import * as React from 'react';
import { Avatar, Box, Button, CssBaseline, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, ThemeProvider, Toolbar, Tooltip, Typography, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import NamekartButton from "./NamekartHomeButton";
import { theme } from './Theme';
import { Archive, ArchiveOutlined, ArrowDropDown, ArrowDropUp, AssessmentOutlined, Assignment, AssignmentInd, AssignmentIndOutlined, AssignmentLate, AssignmentLateOutlined, AssignmentOutlined, AssignmentReturned, AssignmentReturnedOutlined, AssignmentTurnedIn, AssignmentTurnedInOutlined, Dashboard, DashboardOutlined, Domain, DomainOutlined, DomainVerificationOutlined, ExpandLess, ExpandMore, FileUploadOutlined, Group, GroupOutlined, LiveTvOutlined, Logout, MenuTwoTone, Notifications, NotificationsOutlined, PlaylistAddCheck, PlaylistAddCheckOutlined, StoreOutlined, TimelapseOutlined, WorkOutline, YoutubeSearchedForOutlined, SearchOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router';
import userimg from "./images/user1.jpg";

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { canAllocateAll, canAllocateLtd, canAllocateOwned, isAcquisitionCenterVisible, isAdmin, isManager, isProcessor, isProspecterVisible, isQA, isSocialAgent, isESManager, isESAnalyst } from './msalService';
import { getToken } from './axiosInstance';
import { useUser } from './UserProvider';
import { syncUser } from './api';
import nk from "./images/nk.png";

import NamekartStack from './NamekartStack';
const drawerWidth = 180;//256;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
  
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 0, 0.5),

    //    padding: theme.spacing(0, 1.5),

    minHeight:64,
    backgroundColor:'#738e97',//'black'
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer+1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      
    }),
    ...(open && {
      marginLeft: 0,//drawerWidth,
      width: '100%',// `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  export default function Home() {
    const [open, setOpen] = React.useState(true);
    const [olevel1, setOlevel1]=React.useState(false);

    const navigate= useNavigate();
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const { instance,accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { user, updateUser,olevel } = useUser();
    const [nestedOpen1, setNestedOpen1] = React.useState(false);
    const [prospectorOpen, setProspectorOpen] = React.useState(false);
    const [researchCenterOpen, setResearchCenterOpen] = React.useState(false);
    const [acquisitionCenterOpen, setAcquisitionCenterOpen] = React.useState(false);

    const toggleNestedMenu1 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen1(!nestedOpen1);
    };
    const [nestedOpen2, setNestedOpen2] = React.useState(false);

    const toggleNestedMenu2 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen2(!nestedOpen2);
    };

    const [nestedOpen3, setNestedOpen3] = React.useState(false);

    const toggleNestedMenu3 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen3(!nestedOpen3);
    };

    const [nestedOpen4, setNestedOpen4] = React.useState(false);

    const toggleNestedMenu4 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen4(!nestedOpen4);
    };

    const [nestedOpen5, setNestedOpen5] = React.useState(false);

    const toggleNestedMenu5 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen5(!nestedOpen5);
    };

    const [nestedOpen6, setNestedOpen6] = React.useState(false);

    const toggleNestedMenu6 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen6(!nestedOpen6);
    };

    const [nestedOpen7, setNestedOpen7] = React.useState(false);

    const toggleNestedMenu7 = () => {
      if(!open)
      setOpen(true);
      setNestedOpen7(!nestedOpen7);
    };

    const toggleProspector = () => {
      if (!open) setOpen(true);
      setProspectorOpen(!prospectorOpen);
    };

    const toggleResearchCenter = () => {
      if (!open) setOpen(true);
      setResearchCenterOpen(!researchCenterOpen);
    };

    const toggleAcquisitionCenter = () => {
      if (!open) setOpen(true);
      setAcquisitionCenterOpen(!acquisitionCenterOpen);
    };

    const canAccessAnalysisHub = isAdmin() || isESManager() || isESAnalyst();

    React.useEffect(()=>{syncUser().then((res)=>{console.log(res.data); updateUser(res.data); if(res.data.users.length>0)
      setOlevel1(false);
       else setOlevel1(true);}).catch((err)=>{console.log(err);});
    },[])
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{backgroundColor: '#fcfcfa',height:'100%',minHeight:'100vh'}}>
          <Stack direction="column" >
          <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed"  elevation={0} open={open}  sx={{backgroundColor: '#738e97',//'white' 
display:'flex', width:'100%', justifyContent:'center'

          }}
          >
            <Toolbar variant='dense'    disableGutters
            sx={{paddingLeft:'16px',paddingRight:'30px'}}
            >
              <Stack direction='row'  alignItems='center' width='100%' justifyContent='center' >
                {/* {<IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}        
          >
            <MenuTwoTone />
  </IconButton>} */}
  {/* <NamekartStack/> */}
  <img
        src={nk}
        alt="placeholder"
        style={{
          borderRadius: '0',
          transform: 'rotate(0deg)',
          opacity: '1',
          height:'24px',
          padding:0,margin:0
          
        }}
      />
      <Typography align='center' fontSize={18} fontWeight={500} fontFamily='Overpass' color='white' marginLeft={1}>NAMEKART</Typography>
      {/* <span
        style={{
          width: '94px',
          height: '18px',
          transform: 'rotate(0deg)',
          opacity: '1',
          fontSize: '18px',
          fontWeight: 'light',
          color:'white',
          marginLeft:'0 0 0 3px',
          padding:0,

          fontFamily:'Overpass'//'cursive'
        }}
      >
        NAMEKART
      </span> */}
  <Box sx={{ flexGrow: 1 }}></Box>
  <Tooltip title='Will be available soon :)'>
  <Button
    variant="text"
    startIcon={<NotificationsOutlined sx={{ width: 24, height: 24 }} />}
    //onClick={() => { navigate('/home/notifications') }}
    sx={{ height: 24, color: 'white', textTransform:'none' }}
    
  >
    Notifications
  </Button>
  </Tooltip>
          <Button
            variant="text"
            endIcon={<Avatar alt="User" src={userimg} sx={{ width: 24, height: 24,marginLeft:1 }} />}
            sx={{ color: 'white', textTransform: 'none',height:24 }}
          >
            Howdy, {user.firstName}
          </Button>
          {isAuthenticated&&<Tooltip title='Log Out'><IconButton sx={{color:'white'//'#6174D7'
            ,marginLeft:1
          }} onClick={()=>{ if (accounts.length > 0) {
      const account = accounts[0]; // Assuming you want to log out the first logged-in account if multiple accounts are present
      instance.logoutRedirect({ account });
    }}}><Logout sx={{width:24, height:24}}/></IconButton></Tooltip>}
    </Stack>
                </Toolbar>
                </AppBar>
                </Box>
                <Drawer  PaperProps={{sx:{'&::-webkit-scrollbar': {
    width: '6px', // Set the desired width for the scrollbar
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888', // Set the color of the scrollbar thumb
    borderRadius: '6px', // Set the border radius of the scrollbar thumb
  },background: '#738e97',//'#1d1b20',
  color: '#cac4d0', marginTop:'32px'}}} variant="permanent" open={open}>
     
        {/* <DrawerHeader >            
          {/*<IconButton   onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <NamekartButton/>
        </DrawerHeader> */}
        <List    sx={{width: '100%', color: 'white'//'#CAC4D0'
        , fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 20, letterSpacing: 0.10, wordWrap: 'break-word', paddingBottom:4,
        '& .MuiListItemText-primary': {
          fontSize: 14,
          
        },
        '& .MuiListItemText-root': {
          marginLeft:1.5
          
        },
        // '& .MuiListItemIcon-root': {
        //   maxWidth: '10em !important',
        //   height: 24,
        //   paddingRight:0
        // },
       
        }}  >
        <ListItem  button onClick={()=>navigate("/")}>
          <ListItemIcon   sx={{color:'#cac4d0',padding:0,margin:0}} >
            <DashboardOutlined />
          </ListItemIcon>
          <ListItemText  primary="Dashboard"  primaryTypographyProps={{fontSize:14}} 
/>
        </ListItem>
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}

        <ListItem onClick={()=>{navigate("/pqcentre")}} button>
              <ListItemIcon  sx={{color:'#cac4d0'}}>
                <AssignmentLateOutlined />
              </ListItemIcon>
              <ListItemText primary="PQ Centre" />
            </ListItem>
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}
        {(isAdmin()||isManager())&&<ListItem button onClick={toggleNestedMenu1}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <DomainOutlined  />
          </ListItemIcon>
          <ListItemText primary="Domain Studio" />
          {/* {nestedOpen1 ?  <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem>}
        {nestedOpen1&&<List sx={{ paddingLeft: 3}}
        >
           <ListItem  button onClick={()=>{navigate("/domainstudio/owned")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <StoreOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Owned Domains" />
            </ListItem>
            <ListItem button onClick={()=>{navigate("/domainstudio/ltd")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <TimelapseOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Ltd Domains" />
            </ListItem>
            <ListItem button onClick={()=>{navigate("/domainstudio/pd")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <TimelapseOutlined />
              </ListItemIcon> */}
              <ListItemText primary="PD Domains" />
            </ListItem>
            <ListItem button onClick={()=>{navigate("/domainstudio/ald")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <TimelapseOutlined />
              </ListItemIcon> */}
              <ListItemText primary="ALD Domains" />
            </ListItem>
          
          
          </List>}

          <ListItem button onClick={toggleNestedMenu6}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <ArchiveOutlined />
          </ListItemIcon>
          <ListItemText primary="Allocation Centre" />
          {/* {nestedOpen4 ? <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem>
        {nestedOpen6&& <List  sx={{ paddingLeft: 3}}
        >
          {(canAllocateAll()||isAdmin())&&<ListItem button onClick={()=>navigate("/bulkallocator")}>
            <ListItemText primary="Bulk Allocator" />
          </ListItem>}
            {(isProcessor()||isAdmin())&&<ListItem button onClick={()=>navigate("/mydomains")}>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <DomainVerificationOutlined />
            </ListItemIcon > */}
            <ListItemText primary="My Domains" />
          </ListItem>}
          {((isProcessor()||isAdmin())&&!olevel1)&&<><ListItem button onClick={()=>navigate("/allocatedbyme")}>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <AssignmentReturnedOutlined />
            </ListItemIcon > */}
            <ListItemText primary="Allocated by me" />
          </ListItem>
          <ListItem button onClick={()=>navigate("/nonallocated")}>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <AssignmentOutlined />
            </ListItemIcon> */}
            <ListItemText primary="Non-Allocated" />
          </ListItem></>}
          
        </List>}
          
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}
        {isAdmin()&&<><ListItem button onClick={toggleNestedMenu2} disabled>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <AssignmentOutlined />
          </ListItemIcon>
          <ListItemText primary="Acquisitions" />
          {/* {nestedOpen2 ?  <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem>
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}
        </>
}
        <ListItem button onClick={toggleNestedMenu3}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <PlaylistAddCheckOutlined />
          </ListItemIcon>
          <ListItemText primary="Review" />
          {/* {nestedOpen3 ?  <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem>
        {nestedOpen3&&<List sx={{ paddingLeft: 3}}
        >
          {(isQA()||isAdmin())&&<><ListItem button onClick={()=>{navigate("/analysework/0")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <YoutubeSearchedForOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Analyse Work" />
            </ListItem>
            <ListItem button onClick={()=>{navigate("/analysedreports")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <AssessmentOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Analysed Reports" />
            </ListItem>
            </>}
            {(isAdmin()||isProcessor())&&<><ListItem button onClick={()=>navigate("/qareports")}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <AssignmentTurnedInOutlined />
              </ListItemIcon> */}
              <ListItemText primary="QA Reports" />
            </ListItem>
           
            <ListItem button disabled>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <AssignmentIndOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Approvals" />
            </ListItem>
            </>}
            {(isAdmin()||isProcessor()||isQA())&&<><ListItem button onClick={()=>navigate("/misreports")}>
             {/* <ListItemIcon sx={{color:'#cac4d0'}}>
               <AssignmentLateOutlined />
             </ListItemIcon> */}
             <ListItemText primary="MIS Reports" />
           </ListItem></>}
          </List>}
          {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}

        <ListItem button onClick={toggleNestedMenu4}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <ArchiveOutlined />
          </ListItemIcon>
          <ListItemText primary="Processing Centre" />
          {/* {nestedOpen4 ? <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem>
        {nestedOpen4&& <List  sx={{ paddingLeft: 3}}
        >
          <ListItem button onClick={()=>{navigate("/domainlive")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <LiveTvOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Processing Live" />
            </ListItem>
            
          <ListItem button onClick={()=>{navigate("/workarchives")}}>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <ArchiveOutlined />
            </ListItemIcon> */}
            <ListItemText primary="Archive" />
          </ListItem>
          <ListItem button>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <AssignmentOutlined />
            </ListItemIcon> */}
            <ListItemText primary="Special Tasks" />
          </ListItem>
        </List>}
        
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}
         {(isProcessor()||isAdmin())&&<>{/*<ListItem button onClick={()=>navigate('/workupload')}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <FileUploadOutlined />
          </ListItemIcon>
          <ListItemText primary="Work Upload"/>
        </ListItem> 
        <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/>*/}
        
        <ListItem button onClick={()=>navigate('/workinput/0')}>
        <ListItemIcon sx={{color:'#cac4d0'}}>
          <WorkOutline />
        </ListItemIcon>
        <ListItemText primary="Work Station"/>
      </ListItem></>
        }
        {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}
        {isAdmin()&&<ListItem button onClick={()=>navigate('/workforce')}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <GroupOutlined />
          </ListItemIcon>
          <ListItemText primary="WorkForce"/>
        </ListItem>}
       { isSocialAgent()&&<>
       {/* <Divider sx={{ backgroundColor:'#49454F'}} variant='middle'/> */}

        <ListItem button onClick={toggleNestedMenu5}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <ArchiveOutlined />
          </ListItemIcon>
          <ListItemText primary="Research Hub" />
          {/* {nestedOpen5 ? <ArrowDropUp /> : <ArrowDropDown />} */}
        </ListItem></>}
        {nestedOpen5&& <List sx={{ paddingLeft: 3}}
        >
          <ListItem button onClick={()=>{navigate("/socials/bank")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <LiveTvOutlined />
              </ListItemIcon> */}
              <ListItemText primary="Claim Hub" />
            </ListItem>
          <ListItem button onClick={()=>{navigate("/socials/mydomains")}}>
              {/* <ListItemIcon sx={{color:'#cac4d0'}}>
                <LiveTvOutlined />
              </ListItemIcon> */}
              <ListItemText primary="My Claims" />
            </ListItem>
            {<ListItem button onClick={()=>{navigate("/socials/workstation")}
          }>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <DomainVerificationOutlined />
            </ListItemIcon > */}
            <ListItemText primary="Leads Hub" />
          </ListItem>}
          {<><ListItem button onClick={()=>navigate("/socials/collected")}>
            {/* <ListItemIcon sx={{color:'#cac4d0'}}>
              <AssignmentReturnedOutlined />
            </ListItemIcon > */}
            <ListItemText primary="Research Done" />
          </ListItem>
        </>}
         
        </List>}

        {isAdmin()&&<>
        <ListItem button onClick={toggleNestedMenu7}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <ArchiveOutlined />
          </ListItemIcon>
          <ListItemText primary="User Location" />
        </ListItem></>}
        {nestedOpen7&& <List sx={{ paddingLeft: 3}}
        >
          <ListItem button onClick={()=>{navigate("/userlocation")}}>
              <ListItemText primary="Location Logs" />
            </ListItem>
          <ListItem button onClick={()=>{navigate("userlastlocation")}}>
              <ListItemText primary="Last Location" />
          </ListItem>
        </List>}
        
        {isProspecterVisible()&&<ListItem button onClick={toggleProspector}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <WorkOutline />
          </ListItemIcon>
          <ListItemText primary="Prospector" />
        </ListItem>}
        {prospectorOpen && (
          <List sx={{ paddingLeft: 2}}>
            {isAdmin() && (
              <ListItem button onClick={() => navigate("/prospector/masterdata")}>
                <ListItemText primary="Master Data" />
              </ListItem>
            )}
            {canAccessAnalysisHub && (
              <ListItem button onClick={() => navigate("/prospector/analysishub")}>
                <ListItemText primary="Analysis Hub" />
              </ListItem>
            )}
            <ListItem button onClick={() => navigate("/prospector/shortlisteddomains")}>
              <ListItemText primary="Shortlist" />
            </ListItem>
            {/* <ListItem button onClick={() => navigate("/prospector/misseddomains")}>
              <ListItemText primary="Missed Domains" />
            </ListItem> */}
            <ListItem button onClick={() => navigate("/prospector/archive")}>
              <ListItemText primary="Archive" />
            </ListItem>
          </List>
        )}

        {/* <ListItem button onClick={toggleResearchCenter}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <SearchOutlined />
          </ListItemIcon>
          <ListItemText primary="Research Center" />
        </ListItem>
        {researchCenterOpen && (
          <List sx={{ paddingLeft: 3}}>
            <ListItem button  onClick={() => navigate("/researchcenter/claimhub")}>
              <ListItemText primary="Claim Hub" />
            </ListItem>
            <ListItem button  onClick={() => navigate("/researchcenter/leadshub")}>
              <ListItemText primary="Leads Hub" />
            </ListItem>
            <ListItem button  onClick={() => navigate("/researchcenter/archive")}>
              <ListItemText primary="Archive" />
            </ListItem>
          </List>
        )} */}

        {isAcquisitionCenterVisible()&&<ListItem button onClick={toggleAcquisitionCenter}>
          <ListItemIcon sx={{color:'#cac4d0'}}>
            <ShoppingCartOutlined />
          </ListItemIcon>
          <ListItemText primary="Acquisition Center" />
        </ListItem>}
        {acquisitionCenterOpen && (
          <List sx={{ paddingLeft: 3}}>
            <ListItem button  onClick={() => navigate("/acquisitioncenter/recohub")}>
              <ListItemText primary="Reco Hub" />
            </ListItem>
            <ListItem button  onClick={() => navigate("/acquisitioncenter/decisionhub")}>
              <ListItemText primary="Decision Hub" />
            </ListItem>
            <ListItem button  onClick={() => navigate("/acquisitioncenter/ordershub")}>
              <ListItemText primary="Orders Hub" />
            </ListItem>
            <ListItem button  onClick={() => navigate("/acquisitioncenter/archive")}>
              <ListItemText primary="Archive" />
            </ListItem>
          </List>
        )}

      </List>
        </Drawer>
                </Stack>
                <Box  height='100%'sx={{marginLeft:`${drawerWidth}px`, paddingLeft:'30px',paddingRight:'38px',paddingTop:'62px',paddingBottom:'80px'}}>
                {/*<Box height='100%'sx={{backgroundColor:'blue'}}></Box>*/}
                <Outlet/>
                </Box>
                </Box>
                </ThemeProvider>
    )


  }  