import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCSVReader, formatFileSize } from "react-papaparse";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function UploadCSVDialogAldDomains(props) {
  const GREY = "#CCC";
  const GREY_DIM = "#686868";

  const styles = {
    zone: {
      alignItems: "center",
      border: `2px dashed ${GREY}`,
      borderRadius: 20,
      display: "flex",
      flexDirection: "column",
      height: 150,
      width: "100%",
      justifyContent: "center",
      padding: 20,
    },
    zoneHover: {
      borderColor: GREY_DIM,
    },
    file: {
      background: "linear-gradient(to bottom, #EEE, #DDD)",
      borderRadius: 20,
      display: "flex",
      height: 110,
      width: "100%",
      position: "relative",
      zIndex: 10,
      flexDirection: "column",
      justifyContent: "center",
    },
    info: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 10,
      paddingRight: 10,
    },
    size: {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
      borderRadius: 3,
      marginBottom: "0.5em",
      justifyContent: "center",
      display: "flex",
    },
    name: {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
      borderRadius: 3,
      fontSize: 12,
      marginBottom: "0.5em",
    },
    progressBar: {
      bottom: 14,
      position: "absolute",
      width: "100%",
      paddingLeft: 10,
      paddingRight: 10,
    },
    remove: {
      height: 23,
      position: "absolute",
      right: 6,
      top: 6,
      width: 23,
    },
  };

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [val, setVal] = useState([]);
  const [domains, setDomains] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [isDomainProvided, setIsDomainProvided] = useState(false);

  useEffect(() => {
    const domainList = domains.split(/\s*,\s*|\s*[\r\n]+\s*/).map((domainName) => ({
      domain: domainName.trim(),
    }));
    setVal(domainList);
    setIsDomainProvided(domainList.length > 0);
  }, [domains]);

  useEffect(() => {
    setIsDateSelected(selectedDate !== null);
  }, [selectedDate]);

  const isUploadEnabled = isDateSelected && isDomainProvided;

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth>
      <DialogTitle>Add {props.title}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl size="small" fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
            <DatePicker
              label="Order Date"
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                props.setSelectedDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
              fullWidth
            />
          </FormControl>
          <Stack direction="row" spacing={2}>
            <Box width="50%">
              <TextField
                multiline
                placeholder="example.com, example.net, example.org"
                rows={5}
                fullWidth
                value={domains}
                onChange={(event) => setDomains(event.target.value)}
              />
            </Box>
            <Box width="50%">
              <CSVReader
                onUploadAccepted={(results) => {
                  setVal(results.data);
                  setZoneHover(false);
                  setIsDomainProvided(results.data.length > 0);
                }}
                config={{
                  worker: false,
                  header: true,
                  transformHeader: (h) =>
                    h.toLowerCase().replace(/\s/g, "").trim(),
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  setZoneHover(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setZoneHover(false);
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                  Remove,
                }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      ...styles.zone,
                      ...(zoneHover && styles.zoneHover),
                    }}
                  >
                    {acceptedFile ? (
                      <div style={styles.file}>
                        <div style={styles.info}>
                          <span style={styles.size}>
                            {formatFileSize(acceptedFile.size)}
                          </span>
                          <span style={styles.name}>{acceptedFile.name}</span>
                        </div>
                        <div style={styles.progressBar}>
                          <ProgressBar />
                        </div>
                        <div {...getRemoveFileProps()} style={styles.remove}>
                          <Remove />
                        </div>
                      </div>
                    ) : (
                      "Drop CSV file here or click to upload"
                    )}
                  </div>
                )}
              </CSVReader>
            </Box>
          </Stack>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isUploadEnabled}
          onClick={() => {
            props.setSelectedDate(selectedDate);
            props.setState(val);
            setVal([]);
            setDomains("");
            setSelectedDate(null);
            setIsDateSelected(false);
            setIsDomainProvided(false);
            props.handleClose();
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
