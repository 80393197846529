import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        main:'#9db9a0'// '#6750A4',
      },
      secondary: {
        main: '#ffffff'//'#edf2ff',
      },
    },
    typography: {
      fontFamily: [
        'Nunito',
        'Inter',
        'Train One',
        'Roboto',
        'sans-serif',
        'Bebas Neue',
        'Overpass',
        'cursive'
      ].join(",")
    
    },
    components: {
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '24px !important', // Use 'none' to remove the maxWidth restriction
            width: '24px', // Set width to 100% or any other value as needed
            paddingRight: 0
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
            dense: {
                height: 32,
                minHeight: 32
            }
        }
    }
    }
    }
    
    );