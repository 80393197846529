import { Alert, Box, Button, Dialog, DialogActions, Tab, Tabs, Chip, OutlinedInput, Checkbox, ListItemText, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useMemo, useState } from "react";
import UploadCSVDialog from "./UploadCSVDialog";
import UploadCSVDialogAldDomains from "./UploadCSVDialogAldDomains";
import { canAddAld, canAddLtd, isAdmin } from "./msalService";
import { Add, Edit, PanToolAltOutlined } from "@mui/icons-material";
import { allocateDomainToProcessors, allocateDomainToTeams, editAldDomains, getAllAldDomains,getAllAldDomainsWithPagination, getAllLtdDomains, getAllOwnedDomain, getAllPDDomains, getAllTags, uploadAldDomainss, uploadDomains, uploadLtdDomains, uploadOwnedDomains, uploadPDDomains } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from "./UserProvider";

export default function MyDomainsALD() {

  const [domains, setDomains] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState([]);
  const [editDomains, setEditDomains] = useState([]);
  const [tags, setTags] = useState([]);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  let [idds, setIdds] = React.useState([]);
  const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const { user, updateUser } = useUser();
  const [checked, setChecked] = React.useState(true);

  const [pagination, setPagination] = useState({pageIndex: 0,pageSize: 50,});
  const [totalRowCount, setTotalRowCount] = useState(0);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClose1 = () => {
    setOpenDialog1(false);
  };

  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  function formatDateToYYYYMMDD(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchData = async () => {
    try {
      const res = await getAllAldDomainsWithPagination({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
      setData(res.data.content);
      setTotalRowCount(res.data.totalElements);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(editDomains);
    if (editDomains && editDomains.length > 0)
      editAldDomains(editDomains)
        .then((res) => {
          console.log(res);
          setUpsuc(true);
          setEditDomains([]);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [editDomains]);

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
    setSelectedUsers([]);
    setExistingDomains([]);
    setSelectedExistingDomains([]);
    setOpenExistingDomainsDialog(false);
  };

  const handleCloseExistingDomainsDialog = () => {
    setOpenExistingDomainsDialog(false);
    setSelectedExistingDomains([]);
    setExistingDomains([]);
    setDomains([]);
    if(idds.length>0){
    setOpenAllocate(true);
    }
  };

  const handleSelectAll = (event) => {
    setSelectedExistingDomains(event.target.checked ? existingDomains : []);
  };

  const handleExistingDomainSelect = (event) => {
    const { value } = event.target;
    setSelectedExistingDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
  };

  const handleUploadDomains = async (domains, formattedDate, overwrite = false) => {
    try {
        const res = await uploadAldDomainss(domains, formattedDate, overwrite);
        const newIds = res.data.savedDomainIds;
        setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));

        if (res.data.existingDomains.length > 0) {
            setExistingDomains(res.data.existingDomains);
            setOpenExistingDomainsDialog(true);
        } else {
          setOpenAllocate(true);
          setUpsuc(true);
          setDomains([]);
          handleCloseExistingDomainsDialog();
        }
        fetchData();
    } catch (err) {
        console.log(err);
        setDomains([]);
        setUpfail(true);
    }
  };

  useEffect(() => {
    if ((domains && domains.length > 0) && selectedDate) {
      const formattedDate = formatDateToYYYYMMDD(selectedDate);
      handleUploadDomains(domains, formattedDate);
    }
  }, [domains, selectedDate]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    getAllTags().then((res) => {
      const arr = res.data.map((ar) => ar.tag); console.log(arr); setTags(arr)
    }).catch((err) => { console.log(err) })
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'platform', //accessorFn used to join multiple data into a single cell
        id: 'platform', //id is still required when using accessorFn instead of accessorKey
        header: 'Platform',
        // enableColumnFilterModes:false,
        // filterFn: (row, id, filterValue) => { console.log(filterValue); return true; //return row.tags[0].tag === filterValue;
        // },
        //size: 250,
      }, {
        accessorKey: 'domain', //accessorFn used to join multiple data into a single cell
        id: 'domain', //id is still required when using accessorFn instead of accessorKey
        header: 'Domain',
        // enableColumnFilterModes:false,
        // filterFn: (row, id, filterValue) => { console.log(filterValue); return true; //return row.tags[0].tag === filterValue;
        // },
        //size: 250,
      }, {
        id: 'dateOfUpload', //id is still required when using accessorFn instead of accessorKey
        header: 'Add Date',
        accessorFn: (originalRow) => { const date = new Date(originalRow.dateOfUpload); return date }, //convert to date for sorting and filtering
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0], //format date for display
        //size: 250,
      }, {
        id: 'allocatedTo',
        header: 'Allocated To',
        accessorFn: (row) => {
          if (row.presentAllocation && row.presentAllocation.user) {
            return `${row.presentAllocation.user.firstName} ${row.presentAllocation.user.lastName}`;
          } else if (row.presentTeamAllocations && row.presentTeamAllocations.length > 0) {
            return `Team ${row.presentTeamAllocations[0].user.firstName}`;
          } else {
            return 'Not Allocated';
          }
        },
      },{
        id: 'orderDate', //id is still required when using accessorFn instead of accessorKey
        header: 'Order Date',
        accessorFn: (originalRow) => { const date = new Date(originalRow.orderDate); return date }, //convert to date for sorting and filtering
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
        //size: 250,
      }, {
        accessorKey: 'orderStatus', //accessorFn used to join multiple data into a single cell
        id: 'orderStatus', //id is still required when using accessorFn instead of accessorKey
        header: 'Order Status',
        //size: 250,
      }, {
        accessorKey: 'EST', //accessorFn used to join multiple data into a single cell
        id: 'EST', //id is still required when using accessorFn instead of accessorKey
        header: 'EST',
        //size: 250,
      }, {
        accessorKey: 'GDV',
        //enableClickToCopy: true,
        //filterVariant: 'autocomplete',
        header: 'GDV',
        //size: 300,
      }, {
        accessorKey: 'currentPrice', //accessorFn used to join multiple data into a single cell
        id: 'currentPrice', //id is still required when using accessorFn instead of accessorKey
        header: 'Current Price',
        //size: 250,
      }, {
        accessorKey: 'reserve', //accessorFn used to join multiple data into a single cell
        id: 'reserve', //id is still required when using accessorFn instead of accessorKey
        header: 'Reserve',
        //size: 250,
      }, {
        accessorKey: 'clientOffer', //accessorFn used to join multiple data into a single cell
        id: 'clientOffer', //id is still required when using accessorFn instead of accessorKey
        header: 'Client Offer',
        //size: 250,
      }, {
        //accessorKey:'type', //accessorFn used to join multiple data into a single cell
        id: 'type', //id is still required when using accessorFn instead of accessorKey
        header: 'Type',
        accessorFn: (row) => { if (row.brandable) return 'Brandable'; else return 'Generic'; },
        //size: 250,
      }, {
        accessorKey: 'categoryMain', //accessorFn used to join multiple data into a single cell
        id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
        header: 'Category',
        //size: 250,
      }, {
        accessorKey: 'categorySedo', //accessorFn used to join multiple data into a single cell
        id: 'categorySedo', //id is still required when using accessorFn instead of accessorKey
        header: 'SEDO Category',
        //size: 250,
      }, {
        accessorKey: 'categoryDan', //accessorFn used to join multiple data into a single cell
        id: 'categoryDan', //id is still required when using accessorFn instead of accessorKey
        header: 'DAN Category',
        //size: 250,
      }, {
        accessorKey: 'tags', //accessorFn used to join multiple data into a single cell
        //id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
        enableColumnFilterModes: false,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          if (filterValue.length == 0) return true; else { for (let i = 0; i < row.original.tags.length; i++) { if (filterValue.includes(row.original.tags[i].tag)) return true; } return false; }//return row.tags[0].tag === filterValue;
        },
        filterSelectOptions: tags,
        header: 'Tags',
        Cell: ({ cell }) => { if (cell.getValue().length > 0) return cell.getValue()[0].tag; }, //format date for display
        //size: 250,
      }, {
        id: 'registrar', //id is still required when using accessorFn instead of accessorKey
        header: 'Registrar',
        accessorFn: (row) => { if (row.registrar) return row.registrar.registrar; else return 'N/A' },
        //size: 250,
      }, {
        accessorKey: 'whoIsEmail', //accessorFn used to join multiple data into a single cell
        id: 'whoIsEmail', //id is still required when using accessorFn instead of accessorKey
        header: 'WhoIs Email',
        //size: 250,
      }, {
        accessorKey: 'nameservers', //accessorFn used to join multiple data into a single cell
        id: 'nameservers', //id is still required when using accessorFn instead of accessorKey
        header: 'Nameservers',
        //size: 250,
      }, {
        accessorKey: 'wby', //accessorFn used to join multiple data into a single cell
        header: 'WBY',
        //size: 250,
      }, {
        accessorKey: 'aby', //accessorFn used to join multiple data into a single cell
        header: 'ABY',
        //size: 250,
      }, {
        accessorKey: 'reg', //accessorFn used to join multiple data into a single cell
        header: 'Reg',
        //size: 250,
      }, {
        accessorKey: 'regx', //accessorFn used to join multiple data into a single cell
        header: 'Regx',
      }, {
        accessorKey: 'sg',
        header: 'SG',
      }, {
        accessorKey: 'cpc',
        header: 'CPC',
      }
    ],
    [tags]
  );

  const domainTable = useMaterialReactTable({
    columns: columns,
    data: data,
    enableColumnFilterModes: true,

    enableRowSelection: true,
    columnFilterDisplayMode: 'popover',
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    //enableEditing:true,
    //editDisplayMode:'cell',
    initialState: { density: 'compact' },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row-reverse' width='100%' alignItems='center'>
        {isAdmin() && <Button
          disabled={table.getSelectedRowModel().rows.length == 0}

          variant="text"
          //color="primary"
          startIcon={<PanToolAltOutlined />}
          onClick={() => {
            setOpenAllocate(true);
            console.log(table.getSelectedRowModel().rows);
            let id = []
            id = table.getSelectedRowModel().rows.map((row) => { return row.original.id })

            setIdds(id);
            console.log(id);
          }
          }
          sx={{
            textTransform: 'none'
          }}>
          Allocate
        </Button>}<Box flexGrow={1} /></Stack>
    ),

    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),

    manualPagination: true,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,
    state: { pagination },

    muiTableBodyRowProps: ({ row }) => {
      const isProcessor = row.original.presentAllocation && row.original.presentAllocation.user;
      const isTeam = row.original.presentTeamAllocations && row.original.presentTeamAllocations.length > 0;
      
      const backgroundColor = isProcessor ? '#e0f7fa' : isTeam ? '#e8f5e9' : '#ffffff';
    
      return {
        sx: {
          backgroundColor,
        },
      };
    },    
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedUsers([]);
  };

  const handleUserSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = () => {
    const selectedUserEmails = selectedUsers.map((obj) => obj.email);
    const allocationPromise = activeTab === 0
      ? allocateDomainToTeams(idds, selectedUserEmails)
      : allocateDomainToProcessors(idds, selectedUserEmails);
  
    allocationPromise
      .then(() => {
        handleCloseAllocate();
        fetchData();
        setSelectedUsers([]);
        setIdds([]);
      })
      .catch((error) => {
        console.error("Allocation failed", error);
      });
  };

  return (<ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          My Domains
        </Typography>
        <Typography
          variant="h6"
          color='gray'
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          ALD
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        {(isAdmin() || canAddAld()) &&
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => { setOpenDialog(true); }}
              sx={{
                height: 40,
                borderRadius: 100,
                textTransform: 'none'
              }}
            >
              Add Domains
            </Button>
            <UploadCSVDialogAldDomains title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              onClick={() => { setOpenDialog1(true); }}
              sx={{
                height: 40,
                borderRadius: 100,
                textTransform: 'none'
              }}
            >
              Edit Domains
            </Button>
            <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} />
          </>}

      </Stack>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
            <DialogTitle>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Allocate to Team" />
                <Tab label="Allocate to Processor" />
              </Tabs>
            </DialogTitle>
            <DialogContent>
              <Stack direction="row" width="100%" justifyContent="space-between">
                <Typography variant="body1">
                  {idds.length} domain{idds.length != 1 && 's'} selected
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} mt={1.5}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="user-select-label">
                    {activeTab === 0 ? 'Select Team' : 'Select Processors'}
                  </InputLabel>
                  <Select
                    labelId="user-select-label"
                    id="user-select"
                    multiple
                    value={selectedUsers}
                    onChange={handleUserSelect}
                    input={<OutlinedInput label={activeTab === 0 ? 'Select Team' : 'Select Processors'} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.email} label={activeTab === 0 ? `Team ${value.firstName} ${value.lastName}` : `${value.firstName} ${value.lastName}`} />
                        ))}
                      </Box>
                    )}
                  >
                    {user[activeTab === 0 ? 'teams' : 'users'].map((child) => (
                      <MenuItem key={child.email} value={child}>
                        <Checkbox checked={selectedUsers.indexOf(child) > -1} />
                        <ListItemText primary={activeTab === 0 ? `Team ${child.firstName} ${child.lastName}` : `${child.firstName} ${child.lastName}`} />
                      </MenuItem>
                    ))}
                    {activeTab !== 0 && (
                      <MenuItem key={user.email} value={user}>
                        <Checkbox checked={selectedUsers.some(selected => selected.email === user.email)} />
                        <ListItemText primary="Me" />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAllocate}>Cancel</Button>
              <Button variant="contained" onClick={handleSubmit}>
                Allocate
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog fullWidth open={openExistingDomainsDialog} onClose={handleCloseExistingDomainsDialog}>
            <DialogTitle>Existing Domains</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The following domains already exist and were not uploaded:
              </DialogContentText>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedExistingDomains.length === existingDomains.length}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    {existingDomains.map((domain, index) => (
                      <Box key={index} width="30%" mb={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedExistingDomains.includes(domain)}
                              onChange={handleExistingDomainSelect}
                              value={domain}
                            />
                          }
                          label={domain}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseExistingDomainsDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => handleUploadDomains(selectedExistingDomains.map(domain => ({ domain })), formatDateToYYYYMMDD(selectedDate), true)}
              >
                Overwrite
              </Button>
            </DialogActions>
          </Dialog>
          <MaterialReactTable table={domainTable} />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}