import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, Button, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Add, Star } from "@mui/icons-material";
import { theme } from "../Theme";
import { isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst } from "../msalService";
// import UploadCSVDialog from "../UploadCSVDialog";
import UploadCSVDialogShortlistedDomain from "../UploadCSVDialogShortlistedDomain";
import { useUser } from "../UserProvider";
import {
  getAcquShortlistedDomains,
  uploadShortlistedDomains,
  allocateShortlistedDomain,
  getSocialChildren,
  getEsShortlistedDomains,
  fetchStats,
  getEsAnalystShortlistedDomains,
  moveToReco,
  moveToReasearch,
  moveToRecoEsAnalyst,
  moveToResearchEsAnalyst
} from "../api";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const getInitials = (firstName, lastName) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

export default function ShortlistedDomains() {
  const [domains, setDomains] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedListType, setSelectedListType] = useState("");
  const [tabValue, setTabValue] = useState('All');
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [allocateSuccess, setAllocateSuccess] = useState(false);
  const [allocateFail, setAllocateFail] = useState(false);
  const [newDomains, setNewDomains] = useState([]);
  const [agent, setAgent] = useState('');
  const [children, setChildren] = useState([]);
  const { user } = useUser();
  const [refresh, setRefresh] = useState(false);
  const [allocating, setAllocating] = useState(false);
  const [mode, setMode] = useState('ed');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [rowSelection, setRowSelection] = useState({});
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', autoHideDuration: 2000 });
  const [researchTabValue, setResearchTabValue] = useState('All');
  const [domainResearchState, setDomainResearchState] = useState([]);
  const [domainTypeFilter, setDomainTypeFilter] = useState('All');

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isESAnalystUser = isESAnalyst();
  const isEDManagerUser = isEDManager();
  const isEDAnalystUser = isEDAnalyst();

  useEffect(() => {
    let initialMode = 'ed';

    if (isAdminUser||isEDManagerUser || isEDAnalystUser) {
      initialMode = 'ed'; // Default for admin, can be changed via UI
    } else if (isESManagerUser || isESAnalystUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
    fetchDomains(initialMode);

    getSocialChildren().then(res => {
      setChildren(res.data);
    }).catch(err => console.log(err));
  }, [isAdminUser, isESManagerUser, isESAnalystUser, isEDManagerUser, isEDAnalystUser]);

  const [fetchingStats, setFetchingStats] = useState(false);
  const [activeView, setActiveView] = useState("adminView");
  
  useEffect(() => {
    setActiveView(getActiveView());
  },[mode, isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst]);

  const getActiveView = () => {
    if (isAdmin()) {
      return 'adminView';
    }
    if (isESManager() || isEDManager()) {
      return 'managerialView';
    }
    if (isESAnalyst() && mode === 'es') {
      return 'esAnalystView';
    }
    return 'adminView';
  };

  useEffect(() => {
    if (newDomains && newDomains.length > 0) {
      console.log(newDomains);
      uploadShortlistedDomains(newDomains).then(() => {
        setUploadSuccess(true);
        setNewDomains([]);
        fetchDomains(mode);
        setRefresh(!refresh);
      }).catch(err => {
        console.log(err);
        setUploadFail(true);
      });
    }
  }, [newDomains]);

  useEffect(() => {
    if (tabValue === 'All') {
      setFilteredDomains(domains.filter(domain => 
        domainTypeFilter === 'All' || (domain.domainType && domain.domainType === domainTypeFilter)
      ));
    } else {
      setFilteredDomains(domains.filter(domain => 
        domain.list === tabValue && 
        (domainTypeFilter === 'All' || (domain.domainType && domain.domainType === domainTypeFilter))
      ));
    }
    table.resetRowSelection();
  }, [tabValue, domains, domainTypeFilter]);

  useEffect(() => {
    fetchDomains(mode);
  }, [researchTabValue]);

  const fetchDomains = (currentMode) => {
    let apiCall;
    if (activeView === "esAnalystView") {
      apiCall = getEsAnalystShortlistedDomains;
    } else {
      apiCall = currentMode === 'ed' ? getAcquShortlistedDomains : getEsShortlistedDomains;
    }
    apiCall(researchTabValue).then(res => {
      setDomains(res.data);
    }).catch(err => console.log(err));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day}${month}`;
  };

  const formatYear = (dateString) => {
    if (!dateString) return '-';
    return dateString.split('-')[0];
  };
  
  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'EDate', 
      size: 100,
      accessorFn: (row) => {
        const date = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.edate 
          : row.edate;
        return formatDate(date);
      }
    },
    { 
      accessorKey: 'createdAt', 
      header: 'SDate', 
      size: 100,
      accessorFn: (row) => {
        const date = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.createdAt 
          : row.createdAt;
        return formatDate(date);
      }
    },
    { 
      accessorKey: 'domain', 
      header: 'Domain', 
      size: 180,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.domain 
        : row.domain
    },
    { 
      accessorKey: 'list', 
      header: 'List', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.list 
        : row.list
    },
    { 
      accessorKey: 'p', 
      header: 'P', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.p 
        : row.p
    },
    { 
      accessorKey: 'len', 
      header: 'LEN', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.len 
        : row.len
    },
    { 
      accessorKey: 'host', 
      header: 'Host', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.host 
        : row.host
    },
    { 
      accessorKey: 'tld', 
      header: 'TLD', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.tld 
        : row.tld
    },
    { 
      accessorKey: 'wby', 
      header: 'WBY', 
      size: 60,
      accessorFn: (row) => {
        const year = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.wby 
          : row.wby;
        return formatYear(year);
      }
    },
    { 
      accessorKey: 'aby', 
      header: 'ABY', 
      size: 60,
      accessorFn: (row) => {
        const year = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.aby 
          : row.aby;
        return formatYear(year);
      }
    },
    { 
      accessorKey: 'reg', 
      header: 'REG', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.reg 
        : row.reg
    },
    { 
      accessorKey: 'regx', 
      header: 'REGX', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.regx 
        : row.regx
    },
    { 
      accessorKey: 'sg', 
      header: 'SG', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.sg 
        : row.sg
    },
    { 
      accessorKey: 'cpc', 
      header: 'CPC', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.cpc 
        : row.cpc
    },
    { 
      accessorKey: 'bids', 
      header: 'Bids', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.bids 
        : row.bids
    },
    { 
      accessorKey: 'price', 
      header: 'Price', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.price 
        : row.price
    },
    { 
      accessorKey: 'est', 
      header: 'EST', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.est 
        : row.est
    },
    { 
      accessorKey: 'gdv', 
      header: 'GDV', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.gdv 
        : row.gdv
    },
    { 
      accessorKey: 'esw', 
      header: 'ESW', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.esw 
        : row.esw
    },
    { 
      accessorKey: 'gdw', 
      header: 'GDW', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.gdw 
        : row.gdw
    },
    { 
      accessorKey: 'spr500', 
      header: 'SPR 500', 
      size: 80,
      Cell: ({ row }) => {
        const value = activeView === "esAnalystView" 
          ? row.original.shortlistedDomain?.spr500 
          : row.original.spr500;
        return value !== null ? `${(value * 100).toFixed(2)}%` : '-';
      }
    },
    { 
      accessorKey: 'remarks', 
      header: 'Remarks', 
      size: 150,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.remarks 
        : row.remarks
    },
    // { 
    //   accessorKey: 'domainType', 
    //   header: 'Domain Type', 
    //   size: isESAnalystUser && mode === "es" ? 100 : 120,
    //   Cell: ({ row }) => {
    //     if (isESAnalystUser && mode === "es") {
    //       const value = row.original.domainType;
    //       const types = {
    //         'v': 'Venture',
    //         's': 'Specific',
    //         'g': 'Generic'
    //       };
    //       return types[value] || '-';


  
    //     }
    //     return row.original.domainType || '-';
    //   }
    // }
  ], [activeView]);

  useEffect(() => {
    console.log(rowSelection);
    console.log(table.getSelectedRowModel());
  }, [rowSelection]);

  const table = useMaterialReactTable({
    columns,
    data: filteredDomains,
    enableRowSelection: true,
    positionToolbarAlertBanner: 'bottom',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row?.id || '',
    initialState: { density: 'compact' },
    muiTableBodyProps: { sx: { backgroundColor: '#fcfcfa' } },
    muiTableBodyRowProps: { sx: { backgroundColor: '#fcfcfa', paddingY: 0 } },
    muiTablePaperProps: ({ table }) => ({
      sx: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    state: {
      pagination,
      rowSelection,
    },
    onPaginationChange: setPagination,
    
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: false,
    enableStickyHeader: true,


    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        padding: '4px',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' width='100%' alignItems='center' spacing={2}>
        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel id="domain-type-filter-label" sx={{ fontSize: '1rem' }}>Domain Type</InputLabel>
          <Select
            labelId="domain-type-filter-label"
            value={domainTypeFilter}
            label="Domain Type"
            onChange={(e) => setDomainTypeFilter(e.target.value)}
            sx={{
              height: '32px',
              '& .MuiSelect-select': {
                paddingTop: '4px',
                paddingBottom: '4px',
                fontSize: '0.75rem',
              },
            }}
          >
            <MenuItem value="All" sx={{ fontSize: '0.75rem' }}>All</MenuItem>
            <MenuItem value="Specific" sx={{ fontSize: '0.75rem' }}>
              
              Specific
              <Star sx={{ color: 'gold', mr: 1, fontSize: '1rem', ml: 0.5 }} />
            </MenuItem>
            <MenuItem value="Generic" sx={{ fontSize: '0.75rem' }}>
              
              Generic
              <Star sx={{ color: '#0ab4f7', mr: 1, fontSize: '1rem', ml: 0.5 }} />
            </MenuItem>
            <MenuItem value="Venture" sx={{ fontSize: '0.75rem' }}>
             
              Venture
              <Star sx={{ color: '#1fcc41', mr: 1, fontSize: '1rem', ml: 0.5 }} />
            </MenuItem>
          </Select>
        </FormControl>
        <Box flexGrow={1} />
        {Object.keys(rowSelection).length > 0 && (
          <>
          {(activeView === 'adminView' || activeView === 'managerialView') && (
            <Button
              variant="outlined"
              size='small'
              disabled={fetchingStats}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setFetchingStats(true);
                fetchStats(rowSelection).then(res => {
                  setFetchingStats(false);
                  fetchDomains(mode);
                  setSnackbar({ open: true, message: 'Estibot Stats Fetched Successfully! GoDaddy Stats Fetching in background', severity: 'success', autoHideDuration: 5000 });
                }).catch(err => {
                  console.log(err);
                  setFetchingStats(false);
                  setSnackbar({ open: true, message: 'Error Fetching Estibot Stats!', severity: 'error', autoHideDuration: 2000 });
                });
              }}
            >
              {fetchingStats ? "Fetching..." : "Fetch Stats"}
            </Button>
          )}
            <Button
              variant="outlined"
              size='small'
              sx={{ textTransform: 'none' }}
              onClick={handleMoveToReco}
            >
              Move to Reco
            </Button>
            <Button
              variant="outlined"
              size='small'
              sx={{ textTransform: 'none' }}
              onClick={() => {
                const selectedRows = table.getSelectedRowModel().rows;
                const selectedIds = selectedRows.map(row => {
                  if (activeView === "esAnalystView") {
                    return row.original.id;  // Using parent id
                  } else {
                    return row.original.id;  // Using domain id
                  }
                });

                const moveFunction = activeView === "esAnalystView" 
                  ? moveToResearchEsAnalyst 
                  : moveToReasearch;

                moveFunction(selectedIds)
                  .then(() => {
                    setSnackbar({ 
                      open: true, 
                      message: 'Successfully moved domains to research hub', 
                      severity: 'success', 
                      autoHideDuration: 2000 
                    });
                    fetchDomains(mode);
                    setRowSelection({});
                  })
                  .catch(err => {
                    console.error(err);
                    setSnackbar({ 
                      open: true, 
                      message: 'Failed to move domains to research hub', 
                      severity: 'error', 
                      autoHideDuration: 2000 
                    });
                  });
              }}
            >
              Move to Research-Hub
            </Button>
          </>
        )}
      </Stack>
    ),
  });

  const handleResearchTabChange = (event, newValue) => {
    setResearchTabValue(newValue);
  };

  const renderTabLabel = (tabValue) => {
    const tabLabels = {
      'All': 'All',
      'PendingResearch': 'Pending Research',
      'DoneResearch': 'Done Research'
    };
    return tabLabels[tabValue] || tabValue;
  };

  const handleMoveToReco = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => {
      if (activeView === "esAnalystView") {
        return row.original.id;
      } else {
        return row.original.id;
      }
    });

    const moveFunction = activeView === "esAnalystView" 
      ? moveToRecoEsAnalyst 
      : moveToReco;

    moveFunction(selectedIds)
      .then(() => {
        setSnackbar({ 
          open: true, 
          message: 'Successfully moved domains to recommendations', 
          severity: 'success', 
          autoHideDuration: 2000 
        });
        fetchDomains(mode);
        setRowSelection({});
      })
      .catch(err => {
        console.error(err);
        setSnackbar({ 
          open: true, 
          message: 'Failed to move domains to recommendations', 
          severity: 'error', 
          autoHideDuration: 2000 
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar.open} autoHideDuration={snackbar.autoHideDuration} onClose={() => setSnackbar({ open: false, message: '', severity: '' }) }>
          <Alert  severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Shortlist
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {(isAdminUser || isEDManagerUser) && (
            <>
              {mode !== 'es' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setOpenDialog(true)}
                  sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
                >
                  Add Domains
                </Button>
              )}

              </>
              )}
              {(isAdminUser || (isEDManagerUser && isESManagerUser)) && (
                <>
                  <Button
                    variant="outlined"
                onClick={() => {
                  const newMode = mode === 'ed' ? 'es' : 'ed';
                  setMode(newMode);
                  setDomains([]);
                  fetchDomains(newMode);
                }}
                sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
              >
                Switch To {mode === 'ed' ? 'ES' : 'ED'}
              </Button>
            </>
          )}
          {/* <UploadCSVDialog title='Domains' open={openDialog} handleClose={handleCloseDialog} setState={setNewDomains} /> */}
          <UploadCSVDialogShortlistedDomain
            open={openDialog}
            handleClose={handleCloseDialog}
            setState={setNewDomains}
          />
        </Stack>

        <Snackbar
          open={uploadSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>

        <Snackbar
          open={uploadFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Upload Failed!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Allocated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Allocation Failed!
          </Alert>
        </Snackbar>

        <TabContext value={researchTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleResearchTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {['All', 'PendingResearch', 'DoneResearch'].map((state) => (
                <Tab 
                  key={state} 
                  label={renderTabLabel(state)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value={researchTabValue} sx={{ padding: 1 }}>
            <MaterialReactTable table={table} />
          </TabPanel>
        </TabContext>
        <Dialog fullWidth maxWidth="sm" open={openAllocate} onClose={handleCloseAllocate}>
          <DialogTitle>
            <Typography variant="subtitle1">Allocate to Processor</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              {Object.keys(rowSelection).length} domain{Object.keys(rowSelection).length !== 1 && 's'} selected
            </DialogContentText>
            <FormControl fullWidth margin="dense">
              <InputLabel id="processor-label">Select Agent</InputLabel>
              <Select
                labelId="processor-label"
                id="processor-select"
                value={agent}
                label="Select Processor"
                onChange={handleAgentChange}
                size="small"
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAllocate} size="small">Cancel</Button>
            <Button
              disabled={allocating}
              variant="contained"
              onClick={() => {
                setAllocating(true);
                allocateShortlistedDomain(Object.keys(rowSelection), agent).then(() => {
                  setAllocateSuccess(true);
                  setRefresh(!refresh);
                  setRowSelection({});
                  setAgent("");
                  handleCloseAllocate();
                  setAllocating(false);
                }).catch(() => {
                  setAllocateFail(true);
                  setAllocating(false);
                });
              }}
              size="small"
            >
              Allocate
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </ThemeProvider>
  );
}
