import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Paper, Select, Stack, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useContext, useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    Drawer,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    IconButton,
    
  } from '@mui/material';
import { Add, ArrowRight, ArrowRightAlt, Close, Create, DoneOutline, ExpandMore, Stop } from "@mui/icons-material";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useUser } from "./UserProvider";
import { getAnalysedQAReports, getChildren, getDayWorkId, getProcessors, getTeamQAReports } from "./api";
import { UserContext1 } from "..";
import { getUserName } from "./msalService";
import { useNavigate } from "react-router";
const getDateDay=(i)=>{
let d=new Date(); d.setDate(d.getDate()-i); return dayjs(d);
}
const yesterday=getDateDay(1);
export default function AnalysedReports() 
{
    const [date, setDate] = useState(getDateDay(2));
    const [date1, setDate1] = useState(getDateDay(0));
    let [id,setId]=useState(0);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    let [psize,setPsize]= useState(50);
    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);

    const [processor, setProcessor] = useState("All");
    const [processors, setProcessors] = useState([]);
    const { user, updateUser } = useUser();
    useEffect(()=>{
        getAnalysedQAReports(date.format('YYYY-MM-DD'),date1.format('YYYY-MM-DD')).then((res)=>{setRows(res.data); console.log(res.data)}).catch((err)=>{console.log(err)})},[date,date1])
    useEffect(()=>{
        if(processor=='All')
        setRows1(rows);
    else
        setRows1(rows.filter((row)=>{if(row.user.email===processor) return row;}))
},[rows,processor])
useEffect(()=>{getProcessors().then((res)=>{setProcessors(res.data)}).catch((err)=>{console.log(err)})},[])


    const columns = [
        { field: 'user', headerName: 'Processor', width: 170,valueGetter: (params) => {return (params.row.user.firstName)} 
    },
    
        { field: 'score', headerName: 'QA Score',type:'number', width: 90, valueGetter: (params) => {return (params.row.score)}
    },
    
    {
      field: 'udate',
      headerName: 'Upload Date',
      type: 'text',
      width: 150,valueGetter: (params) => {return new Date(params.row.dateOfUpload).toISOString().substring(0,10)}
  },
    
      {
        field: 'rdate',
        headerName: 'Review Date',
        type: 'text',
        width: 150,valueGetter: (params) => {return new Date(params.row.dateOfReview).toISOString().substring(0,10)}
    }, {
      field: 'revStatus',
      headerName: 'Status',
      renderCell: (params)=>{return(<CheckB id={params.row.id} status={params.row.revStatus} />);},
      width: 70,
    }, {
      field: 'action',
      headerName: '',
      renderCell: (params)=>{return(<GoTo id={params.row.id}/>);},
      width: 70,
    }
    ]

    const navigate=useNavigate();

      //-------------------------------------------------------------------------------------------------------------------------------------------

  const GoTo=(props)=>{
  
  
    return(<Box>
      <IconButton size='medium' onClick={()=>{navigate(`/analysework/${props.id}`)}}>
      <ArrowRightAlt fontSize='large' color="primary" />
      </IconButton>
    </Box>);
  }

  //------------------------------------------------------------------------------------------------------


//-----------------------------------------------------------------------------------------------------------------------------------------------
    
const drawerWidth = 400;

    const ProcessorDrawer = ({ }) => {

        let [data,setData]=useState(null);
        let [imp,setImp]=useState([]);
        let [impM,setImpM]=useState([]);
        let [pem,setPem]=useState([]);
        let [ir,setIr]=useState([]);
        let [lm,setLm]=useState([]);


        useEffect(()=>{if(open&&id!=0)
            {
               getDayWorkId(id).then((res)=>{setData(res.data);console.log(res.data)
                const dw=res.data.domainWorks;
                let ic=[],imc=[],pc=[],irc=[],ml=[];

                for(let i=0;i<dw.length;i++)
                {
                    const leads=dw[i].leads;
                    const leadMissed=dw[i].leadMissed;
                    const dom=dw[i].domain
                    for(let j=0;j<leads.length;j++)
                    {
                        if(leads[j].imp)
                        {
                            ic.push({lead:leads[j].site,domain: dom})

                        }
                        if(leads[j].impMissed)
                        {
                            imc.push({lead:leads[j].site,domain: dom})
                        }
                        if(leads[j].primaryEmailMissing!=null&&leads[j].primaryEmailMissing.length>0)
                        {
                            pc.push({lead:leads[j].site,domain: dom})
                        }
                        if(leads[j].irrelevantByQA)
                        {
                            irc.push({lead:leads[j].site,domain: dom})
                        }
                        
                    }
                    for(let j=0;j<leadMissed.length;j++)
                    {
                        ml.push({lead:leadMissed[j].site,domain: dom})
                    }
                }
                console.log(ic)
                setLm(ml);setImp(ic); setImpM(imc); setPem(pc); setIr(irc);
            
            }).catch((err)=>{console.log(err)}) 
            }},[id])
     let isLtd=(domain)=>{
      if(domain.presentAllocation)
      return domain.presentAllocation.domain.portfolio=="ltd"
    else if(domain.pastAllocation)
    return domain.pastAllocation.domain.portfolio=="ltd"
     }

     
     let allocation=(domain)=>{
      if(domain.presentAllocation)
      return domain.presentAllocation
    else if(domain.pastAllocation)
    return domain.pastAllocation
     }

        return (
         <Box>{data&& <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            variant="persistent"
            PaperProps={{sx:{'&::-webkit-scrollbar': {
                width: '3px', // Set the desired width for the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888', // Set the color of the scrollbar thumb
                borderRadius: '3px', // Set the border radius of the scrollbar thumb
              }}}} 
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
          >
            <Stack direction='column' spacing={2} sx={{ paddingY: 2, position: 'relative',mt:'64px'}}>
              <Stack paddingX={3} direction='row' width='100%' justifyContent='end'><IconButton onClick={handleClose} size="small" sx={{ position: 'absolute', right: 8, top: 8, color:'black' }}>
                <Close color="black" fontSize='small' sx={{height:15, width:15}} />
              </IconButton></Stack>
              <Stack direction='column'  paddingX={3} spacing={1}>
              <Stack direction='row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight='bold'  variant="h6">{data.user.firstName+" "+data.user.lastName}</Typography>
               <Stack direction='row' spacing={1}><Typography variant="body2">QA Score</Typography><Typography fontWeight='bold' variant="body2">{data.score}</Typography></Stack> 
              </Stack>
              <Stack direction='row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography  variant="body2">{data.dateOfUpload}</Typography>
               <Stack direction='row' spacing={1}><Typography variant="body2">Report Date:</Typography><Typography fontWeight='bold' variant="body2">{data.dateOfReview}</Typography></Stack> 
              </Stack>
              </Stack>
              <Stack direction='column'  paddingX={3} spacing={0}>
              <Typography alignSelf='start' variant="subtitle2" gutterBottom>
                Domains Processed
              </Typography>
              <List disablePadding >
                {data.domainWorks.map((domain) => (
                  <ListItem disableGutters key={domain.domain}>
                    <Stack width='100%' spacing={0.7} direction='row' alignItems='center'>
                      <Typography color='primary' variant="body2">{domain.domain}</Typography>
                      <Chip label={(isLtd(domain)?"LTD":"OWNED")} size="small" sx={{backgroundColor:isLtd(domain)?'#FFC682':'#C6CFFF',width:isLtd(domain)?15:25,height:9,fontSize:5,'& .MuiChip-label': { 
                    padding: '1px 2px' 
                  } }}/>
                  <Box sx={{flexGrow:1}}/>
                      <Typography variant="body2" fontWeight='bold' color="text.secondary">
                        Est Value: {allocation(domain).domain.EST}
                      </Typography>
                    </Stack>
                  </ListItem>
                ))}
              </List>
              </Stack>
              {/* Accordions */}
              <Box paddingX={1.5}>
              <Paper sx={{padding:1.5,backgroundColor:'#F6F8FF'}}  elevation={0}>
                <Stack direction='column' spacing={2}>
              <Accordion disabled={imp.length==0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body2' fontWeight='bold'>IMP Leads</Typography>
                  <Typography variant='body2' fontWeight='bold' sx={{ ml: 'auto' }}>{imp.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {imp.map((lead) => (
                      <ListItem disableGutters key={lead}>{<Stack direction='row' width='100%' justifyContent='space-between'>
                        <Typography  variant="body2">{lead.lead}</Typography> <Typography variant='body2' color='primary'>{lead.domain}</Typography>
                        </Stack>}</ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion >
              <Accordion disabled={lm.length==0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body2' fontWeight='bold'>Leads Missed</Typography>
                  <Typography variant='body2' fontWeight='bold' sx={{ ml: 'auto' }}>{lm.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {lm.map((lead) => (
                      <ListItem disableGutters key={lead}>{<Stack direction='row' width='100%' justifyContent='space-between'>
                        <Typography variant='body2'>{lead.lead}</Typography> <Typography variant='body2' color='primary'>{lead.domain}</Typography>
                        </Stack>}</ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={impM.length==0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body2' fontWeight='bold'>IMP Missed</Typography>
                  <Typography variant='body2' fontWeight='bold' sx={{ ml: 'auto' }}>{impM.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {impM.map((lead) => (
                      <ListItem disableGutters key={lead}>{<Stack direction='row' width='100%' justifyContent='space-between'>
                        <Typography variant='body2'>{lead.lead}</Typography> <Typography variant='body2' color='primary'>{lead.domain}</Typography>
                        </Stack>}</ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={pem.length==0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body2' fontWeight='bold'>Primary Emails Missing</Typography>
                  <Typography variant='body2' fontWeight='bold' sx={{ ml: 'auto' }}>{pem.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {pem.map((lead) => (
                      <ListItem disableGutters key={lead}>{<Stack direction='row' width='100%' justifyContent='space-between'>
                        <Typography variant='body2'>{lead.lead}</Typography> <Typography variant='body2' color='primary'>{lead.domain}</Typography>
                        </Stack>}</ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion disabled={ir.length==0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body2' fontWeight='bold'>Irrelevant Leads</Typography>
                  <Typography variant='body2' fontWeight='bold' sx={{ ml: 'auto' }}>{ir.length}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding>
                    {ir.map((lead) => (
                      <ListItem disableGutters key={lead}>{<Stack direction='row' width='100%' justifyContent='space-between'>
                        <Typography variant='body2'>{lead.lead}</Typography> <Typography variant='body2' color='primary'>{lead.domain}</Typography>
                        </Stack>}</ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              </Stack>
              </Paper>
              </Box>
              {/* ... Repeat for other accordions */}
              {/* Actions */}
              {/*<Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" startIcon={<Add />}>
                  Give Ticket
                </Button>
                <Box>
                  <Button sx={{ mr: 1 }}>Okay</Button>
                  <Button variant="contained">Send</Button>
                </Box>
                    </Box>*/}
            </Stack>
          </Drawer>}
          </Box>
        );
      };

//---------------------------------------------------------------------------------------------------------------------------

function CustomToolbar(props) {
    const ids=props.ids
    return (
      <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Box flexGrow={1}/>
       
{/*isAdmin()&& <>
<Button
variant="text"
//color="primary"
startIcon={<DeleteOutline />}
onClick={()=>{}}
sx={{
  textTransform:'none'
}}
>
Delete
</Button>
<Button
variant="text"
//color="primary"
startIcon={<ShortcutOutlined />}
onClick={()=>{}}
sx={{
  textTransform:'none'
}}
>
Move
</Button></>*/}
      </GridToolbarContainer>
    );
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------

  const CheckB=(props)=>{
  
   
    const Icon=(props)=>{
      
      if(props.stat==0)
    return <Tooltip title="Not Started Reviewing"><Stop /></Tooltip>
    else if(props.stat==1||props.stat==-1)
    return <Tooltip title="Under Review"><Create color="primary"/></Tooltip>
    else if(props.stat==2)
    return <Tooltip title="Reviewed"><DoneOutline sx={{color:'green'}}/></Tooltip>
    }
  
    return(<Box>
      <Icon stat={props.status} />
    </Box>);
  }

  //------------------------------------------------------------------------------------------------------



    return (<ThemeProvider theme={theme}>
        <Stack direction='column' sx={{marginRight:open?`${drawerWidth}px`:0}} spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Reports
    </Typography>
    <Box sx={{flexGrow:1}}/>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{}} components={['DatePicker','DatePicker']}>
      <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work From"
          value={date}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate(newValue);}} 
        />
        <DesktopDatePicker //disableFuture
        maxDate={yesterday}
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work To"
          value={date1}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate1(newValue);}}
        />
         </DemoContainer>
    </LocalizationProvider>
    <Box paddingTop={1}>
        <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
      <InputLabel id="demo-select-small-label">Processor</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={processor}
        label="Processor"
        onChange={(e) => setProcessor(e.target.value)}
        //sx={{flexDirection:'column',justifyContent:'end',alignItems:'end'}}
        > <MenuItem value='All'>{"All"}</MenuItem>
       {processors&&processors.map((child)=>{ 
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
      </Select>
    </FormControl>
    </Box>
            </Stack>
            <ProcessorDrawer/>
            <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid
        autoHeight 
        rows={rows1}
        columns={columns}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        
        onRowClick={(params)=>{console.log('row clicked');setId(params.row.id); setOpen(true)}}
        //getRowId={(row)=>row.domain.domain}

       // checkboxSelection
        //onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      />
      </Box>
            </Stack>
            </ThemeProvider>
    )
}