import { Alert, Box, Button, Snackbar, Stack, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import UploadCSVDialog from "./UploadCSVDialog";
import { Add, Upload, UploadFile } from "@mui/icons-material";
import { uploadWork } from "./api";
import UploadCSVDialogWork from "./UploadCSVDialogWork";

export default function UploadWork() 
{
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const [work,setWork]=useState([]);
    const[openDialog, setOpenDialog]=useState(false);
    const handleClose = () => {
        setOpenDialog(false);
      };

      useEffect(()=>{
        if(work&&work.length>0)
           uploadWork(work).then((res)=>{setUpsuc(true);setWork([])}).catch((err)=>{console.log(err);setUpfail(true)});},[work])
  
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Upload Work
    </Typography>
    <Box sx={{flexGrow:1}}/>
     {<><Button
      variant="contained"
      color="primary"
      startIcon={<Upload />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      Upload Work
    </Button>
    <UploadCSVDialogWork title='Work' open={openDialog} handleClose={handleClose} setState={setWork} /></>}
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Work Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Work Not Uploaded!
        </Alert>
      </Snackbar>
    
            </Stack>
            </ThemeProvider>
    )
}