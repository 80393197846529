import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NonAllocated from './Components/NonAllocated';
import WorkForce from './Components/WorkForce';
import MyDomains from './Components/MyDomains';
import AllocatedByMe from './Components/AllocatedByMe';
import UploadWork from './Components/UploadWork';
import DomainLive from './Components/DomainLive';
import PQCentre from './Components/PQCentre';
import GenerateQA from './Components/GenerateQA';
import QAReports from './Components/QAReports';
import AnalysedReports from './Components/AnalysedReports';
import WorkInput from './Components/WorkInput';
import WorkInputCopy from './Components/WorkInput copy';
import MyDomainsOwned from './Components/DomainStudio-Owned';
import MyDomainsLtd from './Components/DomainStudio-Ltd';
import MISReports from './Components/MISReports';
import MyAllocatedSocials from './Components/MyAllocatedSocials';
import SocialWorkStation from './Components/SocialWorkStation';
import SocialsCollected from './Components/SocialsCollected';
import Bulkallocator from './Components/BulkAllocator';
import WorkFile from './Components/WorkFile';
import WorkArchives from './Components/WorkArchive';
import MyDomainsPD from './Components/DomainStudio-PD';
import MyDomainsALD from './Components/DomainStudio-Ald';
import SocialsBank from './Components/SocialsBank';
import UserLastLocation from './Components/UserLastLocation';
import UserLocationList from './Components/UserLocationList';
import { saveUserLocation } from './Components/api';
import { useUser } from "./Components/UserProvider";

// New imports for Prospector
import MasterData from './Components/Prospector/MasterData';
import AnalysisHub from './Components/Prospector/AnalysisHub';
import ShortlistedDomains from './Components/Prospector/ShortlistedDomains';
import MissedDomains from './Components/Prospector/MissedDomains';
import ProspectorArchive from './Components/Prospector/Archive';

// New imports for Research Center
import ClaimHub from './Components/ResearchCenter/ClaimHub';
import LeadsHub from './Components/ResearchCenter/LeadsHub';
import ResearchCenterArchive from './Components/ResearchCenter/Archive';

// New imports for Acquisition Center
import RecoHub from './Components/AcquisitionCenter/RecoHub';
import DecisionHub from './Components/AcquisitionCenter/DecisionHub';
import OrdersHub from './Components/AcquisitionCenter/OrdersHub';
import AcquisitionCenterArchive from './Components/AcquisitionCenter/Archive';

function App() {
  const { user } = useUser();
  const locationIntervalRef = React.useRef(null);
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (navigator.geolocation && user.email) {
      const saveLocation = async () => {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          const address = await getFormattedAddress(lat, lng);
          const payload = {
            userId: user.email,
            latitude: lat,
            longitude: lng,
            address: address
          };
          console.log("Location Capturing");
          saveUserLocation(payload);
        }, (error) => {
          console.error("Geolocation error:", error);
        });
      };

      const startLocationTracking = () => {
        saveLocation();
        locationIntervalRef.current = setInterval(saveLocation, 40*60 * 1000); // Save location every 40 minutes
      };

      const stopLocationTracking = () => {
        if (locationIntervalRef.current) {
          clearInterval(locationIntervalRef.current);
        }
      };

      //if (isInitialMount.current) {
        startLocationTracking();
        isInitialMount.current = false;
      //}

      return () => {
        stopLocationTracking();
      };
    }
  }, [user]);

  async function getFormattedAddress(lat, lng) {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;
      const response = await fetch(url);
      const data = await response.json();
      return data.display_name || 'Address not found';
    } catch (error) {
      console.error('Error fetching address:', error);
      return 'Error fetching address';
    }
  }

  return (
    <div className="App">
      <Router>
        <>
          <Routes>
            <Route path="" exact element={<Home />}>
              <Route path="/nonallocated" exact element={<NonAllocated />} />
              <Route path="/workforce" exact element={<WorkForce />} />
              <Route path="/mydomains" exact element={<MyDomains />} />
              <Route path="/allocatedbyme" exact element={<AllocatedByMe />} />
              <Route path="/workupload" exact element={<UploadWork />} />
              <Route path="/workinput/:id" exact element={<WorkInput />} />
              <Route path="/workfile/:id" exact element={<WorkFile />} />
              <Route path="/workinputcopy/:id" exact element={<WorkInputCopy />} />
              <Route path="/workarchives" exact element={<WorkArchives />} />
              <Route path="/domainlive" exact element={<DomainLive />} />
              <Route path="/pqcentre" exact element={<PQCentre />} />
              <Route path="/analysework/:id" exact element={<GenerateQA />} />
              <Route path="/qareports" exact element={<QAReports />} />
              <Route path="/misreports" exact element={<MISReports />} />
              <Route path="/analysedreports" exact element={<AnalysedReports />} />
              <Route path="/domainstudio/owned" exact element={<MyDomainsOwned />} />
              <Route path="/domainstudio/ltd" exact element={<MyDomainsLtd />} />
              <Route path="/domainstudio/pd" exact element={<MyDomainsPD />} />
              <Route path="/domainstudio/ald" exact element={<MyDomainsALD />} />
              <Route path="/socials/mydomains" exact element={<MyAllocatedSocials />} />
              <Route path="/socials/workstation" exact element={<SocialWorkStation />} />
              <Route path="/socials/collected" exact element={<SocialsCollected />} />
              <Route path="/socials/bank" exact element={<SocialsBank />} />
              <Route path="/bulkallocator" exact element={<Bulkallocator />} />
              <Route path="/userlocation" exact element={<UserLocationList />} />
              <Route path="/userlastlocation" exact element={<UserLastLocation />} />

              {/* Prospector routes */}
              <Route path="/prospector/masterdata" exact element={<MasterData />} />
              <Route path="/prospector/analysishub" exact element={<AnalysisHub />} />
              <Route path="/prospector/shortlisteddomains" exact element={<ShortlistedDomains />} />
              <Route path="/prospector/misseddomains" exact element={<MissedDomains />} />
              <Route path="/prospector/archive" exact element={<ProspectorArchive />} />

              {/* Research Center routes */}
              <Route path="/researchcenter/claimhub" exact element={<ClaimHub />} />
              <Route path="/researchcenter/leadshub" exact element={<LeadsHub />} />
              <Route path="/researchcenter/archive" exact element={<ResearchCenterArchive />} />

              {/* Acquisition Center routes */}
              <Route path="/acquisitioncenter/recohub" exact element={<RecoHub />} />
              <Route path="/acquisitioncenter/decisionhub" exact element={<DecisionHub />} />
              <Route path="/acquisitioncenter/ordershub" exact element={<OrdersHub />} />
              <Route path="/acquisitioncenter/archive" exact element={<AcquisitionCenterArchive />} />
            </Route>
          </Routes>
        </>
      </Router>
    </div>
  );
}

export default App;
