import { Alert, Autocomplete, Box, Button, Checkbox, Dialog, DialogContentText, FormControlLabel, FormGroup, IconButton, Snackbar, Stack, Switch, Tab, Tabs, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { memo, useEffect, useState } from "react";
import { allocateToProcessor, allocateToTeam, getNonAlloc, getPresentAllocs, getUserNTeams, removeWorkstationpamulti, setWorkstationpamulti, uploadDomains, workingpa, workstationpa, workstatuspa } from "./api";
import { Add, CheckBox, Create, CreateOutlined, DeleteOutline, Done, DoneOutline, FiberManualRecord, PanToolAltOutlined, ShortcutOutlined, Stop, UndoOutlined, Work, WorkOutline } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { isAdmin } from "./msalService";
import UploadCSVDialog from "./UploadCSVDialog";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser } from "./UserProvider";

const CheckB=memo((props)=>{
  
  const [checked,setChecked]=useState(props.working);
  const [status,setStatus]=useState(props.status);

  const handleChange = (event) => {
    const check=event.target.checked;console.log(check)
    workingpa(props.id,check).then(()=>{
      setChecked(check);
      const arr=props.domains;
      for(let i=0;i<arr.length;i++)
      {
        if(arr[i].id==props.id)
        arr[i].working=check;
      }
      props.setDomains(arr);
    }).catch((err)=>{console.log(err)})
    
  };

  const Icon=(props)=>{
    
    if(props.stat==0)
  return <Tooltip title="Not Started Processing"><Stop /></Tooltip>
  else if(props.stat==1)
  return <Tooltip title="Processing"><Create color="primary"/></Tooltip>
  else if(props.stat==2)
  return <Tooltip title="Processed"><DoneOutline sx={{color:'green'}}/></Tooltip>
  }

  return(<Box>
    <IconButton onClick={()=>{
      let s=status;
      if(status==0) s=1;
    else if(status==1)s=2;
    if(status==2) s=0;
    workstatuspa(props.id,s).then((res)=>{setStatus(s);
      const arr=props.domains;
      for(let i=0;i<arr.length;i++)
      {
        if(arr[i].id==props.id)
        arr[i].status=s;
      }
      props.setDomains(arr);
    }).catch((err)=>{console.log(err)})
    }}><Icon stat={status} /></IconButton>
   {/* <Checkbox  icon={<CreateOutlined />}
  checkedIcon={<Create />} checked={checked} onChange={handleChange} />*/}</Box>);
})

const WorkStationB=memo((props)=>{
  
  const [status,setStatus]=useState(props.workStation);
useEffect(()=>{
  //console.log(props.workStation);
  setStatus(props.workStation);
},[props.workStation])
  const handleChange = (event) => {
    const check=event.target.checked;
    workstationpa(props.id,check).then(()=>{
      setStatus(check);
      console.log(check)
      const arr=props.domains;
      for(let i=0;i<arr.length;i++)
      {
        if(arr[i].id==props.id)
        arr[i].atWorkStation=check;
      }
      props.setDomains(arr);
    }).catch((err)=>{console.log(err)})
    
  };



  return(<Box>
    <Checkbox icon={<WorkOutline />} checkedIcon={<Work />} checked={status} onChange={handleChange} />
   </Box>);
})

export default function MyDomains() 
{
    const [domainsl,setDomainsl]= useState([]);
    let [domainso,setDomainso]= useState([]);
    let [domainsp,setDomainsp]= useState([]);
    let [domainsa,setDomainsa]= useState([]);
    let [domains1,setDomains1]= useState([]);

    let [val,setVal]= useState("");
    let [b,setB]= useState(false);

    const [tvalue, setTvalue] = useState('All');
    let [psize,setPsize]= useState(50);
    let[idds, setIdds]= React.useState([]);
    const[openDialog, setOpenDialog]=useState(false);
    const[openAllocate, setOpenAllocate]=useState(false);
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const[upsuc2, setUpsuc2]=useState(false);
    const[upfail2, setUpfail2]=useState(false);
    const[domains,setDomains]=useState([]);
    const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const [ids, setIds] = useState([]);


  
  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };
    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleCloseAllocate = () => {
        setOpenAllocate(false);
      };
     
      const columns = [
        { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.deadline', headerName: 'Deadline', width: 100,type:'date', valueGetter: (params) => {if(params.row.domain.deadline)
      return (new Date(params.row.domain.deadline))
    else return "";} 
},
        { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
    }, {
      field: 'status',
      headerName: 'Status',
      renderCell: (params)=>{return(<CheckB id={params.row.id} working={params.row.working} status={params.row.status} domains={domains} setDomains={setDomains} />);},
      width: 70,
    },
    
      {
        field: 'EST',
        headerName: 'EST',
        type: 'number',
        width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    {
      field: 'PP',
      headerName: 'PP',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.PP}
    },
    { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "NA"}},
    {field:'atWorkStation',headerName:'W-Station',width:80, renderCell: (params)=>{return(<WorkStationB id={params.row.id} workStation={params.row.atWorkStation} domains={domains} setDomains={setDomains} />);}},
    ]

      const columnsl = [
        { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.deadline', headerName: 'Deadline', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.deadline))} 
},
        { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
    }, {
      field: 'status',
      headerName: 'Status',
      renderCell: (params)=>{return(<CheckB id={params.row.id} working={params.row.working} status={params.row.status} domains={domains} setDomains={setDomains} />);},
      width: 70,
    },
    
      {
        field: 'EST',
        headerName: 'EST',
        type: 'number',
        width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "NA"}},
    {field:'atWorkStation',headerName:'W-Station',width:80, renderCell: (params)=>{return(<WorkStationB id={params.row.id} workStation={params.row.atWorkStation} domains={domains} setDomains={setDomains} />);}},
    ]

    const columnsa = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
  },
  { field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} 
}
,
  
    {
      field: 'domain.orderStatus',
      headerName: 'Order Status',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
  },
      { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  }, {
    field: 'status',
    headerName: 'Status',
    renderCell: (params)=>{return(<CheckB id={params.row.id} working={params.row.working} status={params.row.status} domains={domains} setDomains={setDomains} />);},
    width: 70,
  },
  
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
  },
  { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "NA"}},
  {field:'atWorkStation',headerName:'W-Station',width:80, renderCell: (params)=>{return(<WorkStationB id={params.row.id} workStation={params.row.atWorkStation} domains={domains} setDomains={setDomains} />);}},
  ]


  const columnsp = [
    { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
},
{ field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} 
},
  
{
  field: 'domain.orderStatus',
  headerName: 'Order Status',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
},
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
}, {
  field: 'status',
  headerName: 'Status',
  renderCell: (params)=>{return(<CheckB id={params.row.id} working={params.row.working} status={params.row.status} domains={domains} setDomains={setDomains} />);},
  width: 70,
},

  {
    field: 'EST',
    headerName: 'EST',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{ field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "NA"}},
{field:'atWorkStation',headerName:'W-Station',width:80, renderCell: (params)=>{return(<WorkStationB id={params.row.id} workStation={params.row.atWorkStation} domains={domains} setDomains={setDomains} />);}},
]


    const columnso = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  }, {
    field: 'status',
    headerName: 'Status',
    renderCell: (params)=>{return(<CheckB id={params.row.id} working={params.row.working} status={params.row.status} domains={domains} setDomains={setDomains} />);},
    width: 70,
  },
  
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    {
      field: 'PP',
      headerName: 'PP',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.PP}
    },
    { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "NA"}},
    {field:'atWorkStation',headerName:'W-Station',width:80, renderCell: (params)=>{return(<WorkStationB id={params.row.id} workStation={params.row.atWorkStation} domains={domains} setDomains={setDomains} />);}},
  ]
  const { user, updateUser } = useUser();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event, newValue) => {
    setTvalue(newValue);
  };

    useEffect(()=>{getPresentAllocs().then((res)=>{console.log(res.data);
        const arr=res.data;
       setDomains(arr);
       }).catch((err)=>{console.log(err)})},[b])
        useEffect(()=>{ let al=[],ao=[],ap=[],aa=[];
          for(let i=0;i<domains.length;i++)
          {
              
          if(domains[i].domain.portfolio=="owned")
            ao.push(domains[i]);
          else if(domains[i].domain.portfolio=="pd")
            ap.push(domains[i]);
          else if(domains[i].domain.portfolio=="ald")
            aa.push(domains[i]);
          else 
            al.push(domains[i]);
          }
          console.log(al);
          setDomainsl(al);setDomainso(ao);setDomainsp(ap);setDomainsa(aa);
        console.log(b);
        },[domains])
        function CustomToolbar(props) {
          const ids=props.ids
          return (
            <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
              <Box flexGrow={1}/>
             
   { <>
    <Button
      variant="text"
      //color="primary"
      startIcon={<UndoOutlined />}
      disabled={idds.length==0}
      onClick={()=>{removeWorkstationpamulti(idds).then((res)=>{setB(!b);setUpsuc2(true);}).catch((err)=>{console.log(err);setUpfail2(true);})}}
      sx={{
        textTransform:'none'
      }}
    >
      Remove From W-Station
    </Button>
    <Button
      variant="text"
      //color="primary"
      disabled={idds.length==0}
      startIcon={<ShortcutOutlined />}
      onClick={()=>{setWorkstationpamulti(idds).then((res)=>{setB(!b);setUpsuc(true);}).catch((err)=>{console.log(err);setUpfail(true);})}}
      sx={{
        textTransform:'none'
      }}
    >
      Move To W-Station
    </Button></>}
            </GridToolbarContainer>
          );
        }
    return (
    <ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      My Domains
    </Typography>
    <Box sx={{flexGrow:1}}/>
   {/* { <Autocomplete
      disablePortal
      id="combo-box-demo"
      //options={domainso}
      sx={{ '& .MuiInputBase-root': {
        borderRadius: 100, // Use the value you want for the border-radius
      }, width: 300, height:40, borderRadius:100 }}
     
      size='small'
      //onChange={(event,value)=>{if(value==null) setVal(""); else if (typeof value === 'string')setVal(value); else setVal(value.domain);}}
      renderInput={(params) => <TextField  {...params}  />}
    />} */}
     {/* <>{<Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      //onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Ask Domains
    </Button>} 
    <UploadCSVDialog title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} /></>*/}
   
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Moved to W-Station!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Moved to W-Station!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc2(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Removed from W-Station!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail2(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Removed from W-Station!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
    <TabContext value={tvalue} >
    <Box sx={{ width: '100%', }}>
        
      <TabList
      //orientation="vertical"
         onChange={handleChange}
         sx={{
          width: '860px',
             backgroundColor: '#ffffff',
             borderRadius: '0',
             '& .MuiTab-root': {
               '&.Mui-selected': {
                 backgroundColor: '#fef7ff',
               },
               '&:hover': {
                 backgroundColor: '#fef7ff',
               },
               '&.Mui-selected:hover': {
                 backgroundColor: '#fef7ff',
               },
             },
             
           }}
      >
        <Tab sx={{width:'180px'}} value="All" label={<Stack direction='row' alignItems='center' spacing={1.25}><Typography sx={{fontSize: '14px',fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>All</Typography></Stack>} />
        <Tab sx={{width:'180px'}} value="Ltd" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>LTD</Typography></Stack>} />
          
                <Tab sx={{width:'180px'}}value="Owned" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>Owned</Typography></Stack>} />
                <Tab sx={{width:'180px'}}value="PD" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>PD</Typography></Stack>} />
                <Tab sx={{width:'180px'}}value="ALD" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>ALD</Typography></Stack>} />
      </TabList>
    </Box>
    <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to </Typography> <FormControlLabel sx={{p:0,m:0,ml:1}} control={<Switch checked={checked} onChange={(event)=>{setChecked(event.target.checked);}} size='small'/>} label={<Typography sx={{p:0,m:0}} variant="h6">{checked?'Processor':"Team"}</Typography>}  labelPlacement="start" /></Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length+" "+ tvalue} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Processor</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={processor}
            label="Select Processor"
            onChange={handleProcessorChange}
          >
            {user.users.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName}</MenuItem>
            })}
            {/* ... other options */}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-label">Select Team</InputLabel>
          <Select disabled={checked}
            labelId="team-label"
            id="team-select"
            value={team}
            label="Select Team"
            onChange={handleTeamChange}
          >
           {user.teams.map((child)=>{
              return <MenuItem value={child.email}>Team {child.firstName}</MenuItem>
            })}
          </Select>
        </FormControl>
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button variant="contained" onClick={()=>{if(checked)
        {
          allocateToProcessor(idds,processor).then(()=>{setUpsuc1(true)}).catch(()=>{setUpfail1(true)})
        }
        else allocateToTeam(idds,processor).then(()=>{setUpsuc1(true)}).catch(()=>{setUpfail1(true)})
        handleCloseAllocate();
        }}>Allocate</Button>
      </DialogActions>
    </Dialog>
    <TabPanel value="All" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid 
        autoHeight 
        rows={domains}
        columns={columns}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="Ltd" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{width:'100%'}} >
      <DataGrid 
        autoHeight 
        rows={domainsl}
        columns={columnsl}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}//getRowId={(row)=>row.domain.domain}
        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm);setIdds(itm);}}
      /></Box>
    </TabPanel>
    <TabPanel value="Owned" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
        rows={domainso}
        columns={columnso}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="PD" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{width:'100%'}} >
      <DataGrid 
        autoHeight 
        rows={domainsp}
        columns={columnsp}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}//getRowId={(row)=>row.domain.domain}
        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm);setIdds(itm);}}
      /></Box>
    </TabPanel>
    <TabPanel value="ALD" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{width:'100%'}} >
      <DataGrid 
        autoHeight 
        rows={domainsa}
        columns={columnsa}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}//getRowId={(row)=>row.domain.domain}
        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm);setIdds(itm);}}
      /></Box>
    </TabPanel>
    </TabContext>
    
        </Stack>
    </ThemeProvider>
    );
}