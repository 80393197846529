import { Alert, Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { exportContactsToCRM, getDayWork, getDayWorkId, getMissedLeads, getPresentAllocs, getPresentAllocsWI, getTodayWork, getUsersQA, restartAnalysis, saveLeadQA, saveMissedLeads, saveTodayWork, setBG, startAnalysis, stopAnalysis, uploadWork, workingpa, workstatuspa } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Add, Cancel, CheckCircle, Close, Create, Done, DoneOutline, Remove, Save, SignalCellularConnectedNoInternet3BarTwoTone, Stop, Upload } from "@mui/icons-material";
import { useUser } from "./UserProvider";
import { getName, getUserName } from "./msalService";
import { redirect, useParams } from "react-router";
import dayjs from "dayjs";
import db from "./db";
import UploadCSVDialogWork from "./UploadCSVDialogWork";
import { CSVLink } from "react-csv";

const getContactsData = (lead) => {
    //lead.contacts.push(createContactRow())
    // Assuming domainWork.leads contains the array of leads
    return lead.contacts;
  };

  let editable=false;
  const conttbvariant='outlined'

  const PEmailtb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const [pe, setPe] = useState(props.pe);
   useEffect(()=>{setInputValue(props.value);},[props.value])
   const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);
            }
          };
    return(<TextField
    placeholder="P Email"
    value={inputValue}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={()=>{props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    disabled={!editable}
    InputProps={{sx:{fontSize:13,height:25}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})

  const SEmailtb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const [pe, setPe] = useState(props.pe);
   useEffect(()=>{setInputValue(props.value);},[props.value])
   const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);
            }
          };
    return(<TextField
    placeholder="S Email"
    value={inputValue}
    disabled={!editable}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={()=>{props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    InputProps={{sx:{fontSize:13,height:25}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})


  const Nametb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
    useEffect(()=>{setInputValue(props.value);},[props.value])
    
   // const [pe, setPe] = useState(props.pe);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);
            }
          };
    return(<TextField
    placeholder="Name"
   // defaultValue={props.value}
    value={inputValue}
    size='small'
    disabled={!editable}
    onChange={handleInputChange}
    onBlurCapture={()=>{props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    InputProps={{sx:{fontSize:13,height:25}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})

  const Desigtb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
    useEffect(()=>{setInputValue(props.value);},[props.value])

    // const [pe, setPe] = useState(props.pe);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);
            }
          };
    return(<TextField
    placeholder="Designation"
    value={inputValue}
    size='small'
    disabled={!editable}

    onChange={handleInputChange}
    onBlurCapture={()=>{props.addContactField(props.i1,props.i2,props.i3,props.c,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    InputProps={{sx:{fontSize:13,height:25}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})


  const RenderDetailPanelLeads = 
    memo(({ row,contactsData,r1,subRowC,addRowC,addContactField }) => {
        const [r2,setR2]=useState(row);
       // const [cd,setCd]=useState(contactsData);
//const cd=Array.from(contactsData);
        //console.log(contactsData);
      //  useEffect(()=>{console.log('abe')},[con])
      //const contactsData = getContactsData(row.original);
     
      const contactsColumns = useMemo(()=> [
        {
            id:'name',
            accessorKey: 'name',
            // muiEditTextFieldProps: ({ cell, row }) => ({
            //     placeholder:'Name',
            //     fullWidth:true
            // }),
            size:220,
            Cell: ({renderedCellValue,row,column,table})=>{return(<Nametb value={row.original.name} i1={r1.index} i2={r2.index} i3={row.index} c={column.columnDef.accessorKey} addContactField={addContactField} />) },
            header: 'Name',
          },
        {
            id:'pemail',
            header: 'P Email',
            
            // muiEditTextFieldProps: ({ cell, row }) => ({
            //     placeholder:'Primary Email'
            // }),
            size:220,

            accessorKey:'primaryEmail',
            Cell: ({renderedCellValue,row,column,table})=>{return(<PEmailtb value={row.original.primaryEmail} i1={r1.index} i2={r2.index} i3={row.index} c={column.columnDef.accessorKey} addContactField={addContactField} />) },

          },
          {
            id:'semail',
             header: 'S Email',//muiEditTextFieldProps: ({ cell, row }) => ({
            //     placeholder:'Secondary Email'
            // }),
            size:220,
            accessorKey:'secondaryEmail',
            Cell: ({renderedCellValue,row,column,table})=>{return(<SEmailtb value={row.original.secondaryEmail} i1={r1.index} i2={r2.index} i3={row.index} c={column.columnDef.accessorKey} addContactField={addContactField} />) },

          },
        
         {
            accessorKey: 'designation',
            // muiEditTextFieldProps: ({ cell, row }) => ({
            //     placeholder:'Designation'
            // }),
            size:220,
            header: 'Designation',
            Cell: ({renderedCellValue,row,column,table})=>{return(<Desigtb value={row.original.designation} i1={r1.index} i2={r2.index} i3={row.index} c={column.columnDef.accessorKey} addContactField={addContactField}/>) },

          },
          {
            accessorKey: 'source',
            header: 'Source',
            id: 'source',
            size:140,maxSize:150

            //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
          }

        ],[])
        const contactTable=  useMaterialReactTable({
            columns:contactsColumns,
            data:contactsData,
            enableExpandAll:true,
            enableRowActions:true,
            positionActionsColumn: 'last',
            renderRowActions: ({ row }) => {
                return(
              
                <RowActionsC i1={r1.index} i2={r2.index} i3={row.index} l={contactsData.length} addRowC={addRowC} subRowC={subRowC} />
                
              )
             
            },
            layoutMode:'grid-no-grow',
            enableBottomToolbar:false,
            initialState:{density:'compact'},
           // enableEditing:true,
           // editDisplayMode:'cell',
            muiTablePaperProps:{elevation:0},
            muiTableBodyRowProps:{sx:{padding:0, margin:0, height:30 }},
            enableTopToolbar:false,enableTableHead:false,
        //     muiEditTextFieldProps: ({ cell,row, column, table }) => ({
        //        //onChange:(event)=>{console.log(cell)},
        //        defaultValue:contactsData[row.index][column.columnDef.accessorKey],
        //         onBlur:(event)=>{const val=event.currentTarget.value;
        //             addContactField(r1.index,r2.index,row.index,column.columnDef.accessorKey,val)
        //         },
        //         sx:{padding:0, height:15,}, InputProps:{ sx: {fontSize:13 } }}),

           });
      return (<Box flexGrow={1} width='100%' sx={{flexGrow:1}} >
        
        {contactsData&&contactsData.length>0&&<MaterialReactTable table={contactTable}
      />}</Box>)
    }
)

    const getLeadsData = (domainWork) => {
        return domainWork.leads;
      };

      const Cf=memo((props)=>{
        const [checked,setChecked]=useState(props.checked);
        return (<FormControlLabel sx={{padding:0}} control={<Checkbox sx={{paddingY:0}} onChange={ (event) => {
            const c=event.target.checked
            setChecked(c); props.setCf(props.i1,props.in,c);}
        } checked={checked} disabled={!editable} size='small' />} label={ <Typography padding={0} fontSize={14} color='text.primary' align='left'>CF</Typography> }  />)}
    )
    
      const Imp=memo((props)=>{
        const [checked,setChecked]=useState(props.checked);
        return (<FormControlLabel sx={{padding:0}} control={<Checkbox sx={{paddingY:0}} onChange={ (event) => {
            const c=event.target.checked
            setChecked(c);props.setImp(props.i1,props.in,c)
          }
        } checked={checked}    disabled={!editable}
        size='small' />} label={ <Typography padding={0} fontSize={14} color='text.primary' align='left'>Imp</Typography> }  />)}
    )

    const RowActionsL=memo(({i1,i2,l,subRowL,site,calcL})=>{
        return ( <Box sx={{ display: 'flex', gap: '1rem' }}>
        {(!(i2==0&&l==1))&&
        <Tooltip title="Remove">
          <IconButton size="small" sx={{padding:0}} color="primary" disabled={!editable} onClick={() => {subRowL(i1,i2); const a="ab";
        if(site&&site.length>0)
        calcL(false,1)
        }}>
            <Remove fontSize="small" sx={{padding:0}} />
          </IconButton>
        </Tooltip>}
      </Box>);
      }  )

      const RowActionsC=memo(({i1,i2,i3,l,subRowC,addRowC})=>{
        return ( <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip  title="Add">
          <IconButton size="small" sx={{padding:0}} color="primary" disabled={!editable} onClick={() => {addRowC(i1,i2,i3); }}>
            <Add fontSize="small" sx={{padding:0}} />
          </IconButton>
        </Tooltip>
        {(!(i3==0&&l==1))&&<Tooltip title="Remove">
          <IconButton size="small" sx={{padding:0}} color="primary" disabled={!editable} onClick={() => {subRowC(i1,i2,i3);}}>
            <Remove fontSize="small" sx={{padding:0}} />
          </IconButton>
        </Tooltip>}
      </Box>)
      }  )
    

const RenderDetailPanel = 
memo(({ row, subRowC,addRowC,setLeadName,addLeads,setCf,setImp,addContactField,subRowL,calcLd }) => {
 const leadsData =useMemo(()=>{return getLeadsData(row.original)},[row.original.leads]);
  //const leadsData=getLeadsData(row.original.todayDW)
  const [r1,setR1]=useState(row);
  const [xrow,setXrow]=useState(true);
  //useEffect(()=>{console.log("r2")},[])
  //console.log(leadsData)
  const [l,setL]=useState(row.original.lc);
//   const calcL=useCallback(async (b,c)=>{if(b) {setL(l+c);}
// else {setL(l-c);}
// calcLd(b,c)
// },[l])

const calcL=useCallback(async (b,c)=>{if(b) {setL((l)=>{return l+c});}
else {setL((l)=>{return l-c});}
calcLd(b,c)
},[l])

  const leadsColumns =  useMemo(()=>[
      {
        id: 'site',
        accessorKey: 'site',
        header: 'Leads',
        Cell: ({renderedCellValue,row})=>{return(<Leadtb value={row.original.site} in={row.index} i1={r1.index} setLeadName={setLeadName} calcL={calcL} />) },
        size:220,maxSize:220
        //enableEditing:false
      },
      {
        accessorKey: 'imp',
        header: 'Imp',
        id: 'imp',
        size:80,maxSize:80,
        //muiTableBodyCellProps:{sx:{backgroundColor:'blue' }},

        //accessorFn: (row) => {return  row.imp},
        Cell: ({renderedCellValue,row})=>{return(<Imp checked={row.original.imp} in={row.index} i1={r1.index} setImp={setImp} />) },
       // Edit: ({ cell, renderedCellValue }) => {return <>{renderedCellValue}</>},
      }, {
        //accessorKey: 'contactForm',
        header: 'C Form',
        id: 'cform',
        Cell: ({renderedCellValue,row})=>{return(<Cf checked={row.original.contactForm} in={row.index} i1={r1.index} setCf={setCf} />) },
        size:80,maxSize:80,
        //muiTableBodyCellProps:{sx:{backgroundColor:'red' }},
        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      }
      , {
        //accessorKey: 'contactForm',
        header: 'Contacts',
        id: 'contact',
        Cell: ({renderedCellValue,row})=>{ return(<RenderDetailPanelLeads row={row} contactsData={row.original.contacts} r1={r1} subRowC={subRowC} addRowC={addRowC}  addContactField={addContactField}/>) },
        size:1110, maxSize:1110

        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      }
      ,
      {
        accessorKey: 'classification',
        header: 'Classification',
        id: 'classification',
        size:140,maxSize:150,
        Cell: ({renderedCellValue, row}) => (
          <Typography variant="body2" sx={{ fontSize: 14 }}>
            {row.original.classification}
          </Typography>
        ),

        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      }
      ,
      {
        accessorKey: 'source',
        header: 'Source',
        id: 'source',
        size:140,maxSize:150

        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      }

    ],[row.original.leads])
    const [inputValue, setInputValue] = useState('');
    const [pl, setPl] = useState('');

    const [bg, setBg] = useState(row.original.domain.category);
    const[upfail, setUpfail]=useState(false);
    const[isLoading, setIsLoading]=useState(false);

    const [textInputs, setTextInputs] = useState(new Set());


    useEffect(()=>{setBg(row.original.domain.category)},[])

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
    
      const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
          setTextInputs((prevInputs) => new Set([...prevInputs, inputValue.trim().toLowerCase()]));
          setInputValue('');
        }
      };
    
      const handleRemoveInput = (input) => {
        const updatedInputs = new Set(textInputs);
        updatedInputs.delete(input);
        setTextInputs(updatedInputs);
      };
     // useEffect(()=>{getMissedLeads(row.original.id).then((res)=>{setTextInputs(res.data); setSaveml(false)}).catch((err)=>{console.log(err)})},[])
    const leadTable=  useMaterialReactTable({
        columns:leadsColumns,
        data:leadsData,
        //renderDetailPanel:({ row }) => (<RenderDetailPanelLeads row={row} /> ),
         // muiDetailPanelProps: ({ row }) => ({ sx: {paddingY:0},}),
        enableExpandAll:true,
        enableBottomToolbar:false,
        enableTopToolbar:false,
        enableTableHead:false,
        layoutMode:'grid-no-grow',
        enableRowActions:true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <RowActionsL i1={r1.index} i2={row.index} l={leadsData.length} subRowL={subRowL}  site={row.original.site} calcL={calcL}  />
           
          ),
        initialState:{//expanded:true,
            density:'compact',pagination: { pageSize: 200,pageIndex:0 }},

            state:{isLoading: isLoading},
            //muiTableBodyRowProps:{ sx:{flex:true,flexDirection:'row', alignItems:'end',justifyContent:'s'}},
            muiTableBodyProps:{sx:{backgroundColor:'#fcfcfa'}},muiTableBodyRowProps:{sx:{backgroundColor:'#fcfcfa',paddingY:0}},
            muiTablePaperProps:{elevation:0},
            muiDetailPanelProps:{sx:{ paddingY:0,backgroundColor:'white',paddingLeft:8}},
        onEditingRowSave: ({ values, table,row })=>{
            saveLeadQA(row.original.id,values.impMiss=='Yes',values.irrelevant=='Yes',values.primaryEmailMissing?values.primaryEmailMissing:"").then((res)=>{table.setEditingRow(null);console.log(res.data)})
            .catch((err)=>{console.log(err); setUpfail(true)})
            },
            
      });
  return <Stack direction='column' spacing={1}> 
            <Stack paddingLeft={2} direction='row' width='100%' justifyContent='start' spacing={3} alignItems='center' paddingRight={5} >
            <FormControl size='small'>
<RadioGroup 
row
aria-labelledby="demo-controlled-radio-buttons-group"
name="controlled-radio-buttons-group"
value={bg}
onChange={ (event) => {
    const v=event.target.value;
    const b=bg;
    setBg(v);
    setBG(v,row.original.domain.id).then((res)=>{}).catch((err)=>{console.log(err); setBg(b)})
  }}
>
<FormControlLabel disabled={!editable} value="generic" control={<Radio size='small' />} label={ <Typography fontSize={13} color='text.primary' align='left'>
Generic 
</Typography>      } />
<FormControlLabel disabled={!editable} value="brandable" control={<Radio size="small"/>} label={ <Typography fontSize={13} color='text.primary' align='left'>
Brandable 
</Typography>      } />
{/* <FormControlLabel value="Brandable Generic" control={<Radio size="small" />} label={ <Typography fontSize={13} color='text.primary' align='left'>
Brandable Generic
</Typography>      } /> */}

</RadioGroup>
</FormControl>
  <Stack direction='row' sx={{paddingRight:5}} spacing={3} alignItems='center' >
 {/* <Typography fontSize={13} fontWeight='bold' color='text.primary' align='left'>
Tags: 
</Typography> */ }    
<Stack direction='row' spacing={6} alignItems='center'>
{editable&&<TextField
//label="Leads"
placeholder="Tags"
value={inputValue}
size='small'
disabled={!editable}
//disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
onChange={handleInputChange}
onKeyPress={handleInputKeyPress}
variant='standard'
margin="normal"
InputProps={{sx:{fontSize:13,height:15}}}
sx={{'& legend': { display: 'none' },
'& fieldset': { top: 0 },
width:75,padding:0}}
/>}
<Stack direction='row' sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px',alignItems:'center',justifyContent:'center' }}>
{[...textInputs].map((input) => (
<Chip
  key={input}
  label={input}
  onDelete={() => handleRemoveInput(input)}
  deleteIcon={<Close  sx={{height:13,width:13,padding:0}} />}
  size='small'
  color="primary"
  sx={{height:20,fontSize:13,'& .MuiChip-label': { 
    padding: '2px 5px' 
  } }}
/>
))}
</Stack>


</Stack>
<Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
<Alert  severity="error" sx={{ width: '100%' }}>
 Not Uploaded!
</Alert>
</Snackbar></Stack><Box flexGrow={1} /><Typography variant='body2'  fontWeight='bold' >Leads Count: {l}</Typography></Stack><MaterialReactTable  table={leadTable}/>
{editable&&<Stack direction='row' paddingLeft={1}><TextField
        //label="Leads"
        placeholder="Paste or Enter Leads"
        value={pl}
        disabled={!editable}
        size='small'
        multiline
        //inputRef={inputRef}
        //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
       onChange={(event) => {
        setPl(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault(); // Prevent default behavior of adding a new line
          let pll=pl.trim();
          console.log("t1")
          if (pll!== '') {
           // setIsLoading(true);
            const arr=pll.split('\n')
            const l=arr.map((ar)=>{ return {
                site:ar.trim().toLowerCase(),
                contactForm:false,
                imp:false,
                contacts:[{name:"",primaryEmail:"",secondaryEmail:"",designation:""}]
            }})
           //console.log(l)
           calcL(true,arr.length)
            addLeads(row.index,l)
           // console.log(new Date().getMilliseconds())
            //setIsLoading(false)
            setPl(''); // Reset input value
          }
        }
      }}
       /* onBlur={()=>{setPresentAllocs((prevAllocs) => {
            const updatedAllocs = [...prevAllocs];
            updatedAllocs[row.index].todayDW.leads[props.in].site = inputValue; // Update the site value at the specified index
            return updatedAllocs;
          });}}
        onKeyPress={handleInputKeyPress}*/
        variant='outlined'
        margin="normal"
        autoCorrect={false}
        InputProps={{sx:{fontSize:14}}}
        sx={{'& legend': { display: 'none' },
        '& fieldset': { top: 0 },padding:0,margin:0,width:190}}
      /></Stack>}
      </Stack>;
})


const Leadtb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const inputRef = useRef(null);
   useEffect(()=>{setInputValue(props.value)},[props.value])
    const handleInputChange = (event) => {
        const val=event.target.value
        setInputValue(val);
        //inputRef.current.focus();
        };
//console.log("t1")
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                if(props.value.trim().length==0)
                {
                    if(inputValue.trim().length>0)
                    props.calcL(true,1)
                }
                else
                {
                    if(inputValue.trim().length==0)
                    props.calcL(false,1)
                }
                props.setLeadName(props.i1,props.in,inputValue)
                
            }
          };
    return(<TextField
    //label="Leads"
    placeholder="lead.com"
    value={inputValue}
    size='small'
    disabled={!editable}
    //inputRef={ref.current === row.index ? ref : null}
   // onClick={() => handleFocus(row.index)}
    //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
    onChange={handleInputChange}
    onBlurCapture={()=>{ if(props.value.trim().length==0)
        {
            if(inputValue.trim().length>0)
            props.calcL(true,1)
        }
        else
        {
            if(inputValue.trim().length==0)
            props.calcL(false,1)
        }
        props.setLeadName(props.i1,props.in,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{sx:{fontSize:14,height:30}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})


  let createLeadRow=()=>{
    return {
        site:"",
        contactForm:false,
        imp:false,
        contacts:[{name:"",primaryEmail:"",secondaryEmail:"",designation:""}]
    }
}

let createContactRow=()=>{
    return {name:"",primaryEmail:"",secondaryEmail:"",designation:""}
}
const getSizeInBytes = obj => {
    let str = null;
    if (typeof obj === 'string') {
      // If obj is a string, then use it
      str = obj;
    } else {
      // Else, make obj into a string
      str = JSON.stringify(obj);
    }
    // Get the length of the Uint8Array
    const bytes = new TextEncoder().encode(str).length;
    return bytes;
  };
  
  const logSizeInBytes = (description, obj) => {
    const bytes = getSizeInBytes(obj);
    console.log(`${description} is approximately ${bytes} B`);
  };

  const CheckB=memo((props)=>{
  
    const [status,setStatus]=useState(props.status);
  
  
  
    const Icon=(props)=>{
      
      if(props.stat==0)
    return <Tooltip title="Not Started Processing"><Stop sx={{padding:0}}/></Tooltip>
    else if(props.stat==1)
    return <Tooltip title="Processing"><Create  sx={{padding:0}} color="primary"/></Tooltip>
    else if(props.stat==2)
    return <Tooltip title="Processed"><DoneOutline sx={{color:'green',padding:0}}/></Tooltip>
    }

    return(<Box>
        <IconButton sx={{padding:0}} size="small" disabled={!editable} onClick={()=>{
          let s=status;
          if(status==0) s=1;
        else if(status==1)s=2;
        if(status==2) s=0;
        workstatuspa(props.id,s).then((res)=>{setStatus(s);
          props.setWorkStatus(status,props.i);
        }).catch((err)=>{console.log(err)})
        }}><Icon stat={status} /></IconButton>
       {/* <Checkbox  icon={<CreateOutlined />}
      checkedIcon={<Create />} checked={checked} onChange={handleChange} />*/}</Box>);
    })

    const getDomain=(row)=>{
      if(row.presentAllocation)
        return row.presentAllocation.domain;
      else return row.pastAllocation.domain;
    }

    const getAllocation=(row)=>{
      if(row.presentAllocation)
        return row.presentAllocation;
      else return row.pastAllocation;
    }
export default function WorkFile() 
{

    const { id } = useParams();

    const [dayWork, setDayWork] = useState(null);
    const ref=useRef(null)
    const handleFocus = (id) => {
        ref.current = id; // Update the ref with the currently focused TextField
        //console.log(ref)
      };
      const [ld, setLd ] = useState(0);
       //useEffect(()=>{console.log(presentAllocs)},[presentAllocs])
      // useEffect(()=>{console.log(ld)},[ld])
      const[upsuc, setUpsuc]=useState(false);
      const[upfail, setUpfail]=useState(false);
      const [work,setWork]=useState([]);
      const[openDialog, setOpenDialog]=useState(false);
      const handleClose = () => {
          setOpenDialog(false);
        };
  
        useEffect(()=>{
          if(work&&work.length>0)
             uploadWork(work).then((res)=>{setUpsuc(true);setWork([]); setB(!b)}).catch((err)=>{console.log(err);setUpfail(true)});},[work])
    const [canreview,setCanreview]=useState(true);
    // const CheckB=(props)=>{
  
    //     const [status,setStatus]=useState(props.status);
      
      
      
    //     const Icon=(props)=>{
          
    //       if(props.stat==0)
    //     return <Tooltip title="Not Started Processing"><Stop sx={{padding:0}}/></Tooltip>
    //     else if(props.stat==1)
    //     return <Tooltip title="Processing"><Create  sx={{padding:0}} color="primary"/></Tooltip>
    //     else if(props.stat==2)
    //     return <Tooltip title="Processed"><DoneOutline sx={{color:'green',padding:0}}/></Tooltip>
    //     }

    //     return(<Box>
    //         <IconButton sx={{padding:0}} size="small" onClick={()=>{
    //           let s=status;
    //           if(status==0) s=1;
    //         else if(status==1)s=2;
    //         if(status==2) s=0;
    //         workstatuspa(props.id,s).then((res)=>{setStatus(s);
    //           console.log(presentAllocs)
    //           let arr=presentAllocs;
    //           console.log(arr)
    //           for(let i=0;i<arr.length;i++)
    //           {
    //             if(arr[i].id==props.id)
    //             arr[i].status=s;
    //           }
    //           setPresentAllocs(arr);
    //         }).catch((err)=>{console.log(err)})
    //         }}><Icon stat={status} /></IconButton>
    //        {/* <Checkbox  icon={<CreateOutlined />}
    //       checkedIcon={<Create />} checked={checked} onChange={handleChange} />*/}</Box>);
    //     }
      

    // const saveChangeToIndexDB=async (id,allocs)=>{
    //   db.transaction('rw', db.presentAllocs, async () => {
    //     await db.presentAllocs.put(allocs[id]);
    // }).then(()=>{let d= new Date(); db.data.update(1,{lastWorkSaved:d.getTime()});}).catch((error) => {
    //     console.error(`Failed to update presentAllocs in IndexedDB: ${error}`);
    // });
    // }

    // const saveChangesIndexDB=async (changes)=>{
    //   db.transaction('rw', db.data, async () => {
    //     await db.data.update(1,{changes:changes});
    // }).catch((error) => {
    //     console.error(`Failed to update changes in data in IndexedDB: ${error}`);
    // });
    // }

    // const saveLdIndexDB=async (ld)=>{
    //   db.transaction('rw', db.data, async () => {
    //     await db.data.update(1,{ld:ld});
    // }).catch((error) => {
    //     console.error(`Failed to update ld in data in IndexedDB: ${error}`);
    // });
    // }

const addRowC=useCallback((i1,i2,i3)=>{
  // setPresentAllocs((prevAllocs) => {
  //   const updatedAllocs = [...prevAllocs];
  //   updatedAllocs[i1].todayDW.leads[i2].contacts=[...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(0,i3+1),createContactRow(),...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(i3+1)]

  //   // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,0,createContactRow());
  //   // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
  //   // Update the site value at the specified index
  //   return updatedAllocs;
  // });
},[])

  const subRowC=useCallback((i1,i2,i3)=>{
  //   setPresentAllocs((prevAllocs) => {
  //   const updatedAllocs = [...prevAllocs];
  //   updatedAllocs[i1].todayDW.leads[i2].contacts=[...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(0,i3),...updatedAllocs[i1].todayDW.leads[i2].contacts.slice(i3+1)]
  //   // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,1); // Update the site value at the specified index
  //   // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
  //  // saveChangeToIndexDB(i1,updatedAllocs);
  //   setChanges(true);
  //   //saveChangesIndexDB(true);

  //   return updatedAllocs;
  // });
},[])

  const subRowL=useCallback((i1,i2)=>{
  //   setPresentAllocs((prevAllocs) => {
  //   const updatedAllocs = [...prevAllocs];
  //   updatedAllocs[i1].todayDW.leads=[...updatedAllocs[i1].todayDW.leads.slice(0,i2),...updatedAllocs[i1].todayDW.leads.slice(i2+1)]
  //   // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,1); // Update the site value at the specified index
  //   // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
  //   saveChangeToIndexDB(i1,updatedAllocs);

  //   setChanges(true);
  //   saveChangesIndexDB(true);

  //   return updatedAllocs;
  // });
},[])

  const setCf=useCallback((i1,i2,c)=>{
    // setPresentAllocs((prevAllocs) => {
    // const updatedAllocs = [...prevAllocs];
    // updatedAllocs[i1].todayDW.leads[i2].contactForm = c; // Update the site value at the specified index
    // setChanges(true);
    // saveChangeToIndexDB(i1,updatedAllocs);
    // saveChangesIndexDB(true);

    // return updatedAllocs;
  //});
},[])

  const setImp=useCallback((i1,i2,c)=>{
    // setPresentAllocs((prevAllocs) => {
    // const updatedAllocs = [...prevAllocs];
    // updatedAllocs[i1].todayDW.leads[i2].imp = c; // Update the site value at the specified index
    // setChanges(true);
    // saveChangeToIndexDB(i1,updatedAllocs);
    // saveChangesIndexDB(true);

    // return updatedAllocs;
  //});
},[])

const addContactField=useCallback((i1,i2,i3,c,val)=>{
  // setPresentAllocs((prevAllocs) => {
  //   const updatedAllocs = [...prevAllocs];
  //   updatedAllocs[i1].todayDW.leads[i2].contacts[i3][c]=val; // Update the site value at the specified index
  //   setChanges(true);
  //   saveChangeToIndexDB(i1,updatedAllocs);
  //   saveChangesIndexDB(true);

  //   return updatedAllocs;
  // });
},[])


  const addLeads=useCallback((i1,l)=>{
  // setPresentAllocs((prevAllocs) => {
  // //console.log(prevAllocs)
    
  //   let updatedAllocs = [...prevAllocs];
  //   //let updatedAllocs=JSON.parse(JSON.stringify(prevAllocs))
  //   //console.log(updatedAllocs[i1])
  //   updatedAllocs[i1]={...updatedAllocs[i1],todayDW:{...updatedAllocs[i1].todayDW,leads:[...updatedAllocs[i1].todayDW.leads,...l]}}; // Update the site value at the specified index
  //   //updatedAllocs[i1].todayDW={...updatedAllocs[i1].todayDW}; // Update the site value at the specified index
  //   //updatedAllocs[i1].todayDW.leads=[...updatedAllocs[i1].todayDW.leads,...l] //updatedAllocs[i1].todayDW.leads.concat(l); // Update the site value at the specified index
  //  // console.log(updatedAllocs)
  //  saveChangeToIndexDB(i1,updatedAllocs);
  //  saveChangesIndexDB(true);

  //  setChanges(true);
  //  return updatedAllocs;
  // });
},[])

 const setLeadName=useCallback((i1,i2,inputValue)=>{
  // setPresentAllocs((prevAllocs) => {
  //   const updatedAllocs = [...prevAllocs];
  //   updatedAllocs[i1].todayDW.leads[i2].site = inputValue; // Update the site value at the specified index
  //   setChanges(true);
  //   saveChangesIndexDB(true);
  //   saveChangeToIndexDB(i1,updatedAllocs);

  //   return updatedAllocs;
  // });
},[])

  const setWorkStatus=useCallback((status,i)=>{
    //setPresentAllocs((prevAllocs) => {
  //   let arr=[...prevAllocs];
  //   console.log(arr)

  //   arr[i].status=status;
  //   saveChangeToIndexDB(i,arr);

  //   return arr;
  // });
},[])

//   const calcLd=useCallback(async (b,c)=>{
//     console.log(ld+","+b+","+c)
//     if(b) setLd(ld+c);
//     else setLd(ld-c);},[ld])

    const calcLd=useCallback(
      async (b,c)=>{
        //console.log(ld+","+b+","+c)
        // if(b) setLd((ld)=>{
        //   saveLdIndexDB(ld+c);
        //   return ld+c
        // });
        // else setLd((ld)=>{
        //   saveLdIndexDB(ld-c);
        //   return ld-c});
        }
        ,[])
    

    const columns = React.useMemo(
        () => [
          {
            id: 'domain',
            header: 'Domain',
            accessorFn: (row) => {return getDomain(row).domain;},

          },
          {
            id: 'working',
            accessorFn: (row) => {return row.status;},
            Cell: ({renderedCellValue,row})=>{return(<CheckB id={row.original.id} working={row.original.presentAllocation?row.original.presentAllocation.working:false} status={row.original.presentAllocation?row.original.presentAllocation.status:false} i={row.index} setWorkStatus={setWorkStatus} />) },
            header: 'Status',
          },
          {
            id: 'Type',
            accessorFn: (row) => { return getDomain(row).portfolio;},
            Cell: ({renderedCellValue,row})=>{ return(<Box><Chip label={renderedCellValue} size="small" sx={{backgroundColor:renderedCellValue==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} /> </Box>);},
            header: 'Type',
          },
          {
            id: 'EST',
            accessorFn: (row) => {
                return getDomain(row).EST;  
            },
            header: 'EST',
          },
          {
            id: 'PP',
            accessorFn: (row) => { 
                return getDomain(row).PP;
        },
            header: 'PP',
          },
          
           {
            id: 'deadline', //id is still required when using accessorFn instead of accessorKey
            header: 'Deadline',
            accessorFn: (originalRow) => { if(getDomain(originalRow).portfolio=='ltd'&&getDomain(originalRow).deadline!=null&&getDomain(originalRow).deadline!=''){const date=new Date(getDomain(originalRow).deadline);return date} else return null}, //convert to date for sorting and filtering
            filterVariant: 'date-range',
            Cell: ({ cell }) => {if(cell.getValue()!=null) return cell.getValue().toISOString().split('T')[0]; else return "N/A"},
            //size: 250,
            
          }
          
        ],
        []
      );


        const { user1, updateUser1 } = useUser();
        const [b, setB ] = useState(false);

        const [openS, setOpenS ] = useState(false);
        const [openF, setOpenF ] = useState(false);

        const [date, setDate ] = useState("");

        const [rbtitle,setRbtitle] = useState("");
        const [changes,setChanges] = useState(false);

// const updateDataFromServer =(res)=>{
//   let d=res.data.list;
//   let l=0;
//   d=d.map((a1)=>{
//       let a=a1;
//       let leads=a.todayDW.leads;
//       l=l+leads.length;
//       leads=leads.map((lead)=>{let l= lead; 
//       if(l.contacts.length==0) 
//       l.contacts.push(createContactRow()); return l})
//       leads.push(createLeadRow()) 
//       a.todayDW.leads=leads
//       return a;
//   })
//   setPresentAllocs(d);
//   setDate(res.data.date);
//   setChanges(false)
//   setLd(l);
//               db.presentAllocs.clear().then(() => {
//                 db.presentAllocs.bulkPut(d);
//                 db.data.put({id:1,date:res.data.date,changes:false,ld:l,lastWorkSaved:res.data.lastWorkSaved});
//               });
// }

      useEffect(()=>{
        if(id!=0)
        getDayWorkId(id).then((res)=>{
            if(res.data)
    {setDayWork(res.data);console.log(res.data);setDate(res.data.dateOfUpload); //setDomainWorks(res.data.domainWorks)
            setLd(res.data.lc)
          }
        }).catch((err)=>{console.log(err)})
    },[])

// useEffect(()=>{console.log(changes)
//   saveChangesIndexDB(changes)},[changes]);
// useEffect(()=>{
//   console.log(ld)
//   saveLdIndexDB(ld)},[ld]);

// useEffect(()=>{
//   if(id==0)f
//   getPresentAllocsWI().then((res)=>{
//       if(res.data.list)
// {let d=res.data.list;
// let l=0;
// d=d.map((a1)=>{
//   let a=a1;
//   let leads=a.todayDW.leads;
//   l=l+leads.length;
//   leads=leads.map((lead)=>{let l= lead; 
//   if(l.contacts.length==0) 
//   l.contacts.push(createContactRow()); return l})
//   leads.push(createLeadRow()) 
//   a.todayDW.leads=leads
//   return a;
// })
// setPresentAllocs(d);
// setDate(res.data.date);
// setChanges(false)
// setLd(l);

// }}).catch((err)=>{console.log(err)})
// },[b])

//useEffect(()=>{setChanges(true)},[presentAllocs]);
//console.log(presentAllocs)

/*useEffect(()=>{
    if(id!=0)
    getDayWorkId(id).then((res)=>{
        if(res.data)
{setDayWork(res.data);console.log(res.data); setDomainWorks(res.data.domainWorks)
if(res.data.analyst==null||res.data.analyst.email==
    getUserName())
setCanreview(true); else setCanreview(false);
setDate(dayjs(new Date(res.data.dateOfUpload)));
}
else setDomainWorks([])
    }).catch((err)=>{console.log(err)})
},[])

const reviewbutton=()=>{
    if(dayWork.revStatus==0) setRbtitle('Start Review');
    else if(dayWork.revStatus==1||dayWork.revStatus==-1)
    {
        if(canreview)
        setRbtitle('Finish Review');
    else{setDisablerb(true) ;setRbtitle('Under Review'); }
    }
    else if(dayWork.revStatus==2)
    {
        if(canreview)
        setRbtitle('Re-Review'); 
    else{setDisablerb(true) ;setRbtitle('Reviewed')}
    }

}*/

const domainTable=  useMaterialReactTable({
    columns:columns,
    data:dayWork?dayWork.domainWorks:[],
    //enableEditing:true,
    //editDisplayMode:'cell',
    initialState: { density: 'compact' },
    renderTopToolbarCustomActions: ({ table }) => (
        <Stack direction='row' width='100%' alignItems='center'>
          {/* {<Button
          variant="contained"
          size='small'
          disabled={!changes}
          sx={{textTransform:'none'}}
          onClick={() => {
            
            saveTodayWork(date,presentAllocs).then((res)=>{
                setB(!b)
                setChanges(false)
                saveChangesIndexDB(false);

                setOpenS(true);
               }).catch((err)=>{console.log(err); setOpenF(true)})
          }}
        >
          Save Changes
        </Button>} */}
        <Box flexGrow={1} /><Typography variant='body2'  fontWeight='bold' >Total Leads: {ld}</Typography></Stack>
      ),
      
    //memoMode:'cells',
    renderDetailPanel: ({ row }) => (
        <RenderDetailPanel row={row} addRowC={addRowC} subRowC={subRowC} setLeadName={setLeadName} addLeads={addLeads} setCf={setCf} setImp={setImp} addContactField={addContactField} subRowL={subRowL} calcLd={calcLd} />
      ),
    enableExpandAll:true,
    muiTablePaperProps: ({ table }) => ({
        //not sx
        style: {
          zIndex: table.getState().isFullScreen ? 1250 : undefined,
        },
      }),
      muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke'}},
      muiDetailPanelProps: ({ row }) => ({
        sx: {
          backgroundColor:'#fcfcfa',paddingX:0
        },
      }),
      

  });
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2} alignItems='center'  width='100%' >
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Work File
    </Typography>
   { dayWork&&<Typography
      sx={{
        textAlign: 'left',
        //fontWeight: 'bold',
        position: 'relative',
      }}
    >
      {dayWork.user.firstName} {dayWork.user.lastName}
    </Typography>}
    <Typography>{date}</Typography>

    <Box flexGrow={1}></Box>
    {/* <Button
      variant="text"
      color="primary"
      startIcon={<Upload />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      Upload Work
    </Button> */}
     {dayWork&&<ExportContactsToCRM presentAllocs={dayWork.domainWorks} changes={changes} user={dayWork.user} date={date} />}
    <UploadCSVDialogWork title='Work' open={openDialog} handleClose={handleClose} setState={setWork} />
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Work Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Work Not Uploaded!
        </Alert>
      </Snackbar>
    <Snackbar open={openS} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setOpenS(false);}}>
<Alert  severity='success' sx={{ width: '100%' }}>
 Saved!
</Alert>
</Snackbar>
<Snackbar open={openF} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setOpenF(false);}}>
<Alert  severity="error" sx={{ width: '100%' }}>
 Not Saved!
</Alert>
</Snackbar>
    {/*dayWork&&<Button
      variant="contained"
      color="primary"
      disabled={disablerb}
      //startIcon={<Uploa />}
      onClick={()=>{
        if(dayWork.revStatus==0)
        {
            startAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:1}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==1||dayWork.revStatus==-1)
        {
            stopAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:2}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==2)
        {
            restartAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:-1}))}).catch((err)=>{console.log(err)});
        }
      }}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      { rbtitle}
    </Button>*/}
   
 
            </Stack>
            <Box //sx={{ opacity: openbd ? 0.5 : 1,pointerEvents:openbd ? "none" : "auto"}}
            >
      <LocalizationProvider dateAdapter={AdapterDayjs}>

<MaterialReactTable
table={domainTable}
/>
</LocalizationProvider>
    </Box>
            </Stack>
            </ThemeProvider>
    )
 }

 const ExportContactsToCRM = ({ presentAllocs, changes,date,user }) => {

  const headers = [
    { label: "DOMAIN", key: "DOMAIN" },
    { label: "LEAD", key: "LEAD" },
    { label: "NAME", key: "NAME" },
    { label: "EMAIL", key: "EMAIL" }
];
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState('');
  const [data, setData] = useState([]);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);

  // Handle opening the dialog
  const handleOpen = () => {
    setOpen(true);
    setTag(`${user.firstName} ${user.lastName}/${date}`);
    setData(transformData());
  };

  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Transform presentAllocs to the required array format
  const transformData = () => {
    const contactsArray = [];
    presentAllocs.forEach(alloc => {
      alloc.leads.forEach(lead => {
        lead.contacts.forEach(contact => {
          const email = contact.primaryEmail || contact.secondaryEmail;
          if (email) {
            contactsArray.push({
              DOMAIN: getDomain(alloc).domain,
              LEAD: lead.site,
              NAME: contact.name,
              EMAIL: email
            });
          }
        });
      });
    });
    return contactsArray;
  };

  return (
    <>
      <Button variant="text" onClick={handleOpen} disabled={changes}>
        Export Contacts
      </Button>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Contacts Exported!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Contacts Not Exported!
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Export Contacts To CRM</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            label="Tag"
            type="text"
            fullWidth
            variant="outlined"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CSVLink style={{ textDecoration: 'none' }} data={data} filename={`${tag}.csv`} headers={headers}>
          <Typography color='primary' variant='caption' >Download CSV</Typography> 
          </CSVLink>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            console.log(data);
             // Replace with actual export logic
            exportContactsToCRM(data,tag,getUserName()).then((res)=>{console.log(res); setUpsuc(true); handleClose();
              window.open('https://app.namekart.com/crm/home/manualCampaigns', '_blank');
            }).catch((err)=>{console.log(err); setUpfail(true);});

            //handleClose();
          }}>Export</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};