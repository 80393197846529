import { Alert, Backdrop, Box, Button, Chip, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDayWork, getDayWorkId, getMissedLeads, getUsersQA, restartAnalysis, saveLeadQA, saveMissedLeads, startAnalysis, stopAnalysis } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Cancel, CheckCircle, Close, Create, Done, DoneOutline, Save, Stop } from "@mui/icons-material";
import { useUser } from "./UserProvider";
import { getUserName } from "./msalService";
import { useParams } from "react-router";
import dayjs from "dayjs";

export default function GenerateQA() 
{

    const { id } = useParams();
    const [date, setDate] = useState(null);
    const [user, setUser] = useState("");
    const [users, setUsers] = useState([]);
    const [domainWorks, setDomainWorks] = useState([]);
    const [dayWork, setDayWork] = useState(null);
    const [canreview,setCanreview]=useState(true);
    const columns = React.useMemo(
        () => [
          {
            accessorKey: 'domain',
            header: 'Domain',
          },
          {
            id: 'working',
            accessorFn: (row) => {if(row.presentAllocation) return row.presentAllocation.status; else return false},
            Cell: ({renderedCellValue,row})=>{  if(renderedCellValue==1) return(<Box><Create color="primary"/> </Box>);
else if(renderedCellValue==0)
return(<Box><Stop/> </Box>);
else if(renderedCellValue==2)
return(<Box><DoneOutline sx={{color:'green'}}/> </Box>);},
            header: 'Status',
          },
          {
            id: 'Type',
            accessorFn: (row) => { if(row.presentAllocation)
                return row.presentAllocation.domain.portfolio;
            else if(row.pastAllocation)
            return row.pastAllocation.domain.portfolio;},
            Cell: ({renderedCellValue,row})=>{ return(<Box><Chip label={renderedCellValue==='owned'?"Owned":"Ltd"} size="small" sx={{backgroundColor:renderedCellValue==='owned'?'#C6CFFF':'#FFC682',height:17,fontSize:10}} /> </Box>);},
            header: 'Type',
          },
          {
            id: 'EST',
            accessorFn: (row) => {
                if(row.presentAllocation)
                return row.presentAllocation.domain.EST;
            else if(row.pastAllocation)
            return row.pastAllocation.domain.EST;
             
            },
            header: 'EST',
          },
          {
            id: 'PP',
            accessorFn: (row) => { 
                if(row.presentAllocation)
                return row.presentAllocation.domain.EST;
            else if(row.pastAllocation)
            return row.pastAllocation.domain.EST;
        },
            header: 'PP',
          },
          
        ],
        []
      );

      const getLeadsData = React.useCallback((domainWork) => {
        return domainWork.leads;
      }, []);

      const RenderDetailPanel = 
        ({ row }) => {
          const leadsData = getLeadsData(row.original);
    
          const leadsColumns =  [
              {
                accessorKey: 'site',
                header: 'Leads',
                enableEditing:false
              },
              {
                accessorKey: 'imp',
                header: 'Imp',
                id: 'imp',
                accessorFn: (row) => {return  row.imp ? 'Yes' : 'No'},
                enableEditing:false,
               // Edit: ({ cell, renderedCellValue }) => {return <>{renderedCellValue}</>},
              }, {
                accessorKey: 'contactForm',
                header: 'C Form',
                id: 'cform',
                accessorFn: (row) => {return  row.contactForm ? 'Yes' : 'No'},
                enableEditing:false,
                //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
              },
              {
                accessorKey: 'impMissing',
                header: 'Imp Miss',
                id: 'impMiss',
                accessorFn: (row) => {return  row.impMissing ? 'Yes' : 'No'},
                enableEditing:(row)=>{ 
                    return !row.original.imp},
                editVariant: 'select',
                editSelectOptions: ['Yes','No'],
              },
              {
                accessorKey: 'primaryEmailMissing',
                header: 'P Email Missing',
              },
              
              {
                accessorKey: 'irrelevantByQA',
                header: 'Irrelevant',
                id: 'irrelevant',
                accessorFn: (row) => {return  row.irrelevantByQA ? 'Yes' : 'No'},
                editVariant: 'select',
                editSelectOptions: ['Yes','No'],
              }
              ,
              {
                accessorKey: 'classification',
                header: 'Classification',
                enableEditing:false
              },
              {
                accessorKey: 'source',
                header: 'Source',
                enableEditing:false
              }

            ]
            const [inputValue, setInputValue] = useState('');
            const [saveml, setSaveml] = useState(true);
            const[upfail, setUpfail]=useState(false);

            const [textInputs, setTextInputs] = useState(new Set());
            const handleInputChange = (event) => {
                setInputValue(event.target.value);
              };
            
              const handleInputKeyPress = (event) => {
                if (event.key === 'Enter' && inputValue.trim() !== '') {
                  setTextInputs((prevInputs) => new Set([...prevInputs, inputValue.trim().toLowerCase()]));
                  setSaveml(true)
                  setInputValue('');
                }
              };
            
              const handleRemoveInput = (input) => {
                const updatedInputs = new Set(textInputs);
                updatedInputs.delete(input);
                setTextInputs(updatedInputs);
                setSaveml(true)
              };
              useEffect(()=>{getMissedLeads(row.original.id).then((res)=>{setTextInputs(res.data); setSaveml(false)}).catch((err)=>{console.log(err)})},[])
            const leadTable=  useMaterialReactTable({
                columns:leadsColumns,
                data:leadsData,
                renderDetailPanel: ({ row }) => (
                  row.original.contacts.length > 0 ? <RenderDetailPanelLeads row={row} /> : null
                ),
                 // muiDetailPanelProps: ({ row }) => ({ sx: {paddingY:0},}),
                enableExpandAll:true,
                enableBottomToolbar:false,
                enableTopToolbar:false,
                enableEditing:true,
                editDisplayMode: 'modal',
                initialState:{//expanded:true,
                    density:'compact',
                    pagination: { pageSize: 200, pageIndex: 1 }},
                    muiTableBodyProps:{sx:{backgroundColor:'#fcfcfa'}},muiTableBodyRowProps:{sx:{backgroundColor:'#fcfcfa'}},
                onEditingRowSave: ({ values, table,row })=>{
                    saveLeadQA(row.original.id,values.impMiss=='Yes',values.irrelevant=='Yes',values.primaryEmailMissing?values.primaryEmailMissing:"").then((res)=>{table.setEditingRow(null);console.log(res.data)})
                    .catch((err)=>{console.log(err); setUpfail(true)})
                    },
                    
              });
          return <Stack direction='column' spacing={1}> 
          <Stack direction='row' sx={{paddingRight:5}} spacing={3} alignItems='center' >
          <Typography fontWeight='bold' color='text.primary' align='left'>
      Missed Leads: 
    </Typography>      
    <Stack direction='row' spacing={10} alignItems='center'>
    <TextField
      //label="Leads"
      placeholder="Lead.com"
      value={inputValue}
      size='small'
      disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
      onChange={handleInputChange}
      onKeyPress={handleInputKeyPress}
      variant="outlined"
      margin="normal"
      sx={{'& legend': { display: 'none' },
      '& fieldset': { top: 0 },}}
    />
    <Stack direction='row' sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px',alignItems:'center',justifyContent:'center' }}>
      {[...textInputs].map((input) => (
        <Chip
          key={input}
          label={input}
          onDelete={() => handleRemoveInput(input)}
          deleteIcon={<Close />}
          size='small'
          color="primary"
        />
      ))}
    </Stack>

   
    </Stack>
    <Box flexGrow={1}/>
    <Tooltip title={saveml?"Save Leads Missed":"Saved"}><Box><IconButton disabled={!saveml} color={saveml?"primary":""} onClick={()=>{saveMissedLeads(row.original.id,Array.from(textInputs)).then((res)=>{setSaveml(false)}).catch((err)=>{console.log(err); setUpfail(true)}); }}>{saveml?<Save/>:<DoneOutline/>}</IconButton></Box></Tooltip>
    <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
         Not Uploaded!
        </Alert>
      </Snackbar></Stack><MaterialReactTable  table={leadTable}
          /></Stack>;
        }

      const getContactsData = React.useCallback((lead) => {
        // Assuming domainWork.leads contains the array of leads
        return lead.contacts;
      }, []);

      const RenderDetailPanelLeads = 
        ({ row }) => {
          const contactsData = getContactsData(row.original);
    
          const contactsColumns =  [
              {
                accessorKey: 'name',
                header: 'Contacts',
              },
             
              {
                accessorKey: 'primaryEmail',
                header: 'P Email',
              },
             
              {
                accessorKey: 'secondaryEmail',
                header: 'S Email',
              }, {
                accessorKey: 'designation',
                header: 'Designation',
              },
              {
                accessorKey: 'source',
                header: 'Source',
              }

            ]
            const contactTable=  useMaterialReactTable({
                columns:contactsColumns,
                data:contactsData,
                enableExpandAll:true,
                enableBottomToolbar:false,
                initialState:{density:'compact'},
                enableTopToolbar:false
              });
          return <MaterialReactTable   table={contactTable}
          />;
        }
        const { user1, updateUser1 } = useUser();
        const [disablerb, setDisablerb ] = useState(false);
        const [openbd, setOpenbd ] = useState(false);

        const [rbtitle,setRbtitle] = useState("");

    useEffect(()=>{getUsersQA().then((res)=>{setUsers(res.data)}).catch((err)=>{console.log(err)})},[])
    useEffect(()=>{if(dayWork) {reviewbutton();
    if((canreview)&&(dayWork.revStatus==1||dayWork.revStatus==-1))
    setOpenbd(false);
else setOpenbd(true)}},[dayWork,canreview])

    useEffect(()=>{
        if(id==0&&user!=""&&date!=null)
        getDayWork(date.format('YYYY-MM-DD'),user).then((res)=>{
   
            if(res.data)
   {setDayWork(res.data);console.log(res.data); setDomainWorks(res.data.domainWorks)
    if(res.data.analyst==null||res.data.analyst.email==
        getUserName())
    setCanreview(true); else setCanreview(false);}
    else setDomainWorks([])
        }).catch((err)=>{console.log(err)})
},[date,user])

useEffect(()=>{
    if(id!=0)
    getDayWorkId(id).then((res)=>{
        if(res.data)
{setDayWork(res.data);console.log(res.data); setDomainWorks(res.data.domainWorks)
if(res.data.analyst==null||res.data.analyst.email==
    getUserName())
setCanreview(true); else setCanreview(false);
setDate(dayjs(new Date(res.data.dateOfUpload)));
setUser(res.data.user.email)
}
else setDomainWorks([])
    }).catch((err)=>{console.log(err)})
},[])

const reviewbutton=()=>{
    if(dayWork.revStatus==0) setRbtitle('Start Review');
    else if(dayWork.revStatus==1||dayWork.revStatus==-1)
    {
        if(canreview)
        setRbtitle('Finish Review');
    else{setDisablerb(true) ;setRbtitle('Under Review'); }
    }
    else if(dayWork.revStatus==2)
    {
        if(canreview)
        setRbtitle('Re-Review'); 
    else{setDisablerb(true) ;setRbtitle('Reviewed')}
    }

}

const domainTable=  useMaterialReactTable({
    columns:columns,
    data:domainWorks,initialState: { density: 'compact' },
    renderDetailPanel:({ row }) => (
        <RenderDetailPanel row={row} />
      ),
    enableExpandAll:true,
    muiTablePaperProps: ({ table }) => ({
        //not sx
        style: {
          zIndex: table.getState().isFullScreen ? 1250 : undefined,
        },
      }),
      muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke'}},
      muiDetailPanelProps: ({ row }) => ({
        sx: {
          backgroundColor:'#fcfcfa'
        },
      }),
      

  });
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2} alignItems='center'>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Quality Analysis
    </Typography>
    <Box sx={{flexGrow:1}}/>
    {dayWork&&<Button
      variant="contained"
      color="primary"
      disabled={disablerb}
      //startIcon={<Uploa />}
      onClick={()=>{
        if(dayWork.revStatus==0)
        {
            startAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:1}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==1||dayWork.revStatus==-1)
        {
            stopAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:2}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==2)
        {
            restartAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:-1}))}).catch((err)=>{console.log(err)});
        }
      }}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      { rbtitle}
    </Button>}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{}} components={['DatePicker','DatePicker']}>
      <DesktopDatePicker 
      format='DD-MM-YYYY'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work Date"
          value={date}
          onChange={(newValue) => {
            const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate(newValue);}}
        />
         </DemoContainer>
    </LocalizationProvider>
  <Box paddingTop={1}>
        <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
      <InputLabel id="demo-select-small-label">Processor</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={user}
        label="Processor"
        onChange={(e) => setUser(e.target.value)}
        //sx={{flexDirection:'column',justifyContent:'end',alignItems:'end'}}
        >
       {users.map((child)=>{ 
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
      </Select>
    </FormControl>
    </Box>
            </Stack>
            <Box sx={{ opacity: openbd ? 0.5 : 1,pointerEvents:openbd ? "none" : "auto"}}>
      <MaterialReactTable
     table={domainTable}
    />
    </Box>
            </Stack>
            </ThemeProvider>
    )
 }