import { Alert, Autocomplete, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Snackbar, Stack, Switch, Tab, Tabs, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useRef, useState } from "react";
import { allocateSocialDomain, allocateSocialToProcessor, allocateToProcessor, allocateToTeam, getMySocialAllocations, getNonAlloc, getSocialChildren, getSocialsBank, getUserNTeams, uploadDomains, uploadSocialDomains } from "./api";
import { Add, DeleteOutline, FiberManualRecord, LocalMallOutlined, PanToolAltOutlined, ShortcutOutlined, SportsHandballOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { isAdmin } from "./msalService";
import UploadCSVDialog from "./UploadCSVDialog";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser } from "./UserProvider";
import UploadCSVDialogSocialDomains from "./UploadCSVDialogSocialDomains";


export default function SocialsBank() 
{
    let [domainso,setDomainso]= useState([]);

    let [val,setVal]= useState("");
    const [tvalue, setTvalue] = useState('Ltd');
    let [psize,setPsize]= useState(50);
    let[idds, setIdds]= React.useState([]);
    const[openDialog, setOpenDialog]=useState(false);
    const[openAllocate, setOpenAllocate]=useState(false);
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const[domains,setDomains]=useState([]);
    const [agent, setAgent] = useState([]);
    const [children, setChildren] = useState([]);
    const {user}=useUser();
  const [ids, setIds] = useState([]);
  const[b, setB]=useState(false);
  let allotted=useRef([0,0]);
  const[allocating, setAllocating]=useState(false);


  

  
  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  
    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleCloseAllocate = () => {
        setOpenAllocate(false);
      };
      useEffect(()=>{console.log(domains);
      if(domains&&domains.length>0)
         uploadSocialDomains(domains).then((res)=>{setUpsuc(true);setDomains([]); setB(!b)}).catch((err)=>{console.log(err);setUpfail(true)});},[domains])

    const columns = [
      { field: 'dateOfUpload', headerName: 'Date', width: 100,valueGetter: (params) => {return params.row.dateOfUpload?(new Date(params.row.dateOfUpload)).toISOString().split('T')[0]:"N/A"} 
    },
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain}
  },
  { field: 'deadline', headerName: 'Deadline', width: 100, valueGetter: (params) => {return params.row.deadline?(new Date(params.row.deadline)).toISOString().split('T')[0]:"N/A"} ,

  },
  { field: 'edate', headerName: 'E-Date', width: 100, valueGetter: (params) => {return params.row.edate?(new Date(params.row.edate)).toISOString().split('T')[0]:"N/A"} ,
},
    {
      field: 'list',
      headerName: 'List',
      width: 70,valueGetter: (params) => {return params.row.list}
    },
    { field: 'uploader', headerName: 'Uploaded by', width: 110, valueGetter: (params) => { if(params.row.uploader) return params.row.uploader.firstName+" "+ params.row.uploader.lastName}},
  ]
  const [checked, setChecked] = React.useState(true);

 

    useEffect(()=>{//console.log(user);
      getSocialsBank().then((res)=>{console.log(res.data);
        const arr=res.data;
       
        setDomainso(arr);console.log(arr);}).catch((err)=>{console.log(err)})
      getSocialChildren().then((res)=>{setChildren(res.data);}).catch((err)=>{console.log(err)})
      },[b])
        function CustomToolbar(props) {
          const ids=props.ids
          return (
            <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
              <Box flexGrow={1}/>
              <Button
              disabled={idds.length<=0||allocating}
      variant="text"
      //color="primary"
      startIcon={<LocalMallOutlined />}
      onClick={()=>{setAllocating(true);allocateSocialDomain(idds,user.email).then((res)=>{allotted.current=res.data;
        //console.log(allotted);console.log(res.data);
        setUpsuc1(true);setB(!b);setIdds([]);setAgent("");setAllocating(false);}).catch((err)=>{setUpfail1(true);setAllocating(false)})}}
      sx={{
        textTransform:'none'
      }}
    >
      {allocating?"Claiming...":"Claim"}
    </Button>
              {children.length>0&&<Button
              disabled={idds.length<=0}
      variant="text"
      //color="primary"
      startIcon={<PanToolAltOutlined />}
      onClick={()=>{setOpenAllocate(true)}}
      sx={{
        textTransform:'none'
      }}
    >
      Allocate
    </Button>}
   {isAdmin()&& <>
    <Button
      variant="text"
      //color="primary"
      startIcon={<DeleteOutline />}
      onClick={()=>{}}
      sx={{
        textTransform:'none'
      }}
    >
      Delete
    </Button>
    <Button
      variant="text"
      //color="primary"
      startIcon={<ShortcutOutlined />}
      onClick={()=>{}}
      sx={{
        textTransform:'none'
      }}
    >
      Move
    </Button></>}
            </GridToolbarContainer>
          );
        }
    return (
    <ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Claim Hub
    </Typography>
    <Box sx={{flexGrow:1}}/>
    <Autocomplete
      disablePortal
      disabled={true}
      id="combo-box-demo"
      //options={domainso}
      sx={{ '& .MuiInputBase-root': {
        borderRadius: 100, // Use the value you want for the border-radius
      }, width: 300, height:40, borderRadius:100 }}
     
      size='small'
      //onChange={(event,value)=>{if(value==null) setVal(""); else if (typeof value === 'string')setVal(value); else setVal(value.domain);}}
      renderInput={(params) => <TextField  {...params}  />}
    />
     {isAdmin()&&<><Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Domains
    </Button>
    <UploadCSVDialogSocialDomains title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} /></>}
   
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity={allotted.current[0]==0?"error":"success"} sx={{ width: '100%' }}>
          Allotted {allotted.current[0]}/{allotted.current[1]} domain{allotted.current[0]!=1&&"s"}.
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
    <TabContext value={tvalue} >
    <Box sx={{ width: '100%', }}>
      
    </Box>
    <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to  Processor </Typography> </Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length+" "+ tvalue} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Agent</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={agent}
            label="Select Processor"
            onChange={handleAgentChange}
          >
            {children.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
            {/* ... other options */}
          </Select>
        </FormControl>
        
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button disabled={allocating} variant="contained" onClick={()=>{setAllocating(true);allocateSocialDomain(idds,agent).then((res)=>{allotted.current=res.data;setUpsuc1(true);setB(!b);setIdds([]);setAgent("");handleCloseAllocate(); setAllocating(false);}).catch((err)=>{setUpfail1(true);setAllocating(false)})}}>Allocate</Button>
      </DialogActions>
      </Dialog>
    <Box sx={{width:'100%',height:'100%'}}>
      <DataGrid autoHeight 
        rows={domainso}
        columns={columns}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      />
      </Box>
   
    </TabContext>
    
        </Stack>
    </ThemeProvider>
    );
}