import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { useCSVReader , formatFileSize, lightenDarkenColor,} from "react-papaparse";

export default function UploadCSVDialogOwnedDomains(props) 
{
    const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: 150,//'100%',
    width: '100%',//350
    justifyContent: 'center',
    padding: 20,
  } ,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 110,
    width: '100%',//350
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } ,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } ,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  } ,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [col, setCol] = React.useState([]);
const [val, setVal] = React.useState([]);
const [registrar, setRegistrar] = React.useState("");
const [domains, setDomains] = React.useState("");
const [disabledRegistrar, setDisabledRegistrar] = React.useState(true);

useEffect(() => {
  if(domains.length>0)
{
  const domainList = domains.split(/[,\n]/)
    .map(domainName => ({ domain: domainName.trim() }))
    .filter(domain => domain.domain !== '');
setVal(domainList);
setDisabled(false);
}
}, [domains]);



  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth>
        <DialogTitle>Add {props.title}</DialogTitle>
        <DialogContent>
        <FormControl size="small" fullWidth sx={{marginBottom:2,marginTop:2}}>
          <InputLabel id="registrar-select-label">Select Registrar</InputLabel>
          <Select size="small"
            labelId="registrar-select-label"
            id="registrar-select"
            value={registrar}
            label="Select Registrar"
            onChange={(event) => {setRegistrar(event.target.value); setDisabledRegistrar(event.target.value=="");}}
          >
            {props.registrars.map((registrarOption, index) => (
              <MenuItem key={index} value={registrarOption.registrar}>
                {registrarOption.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" spacing={2}>
          <Box width='50%'>
          <TextField  multiline placeholder="example.com, example.net, example.org"  rows={5} fullWidth value={domains} onChange={(event) => {setDomains(event.target.value);}} />
          </Box>
          <Box width='50%'>
        <CSVReader
        
  onUploadAccepted={(results) => {
    console.log('---------------------------');
    console.log(results);
    console.log('---------------------------');
    setVal(results.data)
    setZoneHover(false);
    setDisabled(false);

  }}
  config={{ worker:false, header:true, transformHeader:function(h) {
    return h.toLowerCase().replace(/\s/g,'').trim();
  }}}
  onDragOver={(event) => {
    event.preventDefault();
    setZoneHover(true);
  }}
  onDragLeave={(event) => {
    event.preventDefault();
    setZoneHover(false);
  }}
>
  {({
    getRootProps,
    acceptedFile,
    ProgressBar,
    getRemoveFileProps,
    Remove,
  }) => (
    <>
      <div
        {...getRootProps()}
        style={Object.assign(
          {},
          styles.zone,
          zoneHover && styles.zoneHover
        )}
      >
        {acceptedFile ? (
          <>
            <div style={styles.file}>
              <div style={styles.info}>
                <span style={styles.size}>
                  {formatFileSize(acceptedFile.size)}
                </span>
                <span style={styles.name}>{acceptedFile.name}</span>
              </div>
              <div style={styles.progressBar}>
                <ProgressBar />
              </div>
              <div
                {...getRemoveFileProps()}
                style={styles.remove}
                onMouseOver={(event) => {
                  event.preventDefault();
                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                }}
                onMouseOut={(event) => {
                  event.preventDefault();
                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                }}
              >
                <Remove color={removeHoverColor} />
              </div>
            </div>
          </>
        ) : (
          'Drop CSV file here or click to upload'
        )}
      </div>
    </>
  )}
</CSVReader>
</Box>
</Stack>
        </DialogContent>
        <DialogActions>
        <Button disabled={disabled||disabledRegistrar} onClick={()=>{ props.setRegistrar(registrar); props.setState(val); setVal([]);setRegistrar(""); setDisabled(true); setDisabledRegistrar(true); props.handleClose();}}>Upload</Button>
        </DialogActions>
       
      </Dialog>
    )
}