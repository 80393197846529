import { Alert, Autocomplete, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Snackbar, Stack, Switch, Tab, Tabs, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { allocateToProcessor, allocateToTeam, getNonAlloc, getUserNTeams, uploadDomains } from "./api";
import { Add, DeleteOutline, FiberManualRecord, PanToolAltOutlined, ShortcutOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { isAdmin } from "./msalService";
import UploadCSVDialog from "./UploadCSVDialog";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser } from "./UserProvider";


export default function NonAllocated() 
{
    const [domainsl,setDomainsl]= useState([]);
    let [domainso,setDomainso]= useState([]);
    let [domainsp,setDomainsp]= useState([]);
    let [domainsa,setDomainsa]= useState([]);
    let [domains1,setDomains1]= useState([]);

    let [val,setVal]= useState("");
    const [tvalue, setTvalue] = useState('Ltd');
    let [psize,setPsize]= useState(50);
    let[idds, setIdds]= React.useState([]);
    const[openDialog, setOpenDialog]=useState(false);
    const[openAllocate, setOpenAllocate]=useState(false);
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const[domains,setDomains]=useState([]);
    const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const [ids, setIds] = useState([]);
  const[b, setB]=useState(false);


  
  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };
    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleCloseAllocate = () => {
        setOpenAllocate(false);
      };
      useEffect(()=>{console.log(domains);
      if(domains&&domains.length>0)
         uploadDomains(domains).then((res)=>{setUpsuc(true);setDomains([])}).catch((err)=>{console.log(err);setUpfail(true)});},[domains])

    const columnsl = [
        { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.deadline', headerName: 'Deadline', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.deadline))} },
        { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
    },
    ,
      {
        field: 'EST',
        headerName: 'EST',
        type: 'number',
        width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "Me"}},
    ]

    const columnsp = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
  },
  { field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} },
  ,
  {
    field: 'domain.orderStatus',
    headerName: 'Order Status',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
},  { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  },
  ,
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
  },
  { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "Me"}},
  ]

  const columnsa = [
    { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
},
{ field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} },
,
{
  field: 'domain.orderStatus',
  headerName: 'Order Status',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
},  { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},
,
  {
    field: 'EST',
    headerName: 'EST',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{ field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "Me"}},
]


    const columnso = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  },
  ,
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    {
      field: 'PP',
      headerName: 'PP',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.PP}
    },
    { field: 'came via', headerName: 'Allocator', width: 150, valueGetter: (params) => {if(params.row.allocator) return params.row.allocator.firstName+" "+ params.row.allocator.lastName; else return "Me"}},
  ]
  const { user, updateUser } = useUser();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event, newValue) => {
    setTvalue(newValue);
  };

    useEffect(()=>{//console.log(user);
      getNonAlloc().then((res)=>{console.log(res.data);
        const arr=res.data;
       
        let al=[],ao=[],ap=[],aa=[];
        
        for(let i=0;i<res.data.length;i++)
        {
           
        if(arr[i].domain.portfolio=="owned")
        ao.push(arr[i]);
        else if(arr[i].domain.portfolio=="ald")
        aa.push(arr[i]);
        else if(arr[i].domain.portfolio=="pd")
        ap.push(arr[i]);
      else al.push(arr[i])
        }
        setDomainsl(al);setDomainso(ao);setDomainsp(ap);setDomainsa(aa);console.log(al);}).catch((err)=>{console.log(err)})},[b])
        function CustomToolbar(props) {
          const ids=props.ids
          return (
            <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
              <Box flexGrow={1}/>
              <Button
              disabled={idds.length<=0}
      variant="text"
      //color="primary"
      startIcon={<PanToolAltOutlined />}
      onClick={()=>{
        setOpenAllocate(true)
      }}
      sx={{
        textTransform:'none'
      }}
    >
      Allocate
    </Button>
   {isAdmin()&& <>
    <Button
      variant="text"
      //color="primary"
      startIcon={<DeleteOutline />}
      onClick={()=>{}}
      sx={{
        textTransform:'none'
      }}
    >
      Delete
    </Button>
    <Button
      variant="text"
      //color="primary"
      startIcon={<ShortcutOutlined />}
      onClick={()=>{}}
      sx={{
        textTransform:'none'
      }}
    >
      Move
    </Button></>}
            </GridToolbarContainer>
          );
        }
    return (
    <ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Non Allocated List
    </Typography>
    <Box sx={{flexGrow:1}}/>
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      //options={domainso}
      sx={{ '& .MuiInputBase-root': {
        borderRadius: 100, // Use the value you want for the border-radius
      }, width: 300, height:40, borderRadius:100 }}
     
      size='small'
      //onChange={(event,value)=>{if(value==null) setVal(""); else if (typeof value === 'string')setVal(value); else setVal(value.domain);}}
      renderInput={(params) => <TextField  {...params}  />}
    />
     {isAdmin()&&<><Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} /></>}
   
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
    <TabContext value={tvalue} >
    <Box sx={{ width: '100%', }}>
        
      <TabList
        
        onChange={handleChange}
        aria-label="secondary tabs example"
        sx={{
            width: '720px',
            backgroundColor: '#ffffff',
            borderRadius: '0',
            '& .MuiTab-root': {
              '&.Mui-selected': {
                backgroundColor: '#fef7ff',
              },
              '&:hover': {
                backgroundColor: '#fef7ff',
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#fef7ff',
              },
            },
            
          }}
      >
        <Tab sx={{width:'180px'}} value="Ltd" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>LTD</Typography></Stack>} />
        <Tab sx={{width:'180px'}}value="Owned" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>Owned</Typography></Stack>} />

<Tab sx={{width:'180px'}}value="PD" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>PD</Typography></Stack>} />
                <Tab sx={{width:'180px'}}value="ALD" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>ALD</Typography></Stack>} />
      </TabList>
    </Box>
    <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to </Typography> <FormControlLabel sx={{p:0,m:0,ml:1}} control={<Switch checked={checked} onChange={(event)=>{setChecked(event.target.checked);}} size='small'/>} label={<Typography sx={{p:0,m:0}} variant="h6">{checked?'Processor':"Team"}</Typography>}  labelPlacement="start" /></Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length+" "+ tvalue} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Processor</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={processor}
            label="Select Processor"
            onChange={handleProcessorChange}
          >
            {user.users.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
            <MenuItem value={user.email}>Me</MenuItem>
            {/* ... other options */}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-label">Select Team</InputLabel>
          <Select disabled={checked}
            labelId="team-label"
            id="team-select"
            value={team}
            label="Select Team"
            onChange={handleTeamChange}
          >
           {user.teams.map((child)=>{
              return <MenuItem value={child.email}>Team {child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button variant="contained" onClick={()=>{if(checked)
        {
          allocateToProcessor(idds,processor).then(()=>{setUpsuc1(true); setB(!b)}).catch(()=>{setUpfail1(true)})
        }
        else allocateToTeam(idds,team).then(()=>{setUpsuc1(true); setB(!b)}).catch(()=>{setUpfail1(true)})
        handleCloseAllocate();
        }}>Allocate</Button>
      </DialogActions>
    </Dialog>
    <TabPanel value="Ltd" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
        rows={domainsl}
        columns={columnsl}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="Owned" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{width:'100%'}} >
      <DataGrid autoHeight 
        rows={domainso}
        columns={columnso}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="ALD" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
        rows={domainsa}
        columns={columnsa}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="PD" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
        rows={domainsp}
        columns={columnsp}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    </TabContext>
    
        </Stack>
    </ThemeProvider>
    );
}