import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, Button,
  Snackbar, Alert, Tab, Tooltip
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Upload, Sort as SortIcon, ContentCopy } from "@mui/icons-material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { theme } from '../Theme';
import { getOrderHubData, getEsOrderHubData, placeOrders } from '../api';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MultiSortDialog from '../MultiSortDialog';


function OrdersHub() {
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [mode, setMode] = useState('ed');
  const [rowSelection, setRowSelection] = useState({});
  const [tabValue, setTabValue] = useState('ALL');
  const [loading, setLoading] = useState(false);
  const [multiSortDialogOpen, setMultiSortDialogOpen] = useState(false);

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isEDManagerUser = isEDManager();

  const listTypes = mode === 'ed'
    ? ['ALL', 'T0', 'T1', 'T-BA', 'T-PD', 'T-PN', 'T-PC']
    : ['ALL', 'T0', 'T1', 'T-PD', 'T-PN', 'T-PC', 'T-RP'];

  useEffect(() => {
    let initialMode = 'ed';
    if (isAdminUser||isEDManagerUser) {
      initialMode = 'ed'; 
    } else if (isESManagerUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
    fetchOrderHubData(initialMode, tabValue);
  }, [isAdminUser, isESManagerUser, isEDManagerUser]);

  const fetchOrderHubData = (currentMode, tab) => {
    setLoading(true);
    const apiCall = currentMode === 'ed' ? getOrderHubData : getEsOrderHubData;
    apiCall({ tab }).then(res => {
      if (Array.isArray(res.data)) {
        const transformedData = res.data.map(item => ({
          acquShortlistedDomain: {
            id: item.id,
            domain: item.domain,
            list: item.list,
            host: item.host,
            tld: item.tld,
            wby: item.wby,
            aby: item.aby,
            reg: item.reg,
            regx: item.regx,
            sg: item.sg,
            cpc: item.cpc,
            bids: item.bids,
            price: item.price,
            est: item.est,
            gdv: item.gdv,
            esw: item.esw,
            gdw: item.gdw,
            reco: item.reco,
            apr: item.apr,
            edate: item.edate,
            p: item.p,
            len: item.len,
            spr500: item.spr500,
            sprGdv: item.sprGdv,
            remarks: item.remarks,
            aqCategory: item.aqCategory,
            status: item.status,
            aprUnaprBy: item.aprUnaprBy,
            recoBy: item.recoBy,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          },
          agents: {
            uploader: currentMode === 'ed' 
              ? (item.user ? {
                  name: `${item.user.firstName} ${item.user.lastName}`,
                  email: item.user.email,
                  role: 'Uploader'
                } : null)
              : (item.aqShortlistings?.length > 0 
                  ? item.aqShortlistings.map(shortlisting => ({
                      name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                      email: shortlisting.user.email,
                      role: 'Shortlister'
                    }))
                  : []),
            processor: item.socialDomain?.socialWorks?.[0]?.user ? {
              name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
              email: item.socialDomain.socialWorks[0].user.email,
              role: 'Processor'
            } : null,
            recommender: item.recoBy ? {
              name: typeof item.recoBy === 'string' 
                ? item.recoBy 
                : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
              email: item.recoBy.email,
              role: 'Recommender'
            } : null,
            approver: item.aprUnaprBy ? {
              name: item.aprUnaprBy,
              role: 'Approver'
            } : null
          }
        }));
        setData(transformedData);
      } else {
        console.error('API response is not an array:', res);
        setData([]);
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
      setData([]);
    }).finally(() => {
      setLoading(false);
    });
  }

  // useEffect(()=>{
  //   console.log(rowSelection);
  // },[rowSelection]);

  const handleSubmit = () => {
    setLoading(true);
    // const selectedDomains = Object.keys(rowSelection)
    //   .filter(key => rowSelection[key])
    //   .reduce((acc, key) => {
    //     const domain = data[key].acquShortlistedDomain;
    //     acc[domain.id] = {
    //       id: domain.id,
    //       domain: domain.domain,
    //       list: domain.list,
    //       status: domain.status,
    //       aqCategory: domain.aqCategory,
    //       sourceType: 'ed', // or get from original data if needed
    //     };
    //     return acc;
    //   }, {});

    placeOrders(rowSelection).then(res => {
      setSnackbar({ open: true, message: 'Orders placed successfully', severity: 'success' });
      setRowSelection({});
      fetchOrderHubData(mode, tabValue);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Error placing orders', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleOpenMultiSortDialog = () => {
    setMultiSortDialogOpen(true);
  };

  const handleCloseMultiSortDialog = () => {
    setMultiSortDialogOpen(false);
  };

  const handleApplyMultiSort = (sortCriteria) => {
    table.setSorting(sortCriteria);
  };

  const handleCopyDomains = useCallback((selectedRows) => {
    const domains = selectedRows.map(row => row.original.acquShortlistedDomain.domain).join('\n');
    navigator.clipboard.writeText(domains).then(() => {
      setSnackbar({ open: true, message: 'Domains copied to clipboard', severity: 'success' });
    }).catch(err => {
      setSnackbar({ open: true, message: 'Failed to copy domains', severity: 'error' });
    });
  }, []);

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Define which columns to export
      const exportColumns = [
        'domain', 'list', 'p', 'len', 'host', 'tld', 'wby', 'aby', 
        'reg', 'regx', 'sg', 'cpc', 'bids', 'price', 'est', 'gdv', 
        'esw', 'gdw', 'reco', 'remarks'
      ];

      // Create CSV header
      const header = exportColumns.join(',') + '\n';

      // Create CSV content from selected rows
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          const value = row.original.acquShortlistedDomain[col];
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      // Combine header and content
      const csv = header + csvContent;

      // Create and trigger download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `domains_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbar({ 
        open: true, 
        message: 'Data exported successfully', 
        severity: 'success' 
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Failed to export data', 
        severity: 'error' 
      });
    }
  }, []);
  
  // useEffect(()=>{
  //   console.log(data);
  // },[data]);

  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'edate', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const date = row.acquShortlistedDomain.edate;
        return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original.acquShortlistedDomain.edate;
        const dateB = rowB.original.acquShortlistedDomain.edate;
        
        // Handle cases where dates are null/undefined
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Convert dates to timestamps for comparison
        const timeA = new Date(dateA).getTime();
        const timeB = new Date(dateB).getTime();
        
        return timeA - timeB;
      }
    },
    { 
      accessorKey: 'list', 
      header: 'list', 
      size: 35, 
      minSize: 35, 
      maxSize: 50,
      accessorFn: (row) => row.acquShortlistedDomain.list || '-',
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'domain', 
      header: 'domain', 
      size: 120, 
      minSize: 120, 
      maxSize: 150,
      accessorFn: (row) => row.acquShortlistedDomain.domain,
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'p', 
      header: 'p', 
      size: 40, 
      minSize: 40, 
      maxSize: 40,
      accessorFn: (row) => row.acquShortlistedDomain.p || '-',
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'bids', 
      header: 'bids', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => row.acquShortlistedDomain.bids || '-',
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'price', 
      header: 'price', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => row.acquShortlistedDomain.price || '-',
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'est', 
      header: 'est', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.est;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdv', 
      header: 'gdv', 
      size: 50, 
      minSize: 50, 
      maxSize: 60,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdv;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'esw', 
      header: 'esw', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.esw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdw', 
      header: 'gdw', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'spr500', 
      header: 'spr500', 
      size: 60,
      minSize: 60,
      maxSize: 80,
      accessorFn: (row) => {
        const spr500 = row.acquShortlistedDomain.spr500;
        return spr500 !== null && spr500 !== undefined ? `${Math.round(spr500 * 100)}%` : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.acquShortlistedDomain.spr500;
        const b = rowB.acquShortlistedDomain.spr500;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    { 
      accessorKey: 'sprGdv', 
      header: 'sprgdv', 
      size: 60,
      minSize: 60,
      maxSize: 80,
      accessorFn: (row) => {
        const sprGdv = row.acquShortlistedDomain.sprGdv;
        return sprGdv !== null && sprGdv !== undefined ? `${Math.round(sprGdv * 100)}%` : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.acquShortlistedDomain.sprGdv;
        const b = rowB.acquShortlistedDomain.sprGdv;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    { 
      accessorKey: 'reco', 
      header: 'reco', 
      size: 50, 
      minSize: 50, 
      maxSize: 60,
      accessorFn: (row) => row.acquShortlistedDomain.reco || '-',
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'apr', 
      header: 'apr', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => row.acquShortlistedDomain.apr || '-',
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'aqCategory', 
      header: 'category', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => row.acquShortlistedDomain.aqCategory || '-',
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'status', 
      header: 'status', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => row.acquShortlistedDomain.status || '-',
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'agents',
      header: 'agent',
      size: 70,
      minSize: 70,
      maxSize: 100,
      Cell: ({ row }) => {
        const agents = Object.entries(row.original.agents).flatMap(([key, value]) => {
          if (key === 'uploader' && Array.isArray(value)) {
            return value;
          }
          return value ? [value] : [];
        }).filter(agent => agent !== null);

        return (
          <Stack 
            direction="row" 
            spacing={0.25}
            alignItems="center"
            sx={{ 
              flexWrap: 'wrap',
              gap: '1px'
            }}
          >
            {agents?.map((agent, index) => {
              if (!agent) return null;

              // Handle cases where name might be an object or string
              let displayName = '';
              if (typeof agent.name === 'string') {
                displayName = agent.name;
              } else if (agent.name?.firstName && agent.name?.lastName) {
                displayName = `${agent.name.firstName} ${agent.name.lastName}`;
              }

              // Skip if we couldn't construct a valid display name
              if (!displayName) return null;

              // Get initials from display name
              const initials = displayName
                .split(' ')
                .filter(part => part.length > 0)
                .slice(0, 2)
                .map(part => part[0].toUpperCase())
                .join('');

              // Different background colors for different roles
              const getBackgroundColor = (role) => {
                switch (role) {
                  case 'Uploader':
                  case 'Shortlister': return '#e3f2fd';
                  case 'Processor': return '#f1f8e9';
                  case 'Recommender': return '#fff3e0';
                  case 'Approver': return '#fce4ec';
                  default: return '#f5f5f5';
                }
              };

              return (
                <Tooltip
                  key={`${index}-${displayName}`}
                  title={
                    <Box sx={{ p: 0.5 }}>
                      <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                        {displayName}
                      </Typography>
                      {agent.email && (
                        <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                          {agent.email}
                        </Typography>
                      )}
                      {agent.role && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            display: 'block', 
                            fontSize: '0.7rem',
                            fontStyle: 'italic',
                            mt: 0.5
                          }}
                        >
                          Role: {agent.role}
                        </Typography>
                      )}
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box
                    sx={{
                      backgroundColor: getBackgroundColor(agent.role),
                      border: '1px solid rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: 'medium',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        textAlign: 'center'
                      }}
                    >
                      {initials}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
      sortingFn: (rowA, rowB) => {
        const getAgentsString = (row) => {
          return Object.values(row.original.agents)
            .filter(agent => agent !== null)
            .map(agent => agent.name)
            .sort()
            .join(',');
        };
        
        return getAgentsString(rowA).localeCompare(getAgentsString(rowB));
      }
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableSorting: true,
    enableMultiSort: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableStickyHeader: true,
    getRowId: (row) => {if(row.acquShortlistedDomain)return row.acquShortlistedDomain.id;else return row.id},
    initialState: {
      density: 'compact',
      pagination: { pageSize: 200, pageIndex: 0 },
      sorting: [],
    },
    state: { rowSelection,isLoading: loading, },
    onRowSelectionChange: setRowSelection,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const buttonSx = {
        textTransform: 'none',
        backgroundColor: '#8797A8',
        color: '#FFFFFF !important',
        padding: '4px 8px',
        minWidth: 'auto',
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
        '&:hover': {
          backgroundColor: '#8797A8',
          opacity: 0.9,
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        },
        '&.Mui-disabled': {
          backgroundColor: '#8797A8',
          opacity: 0.7,
        }
      };

      return (
        <Stack direction="row" spacing={1} width="100%" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={0.5}>
            <Button
              size="small"
              startIcon={<SortIcon />}
              onClick={handleOpenMultiSortDialog}
              sx={buttonSx}
            >
              Sort
            </Button>
            <Button
              size="small"
              startIcon={<ContentCopy />}
              onClick={() => handleCopyDomains(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Copy
            </Button>
            <Button
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => handleExportData(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Export
            </Button>
            <Button
              size="small"
              startIcon={<Upload />}
              onClick={handleSubmit}
              disabled={Object.keys(rowSelection).filter(key => rowSelection[key]).length === 0}
              sx={buttonSx}
            >
              Place Orders
            </Button>
          </Stack>
        </Stack>
      );
    },

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#e6e6fa',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '0.7rem', 
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic', 
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic', 
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
  });

  const handleTabChange = (event, newValue) => {
    setRowSelection({});
    table.resetRowSelection();
    setTabValue(newValue);
    fetchOrderHubData(mode, newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Orders Hub
          </Typography>
          <Box flexGrow={1}></Box>
          {(isAdminUser||(isEDManagerUser&&isESManagerUser)) && (
            <Button
              variant="outlined"
              onClick={() => {
                const newMode = mode === 'ed' ? 'es' : 'ed';
                setMode(newMode);
                fetchOrderHubData(newMode, tabValue);
              }}
              sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
            >
              Switch To {mode === 'ed' ? 'ES' : 'ED'}
            </Button>
          )}
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab 
                  key={listType} 
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <MaterialReactTable table={table} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <MultiSortDialog
          open={multiSortDialogOpen}
          onClose={handleCloseMultiSortDialog}
          columns={columns}
          initialSorting={table.getState().sorting}
          onApplySort={handleApplyMultiSort}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}

export default OrdersHub;
