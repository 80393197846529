import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useMemo, useState } from "react";
import UploadCSVDialog from "./UploadCSVDialog";
import { canAddLtd, canAddPD, isAdmin } from "./msalService";
import { Add, Edit, PanToolAltOutlined } from "@mui/icons-material";
import { allocateDomainToProcessor, allocateDomainToTeam, editPDDomains, getAllLtdDomains, getAllOwnedDomain, getAllPDDomains,getAllPDDomainsWithPagination, getAllTags, uploadDomains, uploadLtdDomains, uploadOwnedDomains, uploadPDDomains } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from "./UserProvider";

export default function MyDomainsPD() 
{
   
//const arr=[];

      const[domains,setDomains]=useState([]);
      const[editDomains,setEditDomains]=useState([]);
      const[data,setData]=useState([]);
      const[tags,setTags]=useState([]);
      const[openDialog, setOpenDialog]=useState(false);
      const[openDialog1, setOpenDialog1]=useState(false);
      const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);

    let[idds, setIdds]= React.useState([]);
    const[openAllocate, setOpenAllocate]=useState(false);
    const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const {user, updateUser} = useUser();
  const [checked, setChecked] = React.useState(true);

  const [pagination, setPagination] = useState({pageIndex: 0,pageSize: 50,});
  const [totalRowCount, setTotalRowCount] = useState(0);

  const fetchData = async () => {
    try {
      const res = await getAllPDDomainsWithPagination({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
      setData(res.data.content);
      setTotalRowCount(res.data.totalElements);
    } catch (err) {
      console.log(err);
    }
  };

    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleClose1 = () => {
        setOpenDialog1(false);
      };

      const handleProcessorChange = (event) => {
        setProcessor(event.target.value);
      };
    
      const handleTeamChange = (event) => {
        setTeam(event.target.value);
      };
      
          const handleCloseAllocate = () => {
            setOpenAllocate(false);
          };

          useEffect(() => {
            console.log(editDomains);
            if (editDomains && editDomains.length > 0)
              editPDDomains(editDomains)
                .then((res) => {
                  console.log(res);
                  setUpsuc(true);
                  setEditDomains([]);
                  fetchData();
                })
                .catch((err) => {
                  console.log(err);
                  setUpfail(true);
                });
          }, [editDomains]);
          useEffect(() => {
            console.log(domains);

            if (domains && domains.length > 0)
              uploadPDDomains(domains)
                .then((res) => {
                  setUpsuc(true);
                  setDomains([]);
                  fetchData();
                })
                .catch((err) => {
                  console.log(err);
                  setUpfail(true);
                });
          }, [domains]);

          useEffect(() => {
            fetchData();
            getAllTags()
              .then((res) => {
                const arr = res.data.map((ar) => ar.tag);
                console.log(arr);
                setTags(arr);
              })
              .catch((err) => {
                console.log(err);
              });
            }, [pagination.pageIndex, pagination.pageSize]);

           const columns = useMemo(
            () => [
              // {
              //   id: 'dom', //id used to define `group` column
              //   header: 'Domain',
              //   columns: [
                  {
                    accessorKey:'platform', //accessorFn used to join multiple data into a single cell
                    id: 'platform', //id is still required when using accessorFn instead of accessorKey
                    header: 'Platform',
                    // enableColumnFilterModes:false,
                    // filterFn: (row, id, filterValue) => { console.log(filterValue); return true; //return row.tags[0].tag === filterValue;
                    // },
                    //size: 250,
                    
                  },
                  {
                    accessorKey:'domain', //accessorFn used to join multiple data into a single cell
                    id: 'domain', //id is still required when using accessorFn instead of accessorKey
                    header: 'Domain',
                    // enableColumnFilterModes:false,
                    // filterFn: (row, id, filterValue) => { console.log(filterValue); return true; //return row.tags[0].tag === filterValue;
                    // },
                    //size: 250,
                    
                  }, {
                    id: 'dateOfUpload', //id is still required when using accessorFn instead of accessorKey
                    header: 'Add Date',
                    accessorFn: (originalRow) => { const date=new Date(originalRow.dateOfUpload);return date}, //convert to date for sorting and filtering
                    filterVariant: 'date-range',
                    Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0], //format date for display
                    //size: 250,
                  }, {
                    id: 'allocatedTo',
                    header: 'Allocated To',
                    accessorFn: (row) => {
                      if (row.presentAllocation && row.presentAllocation.user) {
                        return `${row.presentAllocation.user.firstName} ${row.presentAllocation.user.lastName}`;
                      } else if (row.presentTeamAllocations && row.presentTeamAllocations.length > 0) {
                        return `${row.presentTeamAllocations[0].user.firstName} ${row.presentTeamAllocations[0].user.lastName}`;
                      } else {
                        return 'Not Allocated';
                      }
                    },
                    Cell: ({ cell, row }) => {
                      const value = cell.getValue();
                      const isProcessor = row.original.presentAllocation && row.original.presentAllocation.user;
                      const isTeam = row.original.presentTeamAllocations && row.original.presentTeamAllocations.length > 0;
                      const backgroundColor = isProcessor ? '#e0f7fa' : isTeam ? '#e8f5e9' : '#ffffff'; // Light blue for processor, light green for team, white for not allocated

                      return (
                        <Box sx={{ backgroundColor, padding: '8px', borderRadius: '4px' }}>
                          {value}
                        </Box>
                      );
                    },
                  },{
                    id: 'orderDate', //id is still required when using accessorFn instead of accessorKey
                    header: 'Order Date',
                    accessorFn: (originalRow) => { const date=new Date(originalRow.orderDate);return date}, //convert to date for sorting and filtering
                    filterVariant: 'date-range',
                    Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
                    //size: 250,
                    
                  }
                  , {
                    accessorKey:'orderStatus', //accessorFn used to join multiple data into a single cell
                    id: 'orderStatus', //id is still required when using accessorFn instead of accessorKey
                    header: 'Order Status',
                    //size: 250,
                    
                  }, 
                  // {
                  //   id: 'deadline', //id is still required when using accessorFn instead of accessorKey
                  //   header: 'Deadline',
                  //   accessorFn: (originalRow) => { const date=new Date(originalRow.deadline);return date}, //convert to date for sorting and filtering
                  //   filterVariant: 'date-range',
                  //   Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
                  //   //size: 250,
                    
                  // }, 
                  {
                    accessorKey:'EST', //accessorFn used to join multiple data into a single cell
                    id: 'EST', //id is still required when using accessorFn instead of accessorKey
                    header: 'EST',
                    //size: 250,
                    
                  },
                  {
                    accessorKey: 'GDV',
                    //enableClickToCopy: true,
                    //filterVariant: 'autocomplete',
                    header: 'GDV',
                    //size: 300,
                  }, {
                    accessorKey:'currentPrice', //accessorFn used to join multiple data into a single cell
                    id: 'currentPrice', //id is still required when using accessorFn instead of accessorKey
                    header: 'Current Price',
                    //size: 250,
                    
                  }, {
                    accessorKey:'reserve', //accessorFn used to join multiple data into a single cell
                    id: 'reserve', //id is still required when using accessorFn instead of accessorKey
                    header: 'Reserve',
                    //size: 250,
                    
                  }, {
                    accessorKey:'clientOffer', //accessorFn used to join multiple data into a single cell
                    id: 'clientOffer', //id is still required when using accessorFn instead of accessorKey
                    header: 'Client Offer',
                    //size: 250,
                    
                  },
              //   ],
              // },
              // {
              //   id: 'categ', //id used to define `group` column
              //   header: 'Categories',
              //   columns: [
                  {
                    //accessorKey:'type', //accessorFn used to join multiple data into a single cell
                    id: 'type', //id is still required when using accessorFn instead of accessorKey
                    header: 'Type',
                    accessorFn: (row) => {if(row.brandable) return 'Brandable'; else return 'Generic';},
                    //size: 250,
                    
                  }, {
                    accessorKey:'categoryMain', //accessorFn used to join multiple data into a single cell
                    id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
                    header: 'Category',
                    //size: 250,
                    
                  }, {
                    accessorKey:'categorySedo', //accessorFn used to join multiple data into a single cell
                    id: 'categorySedo', //id is still required when using accessorFn instead of accessorKey
                    header: 'SEDO Category',
                    //size: 250,
                    
                  }, {
                    accessorKey:'categoryDan', //accessorFn used to join multiple data into a single cell
                    id: 'categoryDan', //id is still required when using accessorFn instead of accessorKey
                    header: 'DAN Category',
                    
                    //size: 250,
                    
                  }
                  , {
                    accessorKey:'tags', //accessorFn used to join multiple data into a single cell
                    //id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
                    enableColumnFilterModes:false,
                    filterVariant:'multi-select',
                    filterFn: (row, id, filterValue) => {if(filterValue.length==0) return true; else {for(let i=0;i<row.original.tags.length;i++){ if(filterValue.includes(row.original.tags[i].tag)) return true;} return false;}//return row.tags[0].tag === filterValue;
                    },
                    filterSelectOptions: tags,
                    header: 'Tags',
                    Cell: ({ cell }) => {if(cell.getValue().length>0) return cell.getValue()[0].tag;}, //format date for display

                    //size: 250,
                    
                  },
              //   ],
              // },
              
              // {
              //   id: 'whois', //id used to define `group` column
              //   header: 'WhoIs',
              //   columns: [
                    {
                        //accessorKey:'type', //accessorFn used to join multiple data into a single cell
                        id: 'registrar', //id is still required when using accessorFn instead of accessorKey
                        header: 'Registrar',
                        accessorFn: (row) => {if(row.registrar) return row.registrar.registrar; else return 'N/A' },
                        //size: 250,
                        
                      }, 
                    {
                    accessorKey:'whoIsEmail', //accessorFn used to join multiple data into a single cell
                    id: 'whoIsEmail', //id is still required when using accessorFn instead of accessorKey
                    header: 'WhoIs Email',
                    //size: 250,
                    
                  }, {
                    accessorKey:'nameservers', //accessorFn used to join multiple data into a single cell
                    id: 'nameservers', //id is still required when using accessorFn instead of accessorKey
                    header: 'Nameservers',
                    //size: 250,
                    
                  },
              //   ],
              // },
              
              // {
              //   id: 'stats', //id used to define `group` column
              //   header: 'Stats',
              //   columns: [
                  {
                    accessorKey:'wby', //accessorFn used to join multiple data into a single cell
                    header: 'WBY',
                    //size: 250,
                    
                  }, {
                    accessorKey:'aby', //accessorFn used to join multiple data into a single cell
                    header: 'ABY',
                    //size: 250,
                    
                  }, {
                    accessorKey:'reg', //accessorFn used to join multiple data into a single cell
                    header: 'Reg',
                    //size: 250,
                    
                  }
                  , {
                    accessorKey:'regx', //accessorFn used to join multiple data into a single cell
                    header: 'Regx',
                    
                  }, {
                    accessorKey:'sg', 
                    header: 'SG',
                    
                  }, {
                    accessorKey:'cpc', 
                    header: 'CPC',
                    
                  }
              //   ],
              // }
            ],
            [tags]
          );
        
          const domainTable=  useMaterialReactTable({
            columns:columns,
            data:data,
            enableColumnFilterModes:true,

            manualPagination: true,
            rowCount: totalRowCount,
            onPaginationChange: setPagination,
            state: { pagination },

            muiTableBodyRowProps: ({ row }) => {
              const isProcessor = row.original.presentAllocation && row.original.presentAllocation.user;
              const isTeam = row.original.presentTeamAllocations && row.original.presentTeamAllocations.length > 0;
              const backgroundColor = isProcessor ? '#e0f7fa' : isTeam ? '#e8f5e9' : '#ffffff'; // Light blue for processor, light green for team, white for not allocated

              return {
                sx : {
                  backgroundColor,
                },
              };
            },  
            
            enableRowSelection: true,
            columnFilterDisplayMode: 'popover',
            muiPaginationProps: {
                color: 'secondary',
                rowsPerPageOptions: [50, 150, 250, 500,1000],
                shape: 'rounded',
                variant: 'outlined',
              },
            //enableEditing:true,
            //editDisplayMode:'cell',
            initialState: { density: 'compact' },
            renderTopToolbarCustomActions: ({ table }) => (
                <Stack direction='row-reverse' width='100%' alignItems='center'>
                {isAdmin()&&<Button
              disabled={table.getSelectedRowModel().rows.length==0}

      variant="text"
      //color="primary"
      startIcon={<PanToolAltOutlined />}
      onClick={()=>{setOpenAllocate(true);
        console.log(table.getSelectedRowModel().rows);
        let id=[]
        id=table.getSelectedRowModel().rows.map((row)=>{return row.original.id})
    
        setIdds(id);
        console.log(id);}
    }
      sx={{
        textTransform:'none'
      }}>
      Allocate
    </Button>}<Box flexGrow={1} /></Stack>
              ),
              
            // //memoMode:'cells',
            // renderDetailPanel: ({ row }) => (
            //     <RenderDetailPanel row={row} addRowC={addRowC} subRowC={subRowC} setLeadName={setLeadName} addLeads={addLeads} setCf={setCf} setImp={setImp} addContactField={addContactField} subRowL={subRowL} calcLd={calcLd} />
            //   ),
            // enableExpandAll:true,
            muiTablePaperProps: ({ table }) => ({
                //not sx
                style: {
                  zIndex: table.getState().isFullScreen ? 1250 : undefined,
                },
              }),
            //   muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke'}},
            //   muiDetailPanelProps: ({ row }) => ({
            //     sx: {
            //       backgroundColor:'#fcfcfa',paddingX:0
            //     },
            //   }),
              
        
          });
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      My Domains
    </Typography>
    <Typography
      variant="h6"
      color='gray'
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      PD
    </Typography>
    <Box sx={{flexGrow:1}}/>
   
     {(isAdmin()||canAddPD())&&<><Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true);}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} />
    <Button
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      onClick={()=>{setOpenDialog1(true);}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Edit Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} /></>}

            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Box //sx={{ opacity: openbd ? 0.5 : 1,pointerEvents:openbd ? "none" : "auto"}}
      >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to </Typography> <FormControlLabel sx={{p:0,m:0,ml:1}} control={<Switch checked={checked} onChange={(event)=>{setChecked(event.target.checked);}} size='small'/>} label={<Typography sx={{p:0,m:0}} variant="h6">{checked?'Processor':"Team"}</Typography>}  labelPlacement="start" /></Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Processor</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={processor}
            label="Select Processor"
            onChange={handleProcessorChange}
          >
            {user.users.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-label">Select Team</InputLabel>
          <Select disabled={checked}
            labelId="team-label"
            id="team-select"
            value={team}
            label="Select Team"
            onChange={handleTeamChange}
          >
           {user.teams.map((child)=>{
              return <MenuItem value={child.email}>Team {child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const allocationPromise = checked
              ? allocateDomainToProcessor(idds, processor)
              : allocateDomainToTeam(idds, team);

            allocationPromise
              .then(() => {
                setUpsuc1(true);
                fetchData();
                setProcessor([]);
                setTeam([]);
                setIdds([]);
              })
              .catch(() => {
                setUpfail1(true);
              })
              .finally(() => {
                handleCloseAllocate();
              });
          }}
        >
          Allocate
        </Button>
      </DialogActions>
    </Dialog>
      <MaterialReactTable
     table={domainTable}
    />
    </LocalizationProvider>
    </Box>
            </Stack>
            </ThemeProvider>
    )
}