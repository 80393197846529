import { Alert, Autocomplete, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Radio, RadioGroup, Snackbar, Stack, Switch, Tab, Tabs, TextField, ThemeProvider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useMemo, useState } from "react";
import { allocateToProcessor, allocateToTeam, getAllocatedByMeP, getAllocatedByMeT, getNonAlloc, getUserNTeams, reallocateToProcessorP, reallocateToProcessorT, reallocateToTeamP, reallocateToTeamT, uploadDomains } from "./api";
import { Add, DeleteOutline, FiberManualRecord, PanToolAltOutlined, ShortcutOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { isAdmin } from "./msalService";
import UploadCSVDialog from "./UploadCSVDialog";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser } from "./UserProvider";


export default function AllocatedByMe() 
{
    const [domainslp,setDomainslp]= useState([]);
    let [domainsop,setDomainsop]= useState([]);
    const [domainsap,setDomainsap]= useState([]);
    let [domainspp,setDomainspp]= useState([]);
    const [domainslt,setDomainslt]= useState([]);
    let [domainsot,setDomainsot]= useState([]);
    const [domainsat,setDomainsat]= useState([]);
    let [domainspt,setDomainspt]= useState([]);
    let [portfolio,setPortfolio]= useState("owned");
    let [domainst,setDomainst]= useState([]);
    let [ltd,setLtd]= useState(true);
    let [val,setVal]= useState("");
    const [tvalue, setTvalue] = useState('Processors');
    let [psize,setPsize]= useState(50);
    let[idds, setIdds]= React.useState([]);
    const[openDialog, setOpenDialog]=useState(false);
    const[openAllocate, setOpenAllocate]=useState(false);
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const[domainsp,setDomainsp]=useState([]);
    const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const [ids, setIds] = useState([]);
  const[b, setB]=useState(false);

  
  
  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };
    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleCloseAllocate = () => {
        setOpenAllocate(false);
      };
     
    const columnslp = [
        { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.deadline', headerName: 'Deadline', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.deadline))} 
},
        { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
    },
    ,
      {
        field: 'EST',
        headerName: 'EST',
        type: 'number',
        width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    { field: 'Processor', headerName: 'Processor', width: 110, valueGetter: (params) => {return params.row.user.firstName+" "+ params.row.user.lastName}},
    ]

     
    const columnsap = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
  },
  { field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} 
},

    {
      field: 'orderStatus',
      headerName: 'Order Status',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
  },
      { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  },
  ,
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
  },
  { field: 'Processor', headerName: 'Processor', width: 110, valueGetter: (params) => {return params.row.user.firstName+" "+ params.row.user.lastName}},
  ]

    const columnsop = [
      { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
    },
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
  },
  ,
    {
      field: 'EST',
      headerName: 'EST',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.EST}
    },
    {
      field: 'PP',
      headerName: 'PP',
      type: 'number',
      width: 70,valueGetter: (params) => {return params.row.domain.PP}
    },
    { field: 'user', headerName: 'Processor', width: 110, valueGetter: (params) => {return params.row.user.firstName+" "+ params.row.user.lastName}},
  ]

  const columnslt = [
    { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
},
{ field: 'domain.deadline', headerName: 'Deadline', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.deadline))} 
},
    { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},
,
  {
    field: 'EST',
    headerName: 'EST',
    type: 'number',
    width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{ field: 'user', headerName: 'Team', width: 110, valueGetter: (params) => {return "Team "+params.row.user.firstName}},
]

const columnsat = [
  { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
},
{ field: 'domain.orderDate', headerName: 'Order Date', width: 100,type:'date', valueGetter: (params) => {return (new Date(params.row.domain.orderDate))} 
}
,
{
  field: 'orderStatus',
  headerName: 'Order Status',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.orderStatus}
},
  { field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},
,
{
  field: 'EST',
  headerName: 'EST',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{ field: 'user', headerName: 'Team', width: 110, valueGetter: (params) => {return "Team "+params.row.user.firstName}},
]
const columnsot = [
  { field: 'dateOfAlloc', headerName: 'Date', width: 100,type:'date',valueGetter: (params) => {return (new Date(params.row.dateOfAlloc))} 
},
{ field: 'domain.domain', headerName: 'Domain', width: 210, valueGetter: (params) => {return params.row.domain.domain}
},
,
{
  field: 'EST',
  headerName: 'EST',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.EST}
},
{
  field: 'PP',
  headerName: 'PP',
  type: 'number',
  width: 70,valueGetter: (params) => {return params.row.domain.PP}
},
{ field: 'user', headerName: 'Team', width: 110, valueGetter: (params) => {return "Team "+params.row.user.firstName}},
]


let rowsTCurrent=useMemo(()=>{
  if(portfolio==='owned')
  return domainsot;
  else if(portfolio==='pd')
  return domainspt;
  else if(portfolio==='ald')
  return domainsat;
else if(portfolio==='ltd')
  return domainslt;
},[portfolio,domainsat,domainspt,domainslt,domainsot])
let rowsPCurrent=useMemo(()=>{
  if(portfolio==='owned')
  return domainsop;
  else if(portfolio==='pd')
  return domainspp;
  else if(portfolio==='ald')
  return domainsap;
else if(portfolio==='ltd')
  return domainslp;
},[portfolio,domainsop,domainspp,domainsap,domainslp])

let columnsTCurrent=useMemo(()=>{
  if(portfolio==='owned')
  return columnsot;
  else if(portfolio==='pd' || portfolio==='ald')
  return columnsat;
  
else if(portfolio==='ltd')
  return columnslt;
},[portfolio])

let columnsPCurrent=useMemo(()=>{
  if(portfolio==='owned')
  return columnsop;
  else if(portfolio==='pd' || portfolio==='ald')
  return columnsap;
  
else if(portfolio==='ltd')
  return columnslp;
},[portfolio])
  const { user, updateUser } = useUser();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event, newValue) => {
    setTvalue(newValue);
  };

    useEffect(()=>{getAllocatedByMeP().then((res)=>{console.log(res.data);
        const arr=res.data;
       setDomainsp(arr)
        let al=[],ao=[],ap=[],aa=[];
        for(let i=0;i<res.data.length;i++)
        {
             if(arr[i].domain.portfolio==='owned')
        ao.push(arr[i]);
        else if(arr[i].domain.portfolio==='pd')
        ap.push(arr[i]);
        else if(arr[i].domain.portfolio==='ald')
        aa.push(arr[i]);

        else 
        al.push(arr[i]);
        }
        setDomainslp(al);setDomainsop(ao);setDomainsap(aa);setDomainspp(ap);console.log(al);}).catch((err)=>{console.log(err)})
        getAllocatedByMeT().then((res)=>{console.log(res.data);
          const arr=res.data;
         setDomainst(arr)
          let al=[],ao=[],ap=[],aa=[];
          for(let i=0;i<res.data.length;i++)
          {if(arr[i].domain.portfolio==='owned')
            ao.push(arr[i]);
            else if(arr[i].domain.portfolio==='pd')
            ap.push(arr[i]);
            else if(arr[i].domain.portfolio==='ald')
            aa.push(arr[i]);
    
            else 
            al.push(arr[i]);
          }
          setDomainslt(al);setDomainsot(ao);setDomainsat(aa);setDomainspt(ap);console.log(al);}).catch((err)=>{console.log(err)})},[b])
        function CustomToolbar(props) {
          const ids=props.ids
          return (
            <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
              <Box flexGrow={1}/>
              {/* <FormControlLabel color="blue" sx={{p:0,m:0,ml:1}} control={<Switch checked={ltd} onChange={(event)=>{setLtd(event.target.checked);}} size='small' />} label={<Typography sx={{p:0,m:0}} color='primary' variant='button' textTransform='none'>{ltd?'Ltd':"Owned"}</Typography>}  labelPlacement="start" /> */}
              {/* <FormControl size='small'>
      <RadioGroup 
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={portfolio}
        onChange={(event)=>{setPortfolio(event.target.value)}}
      >
        <FormControlLabel  value="ltd" control={<Radio size='small'  sx={{
    '& .MuiSvgIcon-root': {
      fontSize: 20,
      minWidth:0,minHeight:0
    }
  }} />} label="Ltd" sx={{'& .MuiFormControlLabel-label': {
    fontSize:15,
    color:'text.primary'
  }}} />
        <FormControlLabel value="owned" control={<Radio size='small' />} label="Owned" />
        <FormControlLabel value="pd" control={<Radio size='small' />} label="Pd" />
        <FormControlLabel value="ald" control={<Radio  />} label="Ald" />
      </RadioGroup>
    </FormControl> */}
    <ToggleButtonGroup
    size='small'
  color="primary"
  value={portfolio}
  exclusive
  onChange={(event)=>{setPortfolio(event.target.value)}}
  aria-label="Platform"
  sx={{
    height: '15px', // Reduced height
    '& .MuiToggleButtonGroup-grouped': {
      border: 'none',
    },
  }}
>
  <ToggleButton size='small' value="ltd">Ltd</ToggleButton>
  <ToggleButton size='small' value="owned">Owned</ToggleButton>
  <ToggleButton size='small' value="pd">Pd</ToggleButton>
  <ToggleButton size='small' value="ald">Ald</ToggleButton>
</ToggleButtonGroup>
              <Button
              disabled={idds.length<=0}
      variant="text"
      //color="primary"
      startIcon={<PanToolAltOutlined />}
      onClick={()=>{setOpenAllocate(true)}}
      sx={{
        textTransform:'none'
      }}
    >
      Allocate
    </Button>
   {isAdmin()&& <>
    <Button
      variant="text"
      //color="primary"
      startIcon={<DeleteOutline />}
      onClick={()=>{}}
      disabled={idds.length<=0}
      sx={{
        textTransform:'none'
      }}
    >
      Delete
    </Button>
    <Button
      variant="text"
      //color="primary"
      startIcon={<ShortcutOutlined />}
      onClick={()=>{}}
      disabled={idds.length<=0}
      sx={{
        textTransform:'none'
      }}
    >
      Move
    </Button></>}
            </GridToolbarContainer>
          );
        }
    return (
    <ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Allocated By Me
    </Typography>
    <Box sx={{flexGrow:1}}/>
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      //options={domainso}
      sx={{ '& .MuiInputBase-root': {
        borderRadius: 100, // Use the value you want for the border-radius
      }, width: 300, height:40, borderRadius:100 }}
     
      size='small'
      //onChange={(event,value)=>{if(value==null) setVal(""); else if (typeof value === 'string')setVal(value); else setVal(value.domain);}}
      renderInput={(params) => <TextField  {...params}  />}
    />
   
   
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
    <TabContext value={tvalue} >
    <Box sx={{ width: '100%', }}>
        
      <TabList
        
        onChange={handleChange}
        aria-label="secondary tabs example"
        sx={{
            width: '360px',
            backgroundColor: '#ffffff',
            borderRadius: '0',
            '& .MuiTab-root': {
              '&.Mui-selected': {
                backgroundColor: '#fef7ff',
              },
              '&:hover': {
                backgroundColor: '#fef7ff',
              },
              '&.Mui-selected:hover': {
                backgroundColor: '#fef7ff',
              },
            },
            
          }}
      >
        <Tab sx={{width:'180px'}} value="Processors" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#FFC682'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>Processors</Typography></Stack>} />
        <Tab sx={{width:'180px'}}value="Teams" label={<Stack direction='row' alignItems='center' spacing={1.25}><FiberManualRecord  fontSize='small' sx={{fontSize:15,color:'#C6CFFF'}}/><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454',
                lineHeight: '1.25',}}>Teams</Typography></Stack>} />
      </TabList>
    </Box>
    <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to </Typography> <FormControlLabel sx={{p:0,m:0,ml:1}} control={<Switch checked={checked} onChange={(event)=>{setChecked(event.target.checked);}} size='small'/>} label={<Typography sx={{p:0,m:0}} variant="h6">{checked?'Processor':"Team"}</Typography>}  labelPlacement="start" /></Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length+" "+ tvalue} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Processor</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={processor}
            label="Select Processor"
            onChange={handleProcessorChange}
          >
            {user.users.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
            <MenuItem value={user.email}>Me</MenuItem>

            {/* ... other options */}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-label">Select Team</InputLabel>
          <Select disabled={checked}
            labelId="team-label"
            id="team-select"
            value={team}
            label="Select Team"
            onChange={handleTeamChange}
          >
           {user.teams.map((child)=>{
              return <MenuItem value={child.email}>Team {child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button variant="contained" onClick={()=>{if(checked)
        {
          if(tvalue==='Processors')
          reallocateToProcessorP(idds,processor).then(()=>{setUpsuc1(true); setB(!b);}).catch(()=>{setUpfail1(true)})
        else reallocateToProcessorT(idds,processor).then(()=>{setUpsuc1(true); setB(!b)}).catch(()=>{setUpfail1(true)})

        }
    else {
      if(tvalue==='Processors')
      reallocateToTeamP(idds,team).then(()=>{setUpsuc1(true); setB(!b)}).catch(()=>{setUpfail1(true)})
    else reallocateToTeamT(idds,team).then(()=>{setUpsuc1(true); setB(!b)}).catch(()=>{setUpfail1(true)})

    }
        handleCloseAllocate();
        }}>Allocate</Button>
      </DialogActions>
    </Dialog>
    <TabPanel value="Processors" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
        rows={rowsPCurrent}
        columns={columnsPCurrent}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    <TabPanel value="Teams" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
      <DataGrid autoHeight 
       rows={rowsTCurrent}
       columns={columnsTCurrent}
        pageSize={psize}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        //getRowId={(row)=>row.domain.domain}

        checkboxSelection
        onRowSelectionModelChange={(itm) => {console.log(itm); setIdds(itm); }}

      /></Box>
    </TabPanel>
    </TabContext>
    
        </Stack>
    </ThemeProvider>
    );
}