import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, TextField, Button,
  Snackbar, Alert, Tab, Link, Tooltip, IconButton } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {Upload, Sort } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { theme } from '../Theme';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { getRecoHubData, getEsRecoHubData, updateRecoBulk } from '../api';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import CopyrightIcon from '@mui/icons-material/Copyright';
import MultiSortDialog from '../MultiSortDialog';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EditLeadsDialog from './EditLeadsDialog';
import EditIcon from '@mui/icons-material/Edit';

function RecoHub() {
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [updatedRecoDomains, setUpdatedRecoDomains] = useState({});
  const [updatedRemarksDomains, setUpdatedRemarksDomains] = useState({});
  const [mode, setMode] = useState('ed');
  const [tabValue, setTabValue] = useState('ALL');
  const [multiSortDialogOpen, setMultiSortDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isEDManagerUser = isEDManager();

  const listTypes = mode === 'ed'
    ? ['ALL', 'T0', 'T1', 'T-BA', 'T-PD', 'T-PN', 'T-PC']
    : ['ALL', 'T0', 'T1', 'T-PD', 'T-PN', 'T-PC', 'T-RP'];

  const fetchRecoHubData = useCallback((currentMode, tab) => {
    setLoading(true);
    const apiCall = currentMode === 'ed' ? getRecoHubData : getEsRecoHubData;
    apiCall({ tab }).then(res => {
      if (Array.isArray(res.data)) {
        const transformedData = res.data.map(item => ({
          acquShortlistedDomain: {
            id: item.id,
            domain: item.domain,
            list: item.list,
            host: item.host,
            tld: item.tld,
            wby: item.wby,
            aby: item.aby,
            reg: item.reg,
            regx: item.regx,
            sg: item.sg,
            cpc: item.cpc,
            bids: item.bids,
            price: item.price,
            est: item.est,
            gdv: item.gdv,
            reco: item.reco,
            edate: item.edate,
            createdAt: item.createdAt,
            tld_count: item.tld_count,
            tld_count_dev: item.tld_count_dev,
            p: item.p,
            len: item.len,
            esw: item.esw,
            gdw: item.gdw,
            aprUnaprBy: item.aprUnaprBy,
            recoBy: item.recoBy,
            spr500: item.spr500,
            sprGdv: item.sprGdv,
            remarks: item.remarks
          },
          socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => ({
            id: lead.id,
            domain: lead.domain,
            copyright: lead.copyright,
            description: lead.description,
            irrelevant: lead.irrelevant ?? false,
            missedStatus: lead.missedStatus ?? 0,
            wrongSocialStatus: lead.wrongSocialStatus ?? 0,
            socials: lead.socials?.map(social => ({
              id: social.id,
              platform: social.platform.toLowerCase(),
              status: social.status,
              followers: social.followers,
              employees: social.employees,
              url: social.url,
              irrelevant: social.irrelevant ?? false,
              missedStatus: social.missedStatus ?? 0,
              wrongSocialStatus: social.wrongSocialStatus ?? 0
            })) || []
          })) || [],
          socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
          agents: {
            uploader: currentMode === 'ed' 
              ? (item.user ? {
                  name: `${item.user.firstName} ${item.user.lastName}`,
                  email: item.user.email,
                  role: 'Uploader'
                } : null)
              : (item.aqShortlistings?.length > 0 
                  ? item.aqShortlistings.map(shortlisting => ({
                      name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                      email: shortlisting.user.email,
                      role: 'Shortlister'
                    }))
                  : []),
            processor: item.socialDomain?.socialWorks?.[0]?.user ? {
              name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
              email: item.socialDomain.socialWorks[0].user.email,
              role: 'Processor'
            } : null,
            recommender: item.recoBy ? {
              name: typeof item.recoBy === 'string' 
                ? item.recoBy 
                : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
              email: item.recoBy.email,
              role: 'Recommender'
            } : null,
            approver: item.aprUnaprBy ? {
              name: item.aprUnaprBy,
              role: 'Approver'
            } : null
          }
        }));
        setData(transformedData);
      } else {
        console.error('API response is not an array:', res);
        setData([]);
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
      setData([]);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let initialMode = 'ed';
    if (isAdminUser||isEDManagerUser) {
      initialMode = 'ed';
    } else if (isESManagerUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
    fetchRecoHubData(initialMode, tabValue);
  }, [isAdminUser, isESManagerUser, isEDManagerUser]);


  const handleRecoChange = useCallback((id, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      setUpdatedRecoDomains(prev => ({
        ...prev,
        [id]: numValue
      }));
      setData(prevData => prevData.map(item =>
        item.acquShortlistedDomain.id === id
          ? { ...item, acquShortlistedDomain: { ...item.acquShortlistedDomain, reco: numValue } }
          : item
      ));
    }
  }, []);

  const handleRemarksChange = useCallback((id, value) => {
    setUpdatedRemarksDomains(prev => ({
      ...prev,
      [id]: value
    }));
    setData(prevData => prevData.map(item =>
      item.acquShortlistedDomain.id === id
        ? { ...item, acquShortlistedDomain: { ...item.acquShortlistedDomain, remarks: value } }
        : item
    ));
  }, []);

  const handleSubmit = useCallback((selectedRows, table) => {
    setLoading(true);
    const selectedDomains = selectedRows.map(row => ({
      id: row.original.acquShortlistedDomain.id,
      domain: row.original.acquShortlistedDomain.domain,
      reco: updatedRecoDomains[row.original.acquShortlistedDomain.id] ?? row.original.acquShortlistedDomain.reco,
      remarks: updatedRemarksDomains[row.original.acquShortlistedDomain.id] ?? row.original.acquShortlistedDomain.remarks,
      list: row.original.acquShortlistedDomain.list,
      sourceType: 'ed',
      status: 1,
    }));

    updateRecoBulk(selectedDomains).then(() => {
      fetchRecoHubData(mode, tabValue);
      setUpdatedRecoDomains({});
      setUpdatedRemarksDomains({});
      table.resetRowSelection();
      setSnackbar({ open: true, message: 'Domains updated and submitted successfully', severity: 'success' });
    }).catch(error => {
      console.error('Error updating domains:', error);
      setSnackbar({ open: true, message: 'Failed to update domains', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  }, [updatedRecoDomains, updatedRemarksDomains, fetchRecoHubData, mode, tabValue]);

  const handleOpenMultiSortDialog = () => {
    setMultiSortDialogOpen(true);
  };

  const handleCloseMultiSortDialog = () => {
    setMultiSortDialogOpen(false);
  };

  const handleApplyMultiSort = (sortCriteria) => {
    table.setSorting(sortCriteria);
  };

  const handleCopyDomains = useCallback((selectedRows) => {
    const domains = selectedRows.map(row => row.original.acquShortlistedDomain.domain).join('\n');
    navigator.clipboard.writeText(domains).then(() => {
      setSnackbar({ open: true, message: 'Domains copied to clipboard', severity: 'success' });
    }).catch(err => {
      setSnackbar({ open: true, message: 'Failed to copy domains', severity: 'error' });
    });
  }, []);

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Define which columns to export
      const exportColumns = [
        'domain', 'list', 'p', 'len', 'host', 'tld', 'wby', 'aby', 
        'reg', 'regx', 'sg', 'cpc', 'bids', 'price', 'est', 'gdv', 
        'esw', 'gdw', 'reco', 'remarks'
      ];

      // Create CSV header
      const header = exportColumns.join(',') + '\n';

      // Create CSV content from selected rows
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          const value = row.original.acquShortlistedDomain[col];
          // Handle special cases and formatting
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      // Combine header and content
      const csv = header + csvContent;

      // Create and trigger download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `domains_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbar({ 
        open: true, 
        message: 'Data exported successfully', 
        severity: 'success' 
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Failed to export data', 
        severity: 'error' 
      });
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setUpdatedRecoDomains({});
    setUpdatedRemarksDomains({});
    table.resetRowSelection();

    fetchRecoHubData(mode, newValue);
  };

  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'edate', 
      size: 40, 
      minSize: 40, 
      maxSize: 60,
      accessorFn: (row) => {
        const date = row.acquShortlistedDomain.edate;
        return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original.acquShortlistedDomain.edate;
        const dateB = rowB.original.acquShortlistedDomain.edate;
        //console.log(dateA,dateB);
        
        // Handle cases where dates are null/undefined
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Convert dates to timestamps for comparison
        const timeA = new Date(dateA).getTime();
        const timeB = new Date(dateB).getTime();
        
        return timeA - timeB;
      }
    },
    { 
      accessorKey: 'createdAt', 
      header: 'sdate', 
      size: 60, 
      minSize: 60, 
      maxSize: 60,
      accessorFn: (row) => {
        const date = row.acquShortlistedDomain.createdAt;
        return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original.acquShortlistedDomain.createdAt;
        const dateB = rowB.original.acquShortlistedDomain.createdAt;
       // console.log(dateA,dateB);

        // Handle cases where dates are null/undefined
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Convert dates to timestamps for comparison
        const timeA = new Date(dateA).getTime();
        const timeB = new Date(dateB).getTime();
        
        return timeA - timeB;
      }
    },
    { 
      accessorKey: 'list', 
      header: 'list', 
      size: 35, 
      minSize: 35, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.list;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'domain', 
      header: 'domain', 
      size: 120, 
      minSize: 120,
      maxSize: 150,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.domain;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric',
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} placement="bottom">
          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: '0.8rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block'
            }}
          >
            {cell.getValue()}
          </Typography>
        </Tooltip>
      )
    },
    { 
      accessorKey: 'p', 
      header: 'p', 
      size: 35, 
      minSize: 35, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.p;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'len', 
      header: 'len', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.len;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'host', 
      header: 'host', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.host;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'tld', 
      header: 'tld', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.tld;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'wby',
      header: 'wby',
      size: 50,
      minSize: 45,
      maxSize: 50,
      accessorFn: (row) => {
        const wby = row.acquShortlistedDomain.wby;
        if (wby && wby.length >= 4) {
          const year = parseInt(wby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
      sortingFn: 'basic'
    },
    {
      accessorKey: 'aby',
      header: 'aby',
      size: 30,
      minSize: 30,
      maxSize: 40,
      accessorFn: (row) => {
        const aby = row.acquShortlistedDomain.aby;
        if (aby && aby.length >= 4) {
          const year = parseInt(aby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'reg', 
      header: 'reg', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.reg;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'regx', 
      header: 'regx', 
      size: 50, 
      minSize: 50, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.regx;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { accessorKey: 'tld_count', header: 'tldc', size: 30, minSize: 30, maxSize: 40, accessorFn: (row) => row.acquShortlistedDomain.tld_count},
    { accessorKey: 'tld_count_dev', header: 'tldd', size: 45, minSize: 45, maxSize: 50, accessorFn: (row) => row.acquShortlistedDomain.tld_count_dev},
    { 
      accessorKey: 'sg', 
      header: 'sg', 
      size: 40, 
      minSize: 40, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.sg;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },

    { 
      accessorKey: 'cpc', 
      header: 'cpc', 
      size: 40, 
      minSize: 40, 
      maxSize: 50,
      accessorFn: (row) => {
        const cpc = row.acquShortlistedDomain.cpc;
        return cpc !== undefined && cpc !== null ? Math.round(cpc) : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'bids', 
      header: 'bids', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.bids;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'price', 
      header: 'price', 
      size: 50, 
      minSize: 50, 
      maxSize: 60,
      accessorFn: (row) => {
        const price = row.acquShortlistedDomain.price;
        return price !== undefined && price !== null ? Math.round(price) : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'est', 
      header: 'est', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.est;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdv', 
      header: 'gdv', 
      size: 50, 
      minSize: 50, 
      maxSize: 60,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdv;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'esw', 
      header: 'esw', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.esw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdw', 
      header: 'gdw', 
      size: 60, 
      minSize: 60, 
      maxSize: 80,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    {
      accessorKey: 'spr500',
      header: 'spr500',
      size: 60,
      minSize: 60,
      maxSize: 80,
      accessorFn: (row) => {
        const spr500 = row.acquShortlistedDomain.spr500;
        if (spr500 !== null && spr500 !== undefined) {
          return `${Math.round(spr500 * 100)}%`;
        }
        return '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.acquShortlistedDomain.spr500;
        const b = rowB.acquShortlistedDomain.spr500;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    {
      accessorKey: 'sprGdv',
      header: 'sprgdv',
      size: 80,
      minSize: 80,
      maxSize: 80,
      accessorFn: (row) => {
        const sprGdv = row.acquShortlistedDomain.sprGdv;
        if (sprGdv !== null && sprGdv !== undefined) {
          return `${Math.round(sprGdv * 100)}%`;
        }
        return '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.acquShortlistedDomain.sprGdv;
        const b = rowB.acquShortlistedDomain.sprGdv;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 50,
      minSize: 50,
      maxSize: 60,
      Cell: ({ row }) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            type="number"
            value={updatedRecoDomains[row.original.acquShortlistedDomain.id] ?? row.original.acquShortlistedDomain.reco ?? ''}
            onChange={(e) => handleRecoChange(row.original.acquShortlistedDomain.id, e.target.value)}
            inputProps={{ 
              min: 0, 
              style: { 
                padding: '1px 4px', 
                width: '30px', 
                fontStyle: 'italic' 
              }, 
              sx: { fontSize: 10, height: 18 } 
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
              "& input[type=number]": { MozAppearance: "textfield" }
            }}
          />
        </Stack>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.original.acquShortlistedDomain.reco;
        const b = rowB.original.acquShortlistedDomain.reco;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    {
      accessorKey: 'leads',
      header: 'leads',
      size: 100,
      minSize: 100,
      maxSize: 110,
      Cell: ({ row }) => {
        const filteredLeads = row.original.socialLeads.filter(lead => !lead.irrelevant);
        return (
          <Box>
            {filteredLeads.map((lead, index) => {
              let domain;
              if(lead.domain) {
                try {
                  domain = new URL(lead.domain).hostname;
                  domain = domain.replace('www.','');
                } catch (error) {
                  domain = lead.domain;
                }
              } else {
                domain = '-';
              }
              
              return (
                <Tooltip key={index} title={lead.domain || '-'} placement="bottom">
                  <Link
                    href={lead.domain.startsWith('http://') ||  lead.domain.startsWith('https://') ? lead.domain : `https://${lead.domain}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="caption"
                    display="block"
                    sx={{ 
                      color: 'black',
                      fontSize: '0.8rem',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {domain}
                  </Link>
                </Tooltip>
              );
            })}
          </Box>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.original.socialLeads.length;
        const b = rowB.original.socialLeads.length;
        if(a==1&&b==1)
          return rowA.original.socialLeads[0].domain.length - rowB.original.socialLeads[0].domain.length;
        else
          return a - b;
      }
    },
    {
      accessorKey: 'socials',
      header: 'socials',
      size: 150,
      minSize: 150,
      maxSize: 180,
      Cell: ({ row }) => {
        const filteredSocialLeads = row.original.socialLeads.filter(lead => !lead.irrelevant);
        const currentYear = new Date().getFullYear();

        const formatCount = (count) => {
          if (count === null || count === undefined) return '    ';
          
          // Get next power of 10
          const nextPower = count === 0 ? 0 : Math.pow(10, Math.ceil(Math.log10(count)));
          
          if (nextPower >= 1000000) return `${(nextPower / 1000000)}M`;
          if (nextPower >= 1000) return `${(nextPower / 1000)}K`;
          return nextPower.toString().padStart(4, ' ');
        };

        const renderSocialIcons = (lead) => {
          // Get all social platforms from lead
          // const availableSocials = lead?.socials || [];
          const availableSocials = lead?.socials?.filter(social => !social.irrelevant) || [];
          
          // Find Facebook and Instagram entries
          const fbSocial = availableSocials.find(s => s.platform.toLowerCase() === 'facebook');
          const igSocial = availableSocials.find(s => s.platform.toLowerCase() === 'instagram');
          
          // Choose the one with more followers
          let socialWithMoreFollowers;
          if (fbSocial && igSocial) {
            socialWithMoreFollowers = (fbSocial.followers || 0) >= (igSocial.followers || 0) ? fbSocial : igSocial;
          } else {
            socialWithMoreFollowers = fbSocial || igSocial;
          }
          
          // Create filtered socials array with LinkedIn, X, and the winning FB/IG
          const filteredSocials = [
            availableSocials.find(s => s.platform.toLowerCase() === 'linkedin') || { platform: 'linkedin' },
            availableSocials.find(s => s.platform.toLowerCase() === 'x') || { platform: 'x' },
            socialWithMoreFollowers || { platform: fbSocial ? 'facebook' : 'instagram' }
          ];

          const getUserId = (url) => {
            if (!url) return 'N/A';
            try {
              const urlObj = new URL(url);
              // Extract user ID based on platform
              switch (true) {
                case url.includes('linkedin.com'):
                  return urlObj.pathname.split('/')[2] || url;
                case url.includes('twitter.com'):
                  return '@' + (urlObj.pathname.split('/')[1] || url);
                case url.includes('facebook.com'):
                  return urlObj.pathname.split('/')[1] || url;
                case url.includes('instagram.com'):
                  return '@' + (urlObj.pathname.split('/')[1] || url);
                default:
                  return url;
              }
            } catch {
              return url || 'N/A';
            }
          };

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {filteredSocials.map((social) => {
                let Icon, count, platform = social.platform.toLowerCase();

                switch (platform) {
                  case 'linkedin':
                    Icon = LinkedInIcon;
                    count = social.employees;
                    break;
                  case 'x':
                    Icon = XIcon;
                    count = social.followers;
                    break;
                  case 'facebook':
                    Icon = FacebookIcon;
                    count = social.followers;
                    break;
                  case 'instagram':
                    Icon = InstagramIcon;
                    count = social.followers;
                    break;
                  default:
                    return null;
                }

                const isActive = social.status && social.status.toLowerCase() === 'active';
                const iconColor = isActive ? 'primary.main' : 'action.disabled';
                const tooltipContent = getUserId(social.url);

                return (
                  <Tooltip key={platform} title={tooltipContent}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      {social.url ? (
                        <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </Link>
                      ) : (
                        <>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          );
        };

        if (!filteredSocialLeads || filteredSocialLeads.length === 0) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Tooltip title="No copyright information available">
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <CopyrightIcon sx={{ 
                    fontSize: '0.9rem', 
                    color: '#FFB6C1', // Light pink color for missing copyright
                  }} />
                </Box>
              </Tooltip>
              {renderSocialIcons(null)}
            </Box>
          );
        }

        return (
          <Box>
            {filteredSocialLeads.map((lead, leadIndex) => (
              <Box key={leadIndex} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Tooltip 
                    title={
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block'}}>
                          Copyright Year: {lead?.copyright || 'Not Available'}
                        </Typography>
                        {lead?.description && (
                          <Typography variant="caption" sx={{ fontSize: '0.6rem', display: 'block', mt: 0.2 }}>
                            {lead.description}
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <CopyrightIcon sx={{ 
                        fontSize: '0.9rem', 
                        color: lead?.copyright 
                          ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                          : '#FFB6C1', // Light pink color for missing copyright
                      }} />
                    </Box>
                  </Tooltip>
                  {renderSocialIcons(lead)}
                </Box>
              </Box>
            ))}
          </Box>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const getActiveSocialCount = (filteredSocialLeads) => {
          return filteredSocialLeads.reduce((count, lead) => {
            return count + (lead.socials ? lead.socials.filter(social => social.status && social.status.toLowerCase() === 'active').length : 0);
          }, 0);
        };
        const a = getActiveSocialCount(rowA.original.socialLeads);
        const b = getActiveSocialCount(rowB.original.socialLeads);
        return a - b;
      }
    },
    {
      accessorKey: 'remarks',
      header: 'code',
      size: 50,
      minSize: 50,
      maxSize: 60,
      Cell: ({ row }) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            value={updatedRemarksDomains[row.original.acquShortlistedDomain.id] ?? row.original.acquShortlistedDomain.remarks ?? ''}
            onChange={(e) => handleRemarksChange(row.original.acquShortlistedDomain.id, e.target.value)}
            inputProps={{ 
              style: { 
                padding: '1px 4px', 
                width: '30px', 
                fontStyle: 'italic' 
              }, 
              sx: { fontSize: 10, height: 18 } 
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
              "& input[type=text]": { MozAppearance: "textfield" }
            }}
          />
        </Stack>
      ),
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'active',
      header: 'live',
      size: 30,
      minSize: 30,
      maxSize: 40,
      Cell: ({ row }) => {
        const isActive = row.original.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );

        return (
          <Typography variant="caption" noWrap>
            {isActive ? '1' : '0'}
          </Typography>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const isActiveA = rowA.original.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );
        const isActiveB = rowB.original.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );
        return isActiveA === isActiveB ? 0 : isActiveA ? -1 : 1;
      }
    },
    {
      accessorKey: 'agents',
      header: 'agent',
      size: 70,
      minSize: 70,
      maxSize: 100,
      Cell: ({ row }) => {
        const agents = Object.entries(row.original.agents).flatMap(([key, value]) => {
          if (key === 'uploader' && Array.isArray(value)) {
            return value;
          }
          return value ? [value] : [];
        }).filter(agent => agent !== null);

        return (
          <Stack 
            direction="row" 
            spacing={0.25}
            alignItems="center"
            sx={{ 
              flexWrap: 'wrap',
            }}
          >
            {agents?.map((agent, index) => {
              if (!agent) return null;

              // Handle cases where name might be an object or string
              let displayName = '';
              if (typeof agent.name === 'string') {
                displayName = agent.name;
              } else if (agent.name?.firstName && agent.name?.lastName) {
                displayName = `${agent.name.firstName} ${agent.name.lastName}`;
              }

              // Skip if we couldn't construct a valid display name
              if (!displayName) return null;

              // Get initials from display name
              const initials = displayName
                .split(' ')
                .filter(part => part.length > 0)
                .slice(0, 2)
                .map(part => part[0].toUpperCase())
                .join('');

              // Different background colors for different roles
              const getBackgroundColor = (role) => {
                switch (role) {
                  case 'Uploader':
                  case 'Shortlister': return '#e3f2fd';
                  case 'Processor': return '#f1f8e9';
                  case 'Recommender': return '#fff3e0';
                  case 'Approver': return '#fce4ec';
                  default: return '#f5f5f5';
                }
              };

              return (
                <Tooltip
                  key={`${index}-${displayName}`}
                  title={
                    <Box sx={{ p: 0.5 }}>
                      <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                        {displayName}
                      </Typography>
                      {agent.email && (
                        <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                          {agent.email}
                        </Typography>
                      )}
                      {agent.role && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            display: 'block', 
                            fontSize: '0.7rem',
                            fontStyle: 'italic',
                            mt: 0.5
                          }}
                        >
                          Role: {agent.role}
                        </Typography>
                      )}
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box
                    sx={{
                      backgroundColor: getBackgroundColor(agent.role),
                      border: '1px solid rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: 'medium',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        textAlign: 'center'
                      }}
                    >
                      {initials}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
      sortingFn: (rowA, rowB) => {
        const getAgentsString = (row) => {
          return Object.values(row.original.agents)
            .filter(agent => agent !== null)
            .map(agent => agent.name)
            .sort()
            .join(',');
        };
        
        return getAgentsString(rowA).localeCompare(getAgentsString(rowB));
      }
    },
    // {
    //   accessorKey: 'actions',
    //   header: 'Edit',
    //   size: 50,
    //   minSize: 50,
    //   maxSize: 60,
    //   Cell: ({ row }) => (
    //     <Tooltip title="Edit Leads & Socials">
    //       <IconButton
    //         size="small"
    //         onClick={() => {
    //           setSelectedRow(row.original);
    //           setEditDialogOpen(true);
    //         }}
    //       >
    //         <EditIcon fontSize="small" />
    //       </IconButton>
    //     </Tooltip>
    //   ),
    // },
  ], [updatedRecoDomains, updatedRemarksDomains, handleRecoChange, handleRemarksChange]);


  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableSorting: true,
    enableMultiSort: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableStickyHeader: true,
    initialState: {
      density: 'compact',
      pagination: { pageSize: 100, pageIndex: 0 },
      sorting: [] ,
      columnVisibility: {
        createdAt: false,
        spr500: false,
        len: false,
        host: false,
        tld: false,
        aby: false,
        
      },
    },
    state: {
      isLoading: loading,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const buttonSx = {
        textTransform: 'none',
        backgroundColor: '#8797A8',
        color: '#FFFFFF !important',
        padding: '4px 8px',
        minWidth: 'auto',
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
        '&:hover': {
          backgroundColor: '#8797A8',
          opacity: 0.9,
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        },
        '&.Mui-disabled': {
          backgroundColor: '#8797A8',
          opacity: 0.7,
        }
      };

      return (
        <Stack direction="row" spacing={1} width="100%" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={0.5}>
            <Button
              size="small"
              startIcon={<Sort />}
              onClick={handleOpenMultiSortDialog}
              sx={buttonSx}
            >
              Sort
            </Button>
            <Button
              size="small"
              startIcon={<ContentCopyOutlinedIcon />}
              onClick={() => handleCopyDomains(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Copy
            </Button>
            <Button
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => handleExportData(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Export
            </Button>
            <Button
              size="small"
              startIcon={<Upload />}
              onClick={() => handleSubmit(selectedRows, table)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      );
    },

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#f78de2',//'#e6e6fa',
        color: 'white',//'#000',
        fontWeight: 'bold',
        fontSize: '0.7rem', 
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic', 
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic', 
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Recommendation Hub
          </Typography>
          <Box flexGrow={1}></Box>
          {(isAdminUser||(isEDManagerUser&&isESManagerUser)) && (
            <Button
              variant="outlined"
              onClick={() => {
                const newMode = mode === 'ed' ? 'es' : 'ed';
                setMode(newMode);
                fetchRecoHubData(newMode, tabValue);
              }}
              sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
            >
              Switch To {mode === 'ed' ? 'ES' : 'ED'}
            </Button>
          )}
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab
                  key={listType}
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <MaterialReactTable table={table} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <MultiSortDialog
          open={multiSortDialogOpen}
          onClose={handleCloseMultiSortDialog}
          columns={columns}
          initialSorting={table.getState().sorting}
          onApplySort={handleApplyMultiSort}
        />
        <EditLeadsDialog
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedRow(null);
          }}
          data={{
            ...selectedRow,
            socialWorkId: selectedRow?.socialWorkId
          }}
          onSuccess={() => {
            fetchRecoHubData(mode, tabValue);
          }}
        />
      </Stack>
    </ThemeProvider>
  );
}

export default RecoHub;
