import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({firstName:"User",lastName:"",email:"",users:[],teams:[]});
  const [olevel, setOlevel]=useState(false);

  // Function to update the user data
  const updateUser = (userData) => {
    setUser(userData);
    setOlevel(user.users.length>0?false:true);
  };

 
  

  return (
    <UserContext.Provider value={{ user, updateUser,olevel }}>
      {children}
    </UserContext.Provider>
  );
};
