import { Alert, Box, Button, IconButton, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography, MenuItem, Select} from "@mui/material";
import { theme } from "./Theme";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getSocialAllocationsWork, setSocialAllocationsWork } from "./api";
import { Add, AddCircle, Facebook, Instagram, LinkedIn, Remove, RemoveCircle, Twitter, Upload, X } from "@mui/icons-material";


let createLeadRow=()=>{
    return {
        domain:"",
        description:"",
        copyright:"",
        priorityOrder: "", // Add this new field
        socials:[{platform:"Linkedin",url:"",followers:"",employees:"",status:""}]
    }
}

const convertToInteger = (inputText) => {
  // Trim the input to remove extra spaces
  const trimmedInput = inputText.trim();
if(trimmedInput.length==0)
return 0;
  // Check if the input ends with 'K' or 'k', indicating thousands
  if (trimmedInput.toLowerCase().endsWith('k')) {
    // Remove the 'K' and convert the remaining part to a number
    const numberPart = parseFloat(trimmedInput.slice(0, -1));

    // Multiply by 1000 to get the actual number
    return Math.round(numberPart * 1000);
  } else {
    // Directly convert the input to a number, also removing commas if any
    return parseInt(trimmedInput.replace(/,/g, ''), 10);
  }
};


let createCSocialsRow=()=>{
    return {platform:"Linkedin",url:"",followers:"",employees:"",status:""}
}

let createSocialsRowIG=()=>{
    return {platform:"Instagram",url:"",followers:"",employees:"",status:""}
}

let createSocialsRowFB=()=>{
    return {platform:"Facebook",url:"",followers:"",employees:"",status:""}
}
let createSocialsRowX=()=>{
    return {platform:"X",url:"",followers:"",employees:"",status:""}
}

export default function SocialWorkStation() 
{
    
    const [data,setData]=useState([]);
    const [presentAllocs,setPresentAllocs]=useState([]);
    const [date,setDate]=useState(0);
    let num=useRef(0);
    const [den,setDen]=useState(0);
    const [openS, setOpenS ] = useState(false);
    const [openF, setOpenF ] = useState(false);
    const [b,setB]=useState(false);
    const columns = React.useMemo(
        () => [

          {
            id: 'domain',
            header: 'Domain',
            accessorFn: (row) => {return row.presentDomain.domain;},

          },
          {
            id: 'deadline',
            accessorFn: (row) => {return (new Date(row.presentDomain.deadline)).toISOString().split('T')[0]},
            header: 'Deadline',
            size:90,maxSize:90

          },
          {
            id: 'edate',
            accessorFn: (row) => {return row.presentDomain.edate?(new Date(row.presentDomain.edate)).toISOString().split('T')[0]:"N/A"},
            header: 'E-Date',
            size:90,maxSize:90

          },

          
          {
            id: 'add',
            Cell: ({renderedCellValue,row})=>{return(<Box width='100%' sx={{}} ><AddLeadButton addLead={addLead} i1={row.index} /></Box>) },
            header: 'Add',
            size:16,maxSize:16

          },
          {
            id: 'leads',
            accessorFn: (row) => {return row.socialWork.topLeads;},
            Cell: ({renderedCellValue,row})=>{return(<LeadSection row={row} addRowC={addRowC} subRowC={subRowC} setLeadName={setLeadName} addLead={addLead} setCp={setCp} setDesc={setDesc} addSocialField={addSocialField} subRowL={subRowL} setPriority={setPriority} />) },
            header: 'Leads',
          }
          
        ],
        []
      );

      const domainTable=  useMaterialReactTable({
        columns:columns,
        data:presentAllocs,
        //enableEditing:true,
        //editDisplayMode:'cell',
        layoutMode:'semantic',
        initialState: { density: 'compact' },
        enableRowSelection:true,
        renderTopToolbarCustomActions: ({ table }) => (
          <Stack direction='row' width='100%' alignItems='center'>
            {table.getSelectedRowModel().rows.length>0&&<Typography marginLeft={1} variant='body2'  fontWeight='bold' >{table.getSelectedRowModel().rows.length} of {presentAllocs.length} domains selected.</Typography>}            <Box flexGrow={1} />
            <Button
            variant="text"
            startIcon={<Upload />}
            disabled={table.getSelectedRowModel().rows.length==0}
            sx={{textTransform:'none'}}
            onClick={() => {
              let arr=table.getSelectedRowModel().rows.map((ar)=>ar.original)
              console.log(arr);
              setDen(arr.length);
              setSocialAllocationsWork(arr).then((res)=>{num.current=res.data; if(res.data>0){setOpenS(true);setB(!b); table.resetRowSelection() } else setOpenF(true)}).catch((err)=>{console.log(err);setOpenF(true) })
            }}
          >
            Submit
          </Button></Stack>
        ),
      
        muiPaginationProps: {
          rowsPerPageOptions: [10,50,100,250],
          
        },
        paginationDisplayMode:'pages',
        positionToolbarAlertBanner:'none',
          
        //memoMode:'cells',
       
        enableExpandAll:true,
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
              zIndex: table.getState().isFullScreen ? 1250 : undefined,
             
            },
            
          }),
          muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke',
         
        }}
      });
      useEffect(()=>{console.log(presentAllocs); console.log("Selected Model",domainTable.getSelectedRowModel().rows);},[presentAllocs])



    useEffect(()=>{
        getSocialAllocationsWork().then((res)=>{
          let arr=res.data;
          for(let i=0;i<arr.length;i++)
          {
            arr[i].socialWork.topLeads.forEach(el=>{
              if((!el.socials)||(el.socials.length==0))
              {
                el.socials=[createCSocialsRow()]
              }
            })
          }
            setPresentAllocs(arr);
        }).catch((err)=>{console.log(err)})
},[b])


const addRowC=useCallback((i1,i2)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].socials=[...updatedAllocs[i1].socialWork.topLeads[i2].socials,createCSocialsRow()]

    // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,0,createContactRow());
    // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
    // Update the site value at the specified index
    return updatedAllocs;
  });},[])

  const subRowC=useCallback((i1,i2,i3)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].socials=[...updatedAllocs[i1].socialWork.topLeads[i2].socials.slice(0,i3),...updatedAllocs[i1].socialWork.topLeads[i2].socials.slice(i3+1)]
    // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,1); // Update the site value at the specified index
    // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
   

    return updatedAllocs;
  });},[])

  const subRowL=useCallback((i1,i2)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads=[...updatedAllocs[i1].socialWork.topLeads.slice(0,i2),...updatedAllocs[i1].socialWork.topLeads.slice(i2+1)]
    // updatedAllocs[i1].todayDW.leads[i2].contacts.splice(i3,1); // Update the site value at the specified index
    // updatedAllocs[i1].todayDW.leads[i2].contacts=Array.from(updatedAllocs[i1].todayDW.leads[i2].contacts)
   

    return updatedAllocs;
  });},[])

  const setCp=useCallback((i1,i2,c)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].copyright = c; // Update the site value at the specified index
   

    return updatedAllocs;
  });},[])

  const setDesc=useCallback((i1,i2,c)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].description = c; // Update the site value at the specified index
   

    return updatedAllocs;
  });},[])

const addSocialField=useCallback((i1,i2,i3,c,val)=>{setPresentAllocs((prevAllocs) => {
  console.log("Add Social Field",val,c)
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].socials[i3][c]=val; // Update the site value at the specified index

    return updatedAllocs;
  });},[])

  const setLeadName=useCallback((i1,i2,inputValue)=>{setPresentAllocs((prevAllocs) => {
    const updatedAllocs = [...prevAllocs];
    updatedAllocs[i1].socialWork.topLeads[i2].domain = inputValue; // Update the site value at the specified index
    
   
    return updatedAllocs;
  });},[])

  const addLeads=useCallback((i1,l)=>{
    setPresentAllocs((prevAllocs) => {
    //console.log(prevAllocs)
      
      let updatedAllocs = [...prevAllocs];
      
      updatedAllocs[i1]={...updatedAllocs[i1],socialWork:{...updatedAllocs[i1].socialWork,topLeads:[...updatedAllocs[i1].socialWork.topLeads,...l]}}; // Update the site value at the specified index
     return updatedAllocs;
    });},[])

    const addLead=useCallback((i1)=>{
      setPresentAllocs((prevAllocs) => {
      //console.log(prevAllocs)
        
        let updatedAllocs = [...prevAllocs];
        
        updatedAllocs[i1]={...updatedAllocs[i1],socialWork:{...updatedAllocs[i1].socialWork,topLeads:[...updatedAllocs[i1].socialWork.topLeads,createLeadRow()]}}; // Update the site value at the specified index
       return updatedAllocs;
      });},[])

    const setPriority = useCallback((i1, i2, value) => {
      setPresentAllocs((prevAllocs) => {
        const updatedAllocs = [...prevAllocs];
        updatedAllocs[i1].socialWork.topLeads[i2].priorityOrder = value;
        return updatedAllocs;
      });
    }, []);

    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5} width='100%'>
            <Stack direction='row' width='100%' justifyContent='space-between'>
                <Typography 
                variant="h6"
                sx={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  position: 'relative',
                }}>
                    Leads Hub
                </Typography>
                {/* <Button variant="text"
      color="primary"
      startIcon={<Upload />}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}>
                    Request
                </Button> */}
            </Stack>
            <Snackbar open={openS} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setOpenS(false);}}>
<Alert  severity='success' sx={{ width: '100%' }}>
 {num.current} out of {den} submitted!
</Alert>
</Snackbar>
<Snackbar open={openF} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setOpenF(false);}}>
<Alert  severity="error" sx={{ width: '100%' }}>
 Not Submitted!
</Alert>
</Snackbar>
            <Box  
            >
      <MaterialReactTable
     table={domainTable}
    />
    </Box>
            </Stack>
            </ThemeProvider>
    )
}

//-------------------------------------------------------------------------------------------------------------------------------------

const AddLeadButton = memo(({ addLead,i1 }) => {
  return (
    <IconButton size="small" color="primary" onClick={()=>addLead(i1)}>
      <AddCircle fontSize="small" />
    </IconButton>
  );
});

//=============================================================================================================================
const CopyRighttb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const inputRef = useRef(null);
   useEffect(()=>{setInputValue(props.value)},[props.value])
    const handleInputChange = (event) => {
        const val=event.target.value
        if (/^\d*$/.test(val)) {
          setInputValue(val);
      }
        //inputRef.current.focus();
        };
//console.log("t1")
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                // if(props.value.trim().length==0)
                // {
                //     if(inputValue.trim().length>0)
                //     props.calcL(true,1)
                // }
                // else
                // {
                //     if(inputValue.trim().length==0)
                //     props.calcL(false,1)
                // }
                props.setCp(props.i1,props.in,inputValue)
                
            }
          };
    return(<TextField
    //label="Leads"
    placeholder="Copyright"
    value={inputValue?inputValue:""}
    size='small'
    //inputRef={ref.current === row.index ? ref : null}
   // onClick={() => handleFocus(row.index)}
    //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
    onChange={handleInputChange}
    onBlurCapture={()=>{ 
        // if(props.value.trim().length==0)
        // {
        //     if(inputValue.trim().length>0)
        //     props.calcL(true,1)
        // }
        // else
        // {
        //     if(inputValue.trim().length==0)
        //     props.calcL(false,1)
        // }
        props.setCp(props.i1,props.in,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{sx:{fontSize:14,height:30}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})
//=============================================================================================================================
const Desctb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const inputRef = useRef(null);
   useEffect(()=>{setInputValue(props.value)},[props.value])
    const handleInputChange = (event) => {
        const val=event.target.value
        setInputValue(val);
        //inputRef.current.focus();
        };
//console.log("t1")
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                // if(props.value.trim().length==0)
                // {
                //     if(inputValue.trim().length>0)
                //     props.calcL(true,1)
                // }
                // else
                // {
                //     if(inputValue.trim().length==0)
                //     props.calcL(false,1)
                // }
                props.setDesc(props.i1,props.in,inputValue)
                
            }
          };
    return(<TextField
    //label="Leads"
    placeholder="Description"
    value={inputValue?inputValue:""}
    size='small'
    //inputRef={ref.current === row.index ? ref : null}
   // onClick={() => handleFocus(row.index)}
    //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
    onChange={handleInputChange}
    onBlurCapture={()=>{ 
       
        props.setDesc(props.i1,props.in,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{sx:{fontSize:14,height:30}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})
//=============================================================================================================================
const Leadtb=memo((props)=>{
    const [inputValue, setInputValue] = useState(props.value);
   // const inputRef = useRef(null);
   useEffect(()=>{
    console.log("Leadtb ",props.i1,props.value)
    setInputValue(props.value)},[props.value])
    const handleInputChange = (event) => {
        const val=event.target.value
        setInputValue(val);
        //inputRef.current.focus();
        };
//console.log("t1")
        const handleInputKeyPress = (event) => {
            if (event.key === 'Enter') {
                // if(props.value.trim().length==0)
                // {
                //     if(inputValue.trim().length>0)
                //     props.calcL(true,1)
                // }
                // else
                // {
                //     if(inputValue.trim().length==0)
                //     props.calcL(false,1)
                // }
                props.setLeadName(props.i1,props.in,inputValue)
                
            }
          };
    return(<TextField
    //label="Leads"
    placeholder="lead.com"
    value={inputValue?inputValue:""}
    size='small'
    //inputRef={ref.current === row.index ? ref : null}
   // onClick={() => handleFocus(row.index)}
    //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
    onChange={handleInputChange}
    onBlurCapture={()=>{ 
        props.setLeadName(props.i1,props.in,inputValue);}}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{sx:{fontSize:14,height:30}}}
    sx={{'& legend': { display: 'none' },
    '& fieldset': { top: 0 },padding:0,margin:0}}
  />)})
//-------------------------------------------------------------------------------------------------------
const RowActionsL=memo(({i1,i2,l,subRowL})=>{
  return ( <Box sx={{ display: 'flex', gap: '1rem' }}>
  {(!(i2==0&&l==1))&&
  <Tooltip title="Remove">
    <IconButton size="small" sx={{padding:0}} color="primary" onClick={() => {subRowL(i1,i2); const a="ab";
  
  }}>
      <RemoveCircle fontSize="small" sx={{padding:0}} />
    </IconButton>
  </Tooltip>}
</Box>);
}  )
//------------------------------------------------------------------------------------------------------------------------
const LeadSection = 
memo(({ row, subRowC,addRowC,setLeadName,setCp,setDesc,addSocialField,subRowL,addLeads, setPriority }) => {
 const leadsData =useMemo(()=>{console.log("Leads Data",row.original.socialWork.topLeads); return row.original.socialWork.topLeads},[row.original.socialWork.topLeads]);
  //const leadsData=getLeadsData(row.original.todayDW)
  const [r1,setR1]=useState(row);
  const [xrow,setXrow]=useState(true);
  //useEffect(()=>{console.log("r2")},[])
  //console.log(leadsData)
//   const calcL=useCallback(async (b,c)=>{if(b) {setL(l+c);}
// else {setL(l-c);}
// calcLd(b,c)
// },[l])


  const leadsColumns =  useMemo(()=>[
    {
      id: 'domain',
      accessorKey: 'domain',
      header: 'Leads',
      Cell: ({renderedCellValue,row})=>{return(<Leadtb value={row.original.domain} in={row.index} i1={r1.index} setLeadName={setLeadName}  />) },
      size:220,maxSize:220
      //enableEditing:false
    },
    {
      accessorKey: 'copyright',
      header: 'CR',
      id: 'copyright',
      size:140,maxSize:140,
      //accessorFn: (row) => {return  row.imp},
      Cell: ({renderedCellValue,row})=>{return(<CopyRighttb value={row.original.copyright} in={row.index} i1={r1.index} setCp={setCp} />) },
     // Edit: ({ cell, renderedCellValue }) => {return <>{renderedCellValue}</>},
    }
    , {
        //accessorKey: 'contactForm',
        header: 'Socials',
        id: 'socials',
        Cell: ({renderedCellValue,row})=>{ return(<SocialsSection row={row} socialsData={row.original.socials} r1={r1} subRowC={subRowC} addRowC={addRowC}  addSocialField={addSocialField}/>) },
        size:520

        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      },
      // {
      //   accessorKey: 'priorityOrder',
      //   header: 'Code',
      //   id: 'priorityOrder',
      //   size: 70,
      //   maxSize: 70,
      //   Cell: ({renderedCellValue, row}) => (
      //     <TextField
      //       placeholder="Code"
      //       value={row.original.priorityOrder || ""}
      //       size="small"
      //       onChange={(e) => {
      //         const value = e.target.value.toUpperCase();
      //         if (value === '' || /^[A-Z]$/.test(value)) {
      //           setPriority(r1.index, row.index, value);
      //         }
      //       }}
      //       InputProps={{
      //         sx: {
      //           fontSize: 12,
      //           height: 26,
      //           '&::placeholder': {
      //             opacity: 1,
      //           },
      //         },
      //         inputProps: { 
      //           maxLength: 1,
      //           style: { 
      //             textTransform: 'uppercase',
      //             padding: '2px 8px',
      //           }
      //         }
      //       }}
      //       sx={{
      //         '& legend': { display: 'none' },
      //         '& fieldset': { top: 0 },
      //         padding: 0,
      //         margin: 0,
      //         width: '100%',
      //         '& .MuiOutlinedInput-root': {
      //           '& fieldset': {
      //             borderColor: 'rgba(0, 0, 0, 0.23)',
      //           },
      //           '&:hover fieldset': {
      //             borderColor: 'rgba(0, 0, 0, 0.23)',
      //           },
      //           '&.Mui-focused fieldset': {
      //             borderColor: 'rgba(0, 0, 0, 0.23)',
      //           },
      //         },
      //       }}
      //     />
      //   ),
      // },
       {
        //accessorKey: 'contactForm',
        header: 'Description',
        id: 'description',
        Cell: ({renderedCellValue,row})=>{return(<Desctb value={row.original.description} in={row.index} i1={r1.index} setDesc={setDesc} />) },
        size:220,maxSize:220,
  
        //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
      }

    ],[row.original.socialWork.topLeads])
    const [pl, setPl] = useState('');

    const[upfail, setUpfail]=useState(false);
    const[isLoading, setIsLoading]=useState(false);




    
     // useEffect(()=>{getMissedLeads(row.original.id).then((res)=>{setTextInputs(res.data); setSaveml(false)}).catch((err)=>{console.log(err)})},[])
    const leadTable=  useMaterialReactTable({
        columns:leadsColumns,
        data:leadsData,
        //renderDetailPanel:({ row }) => (<RenderDetailPanelLeads row={row} /> ),
         // muiDetailPanelProps: ({ row }) => ({ sx: {paddingY:0},}),
        enableExpandAll:true,
        enableBottomToolbar:false,
        enableTopToolbar:false,
        enableTableHead:false,
        layoutMode:'grid-no-grow',
        enableRowActions:true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <RowActionsL i1={r1.index} i2={row.index} l={leadsData.length} subRowL={subRowL}  site={row.original.site}  />
           
          ),
        initialState:{//expanded:true,
            density:'compact',pagination: { pageSize: 200,pageIndex:0 }},

            state:{isLoading: isLoading},
            //muiTableBodyRowProps:{ sx:{flex:true,flexDirection:'row', alignItems:'end',justifyContent:'space-between'}},
            muiTableBodyProps:{sx:{backgroundColor:'whitesmoke',padding:0}},muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke',padding:0}},
            muiTablePaperProps:{elevation:0},
            muiDetailPanelProps:{sx:{ paddingY:0,backgroundColor:'white',paddingLeft:8}},
        
            
      });
  return <Stack direction='column' spacing={1}> 
           <MaterialReactTable  table={leadTable}/>
{/* {<Stack direction='row' paddingLeft={1}><TextField
        //label="Leads"
        placeholder="Paste or Enter Leads"
        value={pl}
        size='small'
        multiline
        //inputRef={inputRef}
        //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
       onChange={(event) => {
        setPl(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault(); // Prevent default behavior of adding a new line
          let pll=pl.trim();
          console.log("t1")
          if (pll!== '') {
           // setIsLoading(true);
            const arr=pll.split('\n')
            const l=arr.map((ar)=>{ return {
                domain:ar.trim().toLowerCase(),
                copyright:"",
                description:"",
                socials:[{platform:"",followers:0,employees:0,status:""}]
            }})
           //console.log(l)
            addLeads(row.index,l)
           // console.log(new Date().getMilliseconds())
            //setIsLoading(false)
            setPl(''); // Reset input value
          }
        }
      }}
       /* onBlur={()=>{setPresentAllocs((prevAllocs) => {
            const updatedAllocs = [...prevAllocs];
            updatedAllocs[row.index].todayDW.leads[props.in].site = inputValue; // Update the site value at the specified index
            return updatedAllocs;
          });}}
        onKeyPress={handleInputKeyPress}/
        variant='outlined'
        margin="normal"
        autoCorrect={false}
        InputProps={{sx:{fontSize:14}}}
        sx={{'& legend': { display: 'none' },
        '& fieldset': { top: 0 },padding:0,margin:0,width:190}}
      /></Stack>} */}
      
      </Stack>;
})

//------------------------------------------------------------------------------------------------------------
const conttbvariant='outlined'

const Socialstb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    props.addSocialField(props.i1, props.i2, props.i3, props.c, newValue);
  };

  return (
    <Select
    value={inputValue}      
    onChange={handleInputChange}
      size="small"
      variant={conttbvariant}
      displayEmpty
      sx={{
        fontSize: 13,
        height: 25,
        width: '100%', // Ensure full width
        '& .MuiSelect-select': {
          padding: '2px 10px 2px 8px', // Adjust padding
          height: '21px', // Fixed height
          lineHeight: '21px', // Align text vertically
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Consistent border color
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200, // Limit dropdown height
          },
        },
      }}
    >
      <MenuItem value="" disabled style={{ fontSize: 13, height: 25 }}>
        <em>Status</em>
      </MenuItem>
      <MenuItem value="active" style={{ fontSize: 13, height: 25 }}>Active</MenuItem>
      <MenuItem value="inactive" style={{ fontSize: 13, height: 25 }}>Inactive</MenuItem>
      <MenuItem value="zero" style={{ fontSize: 13, height: 25 }}>Zero</MenuItem>
    </Select>
  );
});

const Followerstb=memo((props)=>{
  const [inputValue, setInputValue] = useState(props.value);
 // const [pe, setPe] = useState(props.pe);
 useEffect(()=>{setInputValue(props.value);},[props.value])
 const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
      const handleInputKeyPress = (event) => {
          if (event.key === 'Enter') {
            let i=convertToInteger(inputValue);
              props.addSocialField(props.i1,props.i2,props.i3,props.c,i);
          }
        };
  return(<TextField
  placeholder={props.c}
  value={inputValue}
    size='small'
  type='number'
  onChange={handleInputChange}
  onBlurCapture={()=>{            let i=convertToInteger(inputValue);
    props.addSocialField(props.i1,props.i2,props.i3,props.c,i);}}
  onKeyPress={handleInputKeyPress}
  variant={conttbvariant}
  //type={props.c=='status'?'text':'number'}
  margin="normal"
  autoCorrect='false'
  //label={props.c}
  
  
  InputProps={{sx:{fontSize:13,height:25}}}
  sx={{'& legend': { display: 'none' },
  '& fieldset': { top: 0 },padding:0,margin:0,
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
  "& input[type=number]": { MozAppearance: "textfield" }
  }}
/>)})

const Employeetb=memo((props)=>{
  const [inputValue, setInputValue] = useState(props.value);
 // const [pe, setPe] = useState(props.pe);
 useEffect(()=>{setInputValue(props.value);},[props.value])
 const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
      const handleInputKeyPress = (event) => {
          if (event.key === 'Enter') {
            let i=convertToInteger(inputValue);
              props.addSocialField(props.i1,props.i2,props.i3,props.c,i);
          }
        };
  return(<TextField
  placeholder={props.c}
  value={inputValue}
    size='small'
  type='number'
  onChange={handleInputChange}
  onBlurCapture={()=>{            let i=convertToInteger(inputValue);
    props.addSocialField(props.i1,props.i2,props.i3,props.c,i);}}
  onKeyPress={handleInputKeyPress}
  variant={conttbvariant}
  //type={props.c=='status'?'text':'number'}
  margin="normal"
  autoCorrect='false'
  InputProps={{sx:{fontSize:13,height:25}}}
  sx={{'& legend': { display: 'none' },
  '& fieldset': { top: 0 },padding:0,margin:0,
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
  "& input[type=number]": { MozAppearance: "textfield" }
  }}
/>)})

//------------------------------------------------------------------------------------------------------------------------------



const SocialIconButton = memo(({plat,addSocialField,i1,i2,i3,c}) => {
  const platforms = ["Linkedin", "Instagram", "Facebook", "X"];
  const icons = {
    Linkedin: <LinkedIn sx={{color:'#0077b5'}} />,
    Instagram: <Instagram sx={{color:'#d62976'}} />,
    Facebook: <Facebook sx={{color:'#316FF6'}} />,
    X: <X sx={{color:'black'}}/>
  };
  const [platform, setPlatform] = useState(plat);
  useEffect(()=>{setPlatform(plat)},[plat])

  const handleIconClick = () => {
    const currentIndex = platforms.indexOf(platform);
    const nextIndex = (currentIndex + 1) % platforms.length;
    setPlatform(platforms[nextIndex]);
    console.log("On Click",platforms[nextIndex])
    addSocialField(i1,i2,i3,c,platforms[nextIndex])
  };

  return (
    <IconButton size="small"   onClick={handleIconClick}>
      {icons[platform]}
    </IconButton>
  );
});


//-------------------------------------------------------------------------------------------------------------------------------------------
const SocialsSection = 
memo(({ row,socialsData,r1,subRowC,addRowC,addSocialField }) => {
    const [r2,setR2]=useState(row);
    useEffect(()=>{setR2(row)},[row])
    const [sData,setSdata]=useState(socialsData);
    useEffect(()=>{setSdata(socialsData)},[socialsData])
   // const [cd,setCd]=useState(contactsData);
//const cd=Array.from(contactsData);
    //console.log(contactsData);
  //  useEffect(()=>{console.log('abe')},[con])
  //const contactsData = getContactsData(row.original);
 
  const socialsColumns = useMemo(() => [
    {
      id: 'platform',
      accessorKey: 'platform',
      size: 50,
      maxSize: 50,
      Cell: ({renderedCellValue, row, column, table}) => (
        <Box width='100%'>
          <SocialIconButton 
            plat={row.original.platform} 
            i1={r1.index} 
            i2={r2.index} 
            i3={row.index} 
            c={column.columnDef.accessorKey} 
            addSocialField={addSocialField} 
          />
        </Box>
      ),
      header: 'Platform',
    },
    {
      id: 'url',
      header: 'URL',
      size: 120,
      maxSize: 120,
      accessorKey: 'url',
      Cell: ({renderedCellValue, row, column, table}) => (
        <URLtb 
          value={row.original.url} 
          i1={r1.index} 
          i2={r2.index} 
          i3={row.index} 
          c={column.columnDef.accessorKey} 
          addSocialField={addSocialField} 
        />
      ),
    },
    {
      id: 'followers',
      header: 'Followers',
      size: 80,
      maxSize: 80,
      accessorKey: 'followers',
      Cell: ({renderedCellValue, row, column, table}) => (
        <Followerstb 
          value={row.original.followers} 
          i1={r1.index} 
          i2={r2.index} 
          i3={row.index} 
          c={column.columnDef.accessorKey} 
          addSocialField={addSocialField} 
        />
      ),
    },
    {
      id: 'employees',
      header: 'Employees',
      size: 80,
      maxSize: 80,
      accessorKey: 'employees',
      Cell: ({renderedCellValue, row, column, table}) => (
        <Employeetb 
          value={row.original.employees} 
          i1={r1.index} 
          i2={r2.index} 
          i3={row.index} 
          c={column.columnDef.accessorKey} 
          addSocialField={addSocialField} 
        />
      ),
    },
    {
      id: 'status',
      accessorKey: 'status',
      size: 100,
      maxSize: 100,
      header: 'Status',
      Cell: ({renderedCellValue, row, column, table}) => (
        <Socialstb 
          value={row.original.status} 
          i1={r1.index} 
          i2={r2.index} 
          i3={row.index} 
          c={column.columnDef.accessorKey} 
          addSocialField={addSocialField} 
        />
      ),
    },
  ], []);

  const socialsTable = useMaterialReactTable({
    columns: socialsColumns,
    data: sData,
    enableExpandAll: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({row}) => (
      <RowActionsC i1={r1.index} i2={r2.index} i3={row.index} l={sData.length} addRowC={addRowC} subRowC={subRowC}/>
    ),
    layoutMode: 'grid-no-grow',
    enableBottomToolbar: false,
    initialState: {density: 'compact'},
    muiTableBodyProps: {sx: {backgroundColor: '#f0f0ed'}},
    muiTablePaperProps: {elevation: 0, sx: {padding: 0}},
    muiTableBodyRowProps: {sx: {padding: 0, margin: 0, height: 30, backgroundColor: '#f0f0ed'}},
    enableTopToolbar: false,
    enableTableHead: false,
  });

  return (
    <Box flexGrow={1} width='100%' sx={{flexGrow: 1}}>
      {(sData && (sData && sData.length > 0)) && <MaterialReactTable table={socialsTable}/>}
    </Box>
  );
});

//-------------------------------------------------------------------------------------------------------

const RowActionsC=memo(({i1,i2,i3,l,subRowC,addRowC})=>{
  return ( <Box sx={{ display: 'flex', gap: '1rem' }}>
  <Tooltip  title="Add">
    <IconButton size="small" sx={{padding:0}} color="primary" onClick={() => {addRowC(i1,i2,i3); }}>
      <Add fontSize="small" sx={{padding:0}} />
    </IconButton>
  </Tooltip>
  {(!(i3==0&&l==1))&&<Tooltip title="Remove">
    <IconButton size="small" sx={{padding:0}} color="primary" onClick={() => {subRowC(i1,i2,i3);}}>
      <Remove fontSize="small" sx={{padding:0}} />
    </IconButton>
  </Tooltip>}
</Box>)
}  )

// Add this near your other input components (e.g., Followerstb, Employeetb)
const URLtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addSocialField(props.i1, props.i2, props.i3, props.c, inputValue);
    }
  };

  return (
    <TextField
      placeholder="Enter URL"
      value={inputValue || ''}
      size='small'
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.addSocialField(props.i1, props.i2, props.i3, props.c, inputValue);
      }}
      onKeyPress={handleInputKeyPress}
      variant={conttbvariant}
      margin="normal"
      autoCorrect='false'
      InputProps={{sx: {fontSize: 13, height: 25}}}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0,
      }}
    />
  );
});