import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function MultiSortDialog({ open, onClose, columns, initialSorting, onApplySort }) {
  const [sortCriteria, setSortCriteria] = useState([{ id: '', desc: false }]);

  useEffect(() => {
    if (open) {
      setSortCriteria(initialSorting.length > 0 ? initialSorting : [{ id: '', desc: false }]);
    }
  }, [open, initialSorting]);

  const handleAddSortCriteria = () => {
    setSortCriteria([...sortCriteria, { id: '', desc: false }]);
  };

  const handleRemoveSortCriteria = (index) => {
    const newCriteria = [...sortCriteria];
    newCriteria.splice(index, 1);
    setSortCriteria(newCriteria);
  };

  const handleSortCriteriaChange = (index, field, value) => {
    const newCriteria = [...sortCriteria];
    newCriteria[index][field] = value;
    setSortCriteria(newCriteria);
  };

  const handleApplySort = () => {
    const validSortCriteria = sortCriteria.filter((criteria) => criteria.id);
    onApplySort(validSortCriteria);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: '12px 24px'}}>Sort Options</DialogTitle>
      <DialogContent dividers sx={{ padding: '16px 24px' }}>
        {sortCriteria.map((criteria, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Select
              value={criteria.id}
              onChange={(e) => handleSortCriteriaChange(index, 'id', e.target.value)}
              sx={{ minWidth: 220 , maxHeight: 40}}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">Select a column</MenuItem>
              {columns.map((column) => (
                <MenuItem key={column.accessorKey} value={column.accessorKey}>
                  {column.header}
                </MenuItem>
              ))}
            </Select>

            <RadioGroup
              row
              value={criteria.desc ? 'desc' : 'asc'}
              onChange={(e) => handleSortCriteriaChange(index, 'desc', e.target.value === 'desc')}
              sx={{ marginLeft: 'auto' }}
            >
              <FormControlLabel value="asc" control={<Radio size="small" />} label="A to Z" />
              <FormControlLabel value="desc" control={<Radio size="small" />} label="Z to A" />
            </RadioGroup>

            <IconButton
              onClick={() => handleRemoveSortCriteria(index)}
              size="small"
              sx={{ marginLeft: '8px' }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}

        <Button
          onClick={handleAddSortCriteria}
          sx={{ minWidth: 220 , minHeight: 40, mt: 1}}
          size="small"
          variant="outlined"
          color="primary"
        >
          Add another sort column
        </Button>
      </DialogContent>

      <DialogActions sx={{ padding: '8px 24px', justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApplySort} variant="contained" color="primary" sx={{ padding: '2px 8px'}}>
          Sort
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MultiSortDialog;
