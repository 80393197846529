import { LogLevel } from "@azure/msal-browser";

const msalConfig = {
    auth: {
      clientId: 'c671954e-7f6e-4db7-91f9-08fa9eca986b',
      issuer: 'https://login.microsoftonline.com/eba2c098-631c-4978-8326-5d25c2d09ca5/v2.0',
      //issuer: 'https://sts.windows.net/eba2c098-631c-4978-8326-5d25c2d09ca5/',
      authority: 'https://login.microsoftonline.com/eba2c098-631c-4978-8326-5d25c2d09ca5',
      redirectUri: 

        'https://app.namekart.com/',//
       // 'http://localhost:3000/',
      tenantId: 'eba2c098-631c-4978-8326-5d25c2d09ca5',
      strictDiscoveryDocumentValidation: false,
      postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false
    },
    system: {
        tokenRenewalOffsetSeconds: 600,
        navigateFrameWait: 0,
        loggerOptions: {
          loggerCallback(logLevel, message) {
            console.log(message);
          },
        },
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
       // Enable PKCE
  mode: 'redirect',
  interactionType: 'redirect',
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
  

 
export const protectedResources = {
  Api: {
    path: "/",
    scopes: ['openid','email','profile',"api://c671954e-7f6e-4db7-91f9-08fa9eca986b/Files.Edit"
  ],
  },
};
export const appRoles = {
  Admin:"Admin",
  Employee:"Employee",
  QA:"QA",
  Manager: "Manager",
  SocialManager:"social_manager",
  SocialAgent:"social_agent",
  AddOwned:'add_owned',
  AddLtd:'add_ltd',
  AddPD:'add_pd',
  AddAld:'add_ald',
  AddAll:'add_all',
  AllocateOwned:'allocate_owned',
  AllocateLtd:'allocate_ltd',
  AllocateAll:'allocate_all',
  Developer:'developer',
  ESManager:'es_manager',
  ESAnalyst:'es_analyst',
  EDManager:'ed_manager',
  EDAnalyst:'ed_analyst',
  
};
  
  export default msalConfig;
  