import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, Button,
  Snackbar, Alert, Tab, Tooltip, Link
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { theme } from '../Theme';
import { getAcquArchiveDomains, getAcquEsArchiveDomains } from '../api';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ContentCopy from '@mui/icons-material/ContentCopy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Archive() {
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [mode, setMode] = useState('ed');
  const [tabValue, setTabValue] = useState('all');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isEDManagerUser = isEDManager();
  const currentYear = new Date().getFullYear();

  const processingStates = ['all', 'recommendation', 'recommendation-rejected', 'decision', 'decision-rejected', 'order', 'order-placed'];

  useEffect(() => {
    let initialMode = 'ed';
    if (isAdminUser || isEDManagerUser) {
      initialMode = 'ed';
    } else if (isESManagerUser) {
      initialMode = 'es';
    }
    setMode(initialMode);
    fetchArchiveData(initialMode, tabValue);
  }, [isAdminUser, isESManagerUser, isEDManagerUser]);

  const fetchArchiveData = (currentMode, tab) => {
    setLoading(true);
    const apiCall = currentMode === 'ed' ? getAcquArchiveDomains : getAcquEsArchiveDomains;

    // Convert pagination parameters for API
    const page = pagination.pageIndex;
    const size = pagination.pageSize;

    apiCall(tab, page, size)
      .then(res => {
        if (res.data) {
          setData(res.data.content || []);
          setTotalElements(res.data.totalElements || 0);
        } else {
          console.error('API response format incorrect:', res);
          setData([]);
          setTotalElements(0);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setData([]);
        setTotalElements(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchArchiveData(mode, tabValue);
  }, [pagination.pageIndex, pagination.pageSize, mode, tabValue]);

  const columns = useMemo(() => [
    {
      accessorKey: 'edate',
      header: 'EDATE',
      size: 50,
      accessorFn: (row) => {
        const date = row.edate;
        return date ? new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short'
        }).replace(/ /g, '-') : '-';
      },
      sortingFn: 'datetime'
    },
    {
      accessorKey: 'sdate',
      header: 'sdate',
      size: 50,
      accessorFn: (row) => {
        const date = row.createdAt;
        return date ? new Date(date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short'
        }).replace(/ /g, '-') : '-';
      },
      sortingFn: 'datetime'
    },
    {
      accessorKey: 'list',
      header: 'list',
      size: 40,
      accessorFn: (row) => row.list ?? '-',
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'domain',
      header: 'domain',
      size: 120,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue() || '-'} placement="bottom">
          <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
            {cell.getValue() || '-'}
          </Typography>
        </Tooltip>
      ),
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'p',
      header: 'p',
      size: 30,
      accessorFn: (row) => row.p ?? '-'
    },
    {
      accessorKey: 'len',
      header: 'len',
      size: 40,
      accessorFn: (row) => row.len ?? '-'
    },
    {
      accessorKey: 'host',
      header: 'host',
      size: 60,
      accessorFn: (row) => row.host ?? '-'
    },
    {
      accessorKey: 'tld',
      header: 'tld',
      size: 30,
      accessorFn: (row) => row.tld ?? '-'
    },
    {
      accessorKey: 'wby',
      header: 'wby',
      size: 45,
      accessorFn: (row) => {
        const wby = row.wby;
        if (wby && wby.length >= 4) {
          const year = parseInt(wby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
    },
    {
      accessorKey: 'aby',
      header: 'aby',
      size: 30,
      accessorFn: (row) => {
        const aby = row.aby;
        if (aby && aby.length >= 4) {
          const year = parseInt(aby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
    },
    {
      accessorKey: 'reg',
      header: 'reg',
      size: 30,
      accessorFn: (row) => row.reg ?? '-'
    },
    {
      accessorKey: 'regx',
      header: 'regx',
      size: 50,
      accessorFn: (row) => row.regx ?? '-'
    },
    {
      accessorKey: 'sg',
      header: 'sg',
      size: 30,
      accessorFn: (row) => row.sg ?? '-'
    },
    {
      accessorKey: 'cpc',
      header: 'cpc',
      size: 30,
      accessorFn: (row) => {
        const cpc = row.cpc;
        return cpc !== undefined && cpc !== null ? Math.round(cpc) : '-';
      }
    },
    {
      accessorKey: 'bids',
      header: 'bids',
      size: 30,
      accessorFn: (row) => row.bids ?? '-'
    },
    {
      accessorKey: 'price',
      header: 'price',
      size: 50,
      accessorFn: (row) => row.price ?? '-'
    },
    {
      accessorKey: 'est',
      header: 'est',
      size: 30,
      accessorFn: (row) => {
        const value = row.est;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      }
    },
    {
      accessorKey: 'gdv',
      header: 'gdv',
      size: 30,
      accessorFn: (row) => {
        const value = row.gdv;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      }
    },
    {
      accessorKey: 'esw',
      header: 'esw',
      size: 30,
      accessorFn: (row) => {
        const value = row.esw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      }
    },
    {
      accessorKey: 'gdw',
      header: 'gdw',
      size: 30,
      accessorFn: (row) => {
        const value = row.gdw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      }
    },
    {
      accessorKey: 'spr500',
      header: 'spr500',
      size: 60,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value !== null && value !== undefined && value !== '' ?
          `${(value * 100).toFixed(0)}%` : '-';
      }
    },
    {
      accessorKey: 'sprGdv',
      header: 'sprgdv',
      size: 80,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return typeof value === 'number' ? `${(value * 100).toFixed(0)}%` : '-';
      },
      accessorFn: (row) => {
        const value = row.sprGdv;
        return value !== null && value !== undefined ? value : null;
      },
    },
    {
      accessorKey: 'reco',
      header: 'reco',
      size: 50,
      accessorFn: (row) => {
        const value = row.reco;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'apr',
      header: 'apr',
      size: 40,
      accessorFn: (row) => {
        const value = row.apr;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },
    {
      accessorKey: 'leads',
      header: 'leads',
      size: 100,
      Cell: ({ row }) => (
        <Box>
          {row.original.socialDomain?.socialWorks?.[0]?.topLeads?.map((lead, index) => {
            let domain = lead.domain || '-';
            if (domain !== '-') {
              try {
                domain = new URL(domain).hostname.replace('www.', '');
              } catch (error) {
                console.log(error);
              }
            }

            return (
              <Tooltip key={index} title={lead.domain || '-'} placement="bottom">
                <Link
                  href={domain !== '-' ? (domain.startsWith('http') ? domain : `https://${domain}`) : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="caption"
                  display="block"
                  sx={{
                    fontSize: '0.8rem',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  {domain}
                </Link>
              </Tooltip>
            );
          }) || '-'}
        </Box>
      )
    },
    {
      accessorKey: 'socials',
      header: 'socials',
      size: 150,
      minSize: 150,
      maxSize: 180,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialDomain?.socialWorks?.[0]?.topLeads || [];

        const getUserId = (url) => {
          if (!url) return 'N/A';
          try {
            const urlObj = new URL(url);
            switch (true) {
              case url.includes('linkedin.com'):
                return urlObj.pathname.split('/')[2] || url;
              case url.includes('twitter.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              case url.includes('facebook.com'):
                return urlObj.pathname.split('/')[1] || url;
              case url.includes('instagram.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              default:
                return url;
            }
          } catch {
            return url || 'N/A';
          }
        };

        const formatCount = (count) => {
          if (count === null || count === undefined) return '    ';
          // Get next power of 10
          const nextPower = count === 0 ? 0 : Math.pow(10, Math.ceil(Math.log10(count)));
          if (nextPower >= 1000000) return `${(nextPower / 1000000)}M`;
          if (nextPower >= 1000) return `${(nextPower / 1000)}K`;
          return nextPower.toString().padStart(4, ' ');
        };

        const renderSocialIcons = (lead) => {
          // Get all social platforms from lead
          const availableSocials = lead?.socials || [];
          
          // Find Facebook and Instagram entries
          const fbSocial = availableSocials.find(s => s.platform.toLowerCase() === 'facebook');
          const igSocial = availableSocials.find(s => s.platform.toLowerCase() === 'instagram');
          
          // Choose the one with more followers
          let socialWithMoreFollowers;
          if (fbSocial && igSocial) {
            socialWithMoreFollowers = (fbSocial.followers || 0) >= (igSocial.followers || 0) ? fbSocial : igSocial;
          } else {
            socialWithMoreFollowers = fbSocial || igSocial;
          }
          
          // Create filtered socials array with LinkedIn, X, and the winning FB/IG
          const filteredSocials = [
            availableSocials.find(s => s.platform.toLowerCase() === 'linkedin') || { platform: 'linkedin' },
            availableSocials.find(s => s.platform.toLowerCase() === 'x') || { platform: 'x' },
            socialWithMoreFollowers || { platform: fbSocial ? 'facebook' : 'instagram' }
          ];

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {filteredSocials.map((social) => {
                let Icon, count, platform = social.platform.toLowerCase();

                switch (platform) {
                  case 'linkedin':
                    Icon = LinkedInIcon;
                    count = social.employees;
                    break;
                  case 'x':
                    Icon = XIcon;
                    count = social.followers;
                    break;
                  case 'facebook':
                    Icon = FacebookIcon;
                    count = social.followers;
                    break;
                  case 'instagram':
                    Icon = InstagramIcon;
                    count = social.followers;
                    break;
                  default:
                    return null;
                }

                const isActive = social.status && social.status.toLowerCase() === 'active';
                const iconColor = isActive ? 'primary.main' : 'action.disabled';
                const tooltipContent = getUserId(social.url);

                return (
                  <Tooltip key={platform} title={tooltipContent}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      {social.url ? (
                        <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </Link>
                      ) : (
                        <>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          );
        };


        if (!socialLeads || socialLeads.length === 0) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Tooltip title="No copyright information available">
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <CopyrightIcon sx={{ 
                    fontSize: '0.9rem', 
                    color: '#FFB6C1', // Light pink color for missing copyright
                  }} />
                </Box>
              </Tooltip>
              {renderSocialIcons(null)}
            </Box>
          );
        }

        return (
          <Box>
            {socialLeads.map((lead, leadIndex) => (
              <Box key={leadIndex} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Tooltip 
                    title={
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block'}}>
                          Copyright Year: {lead?.copyright || 'Not Available'}
                        </Typography>
                        {lead?.description && (
                          <Typography variant="caption" sx={{ fontSize: '0.6rem', display: 'block', mt: 0.2 }}>
                            {lead.description}
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <CopyrightIcon sx={{ 
                        fontSize: '0.9rem', 
                        color: lead?.copyright 
                          ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                          : '#FFB6C1', // Light pink color for missing copyright
                      }} />
                    </Box>
                  </Tooltip>
                  {renderSocialIcons(lead)}
                </Box>
              </Box>
            ))}
          </Box>
        );
      }
    },
    {
      accessorKey: 'active',
      header: 'live',
      size: 30,
      Cell: ({ row }) => {
        const isActive = row.original.socialDomain?.socialWorks?.[0]?.topLeads?.some(lead =>
          lead.socials?.some(social => social.status?.toLowerCase() === 'active')
        );
        return (
          <Typography variant="caption" noWrap>
            {isActive ? '1' : '0'}
          </Typography>
        );
      }
    },
    {
      accessorKey: 'agents',
      header: 'agent',
      size: 80,
      minSize: 80,
      maxSize: 100,
      Cell: ({ row }) => {
        // Collect all agents with their roles
        const agents = [];

        // Add user (Shortlister/Uploader)
        if (mode === 'es') {
          if (row.original.aqShortlistings?.length > 0) {
            row.original.aqShortlistings.forEach(shortlisting => {
              if (shortlisting.user) {
                agents.push({
                  name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                  email: shortlisting.user.email,
                  role: 'Shortlister'
                });
              }
            });
          }
        } else {
          if (row.original.user) {
            agents.push({
              name: `${row.original.user.firstName} ${row.original.user.lastName}`,
              email: row.original.user.email,
              role: 'Uploader'
            });
          }
        }

        // Add processor (from socialWorks)
        if (row.original.socialDomain?.socialWorks?.[0]?.user) {
          const processor = row.original.socialDomain.socialWorks[0].user;
          agents.push({
            name: `${processor.firstName} ${processor.lastName}`,
            email: processor.email,
            role: 'Processor'
          });
        }

        // Add recommender
        if (row.original.recoBy) {
          agents.push({
            name: typeof row.original.recoBy === 'string'
              ? row.original.recoBy
              : `${row.original.recoBy.firstName} ${row.original.recoBy.lastName}`,
            email: row.original.recoBy?.email,
            role: 'Recommender'
          });
        }

        // Add approver
        if (row.original.aprUnaprBy) {
          agents.push({
            name: typeof row.original.aprUnaprBy === 'string'
              ? row.original.aprUnaprBy
              : `${row.original.aprUnaprBy.firstName} ${row.original.aprUnaprBy.lastName}`,
            email: row.original.aprUnaprBy?.email,
            role: 'Approver'
          });
        }

        if (agents.length === 0) return '-';

        return (
          <Stack 
            direction="row" 
            spacing={0.25}
            alignItems="center"
            sx={{ 
              flexWrap: 'wrap',
            }}
          >
            {agents.map((agent, index) => {
              if (!agent?.name) return null;

              const initials = agent.name
                .split(' ')
                .filter(part => part.length > 0)
                .slice(0, 2)
                .map(part => part[0].toUpperCase())
                .join('');

              const getBackgroundColor = (role) => {
                switch (role) {
                  case 'Uploader':
                  case 'Shortlister': return '#e3f2fd';
                  case 'Processor': return '#f1f8e9';
                  case 'Recommender': return '#fff3e0';
                  case 'Approver': return '#fce4ec';
                  default: return '#f5f5f5';
                }
              };

              return (
                <Tooltip
                  key={`${index}-${agent.name}`}
                  title={
                    <Box sx={{ p: 0.5 }}>
                      <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                        {agent.name}
                      </Typography>
                      {agent.email && (
                        <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                          {agent.email}
                        </Typography>
                      )}
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.7rem',
                          fontStyle: 'italic',
                          mt: 0.5
                        }}
                      >
                        Role: {agent.role}
                      </Typography>
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box
                    sx={{
                      backgroundColor: getBackgroundColor(agent.role),
                      border: '1px solid rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: 'medium',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        textAlign: 'center'
                      }}
                    >
                      {initials}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Stack>
        );
      }
    },
  ], [mode]);

  const handleCopyDomains = useCallback((selectedRows) => {
    const domains = selectedRows.map(row => row.original.domain).join('\n');
    navigator.clipboard.writeText(domains).then(() => {
      setSnackbar({ open: true, message: 'Domains copied to clipboard', severity: 'success' });
    }).catch(err => {
      setSnackbar({ open: true, message: 'Failed to copy domains', severity: 'error' });
    });
  }, []);

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Define all columns to export
      const exportColumns = [
        'edate', 'sdate', 'list', 'domain', 'p', 'len', 'host', 'tld',
        'wby', 'aby', 'reg', 'regx', 'sg', 'cpc', 'bids', 'price',
        'est', 'gdv', 'esw', 'gdw', 'spr500', 'reco', 'apr', 'leads',
        'socials', 'active', 'agents'
      ];

      // Create CSV header
      const header = exportColumns.join(',') + '\n';

      // Create CSV content from selected rows
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          let value;
          
          switch(col) {
            case 'edate':
              const edate = row.original.edate;
              value = edate ? new Date(edate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short'
              }).replace(/ /g, '-') : '-';
              break;

            case 'sdate':
              const sdate = row.original.createdAt;
              value = sdate ? new Date(sdate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short'
              }).replace(/ /g, '-') : '-';
              break;

            case 'spr500':
              value = row.original.spr500 !== null && row.original.spr500 !== undefined
                ? `${(row.original.spr500 * 100).toFixed(0)}%`
                : '-';
              break;

            case 'leads':
              value = row.original.socialDomain?.socialWorks?.[0]?.topLeads
                ?.map(lead => {
                  try {
                    return new URL(lead.domain).hostname.replace('www.', '');
                  } catch {
                    return lead.domain || '-';
                  }
                })
                .join('; ') || '-';
              break;

            case 'socials':
              const socialLeads = row.original.socialDomain?.socialWorks?.[0]?.topLeads || [];
              const socialInfo = socialLeads.map(lead => {
                const platforms = ['linkedin', 'instagram', 'facebook', 'x'];
                const socialData = platforms.map(platform => {
                  const social = lead?.socials?.find(s => s.platform.toLowerCase() === platform);
                  if (!social) return '';
                  const count = platform === 'linkedin' ? social.employees : social.followers;
                  return `${platform}:${count || 0}`;
                }).filter(Boolean);
                return socialData.join(',');
              }).join('; ');
              value = socialInfo || '-';
              break;

            case 'active':
              value = row.original.socialDomain?.socialWorks?.[0]?.topLeads?.some(lead =>
                lead.socials?.some(social => social.status?.toLowerCase() === 'active')
              ) ? '1' : '0';
              break;

            case 'agents':
              const agents = [];
              // Add user/shortlister
              if (mode === 'es') {
                if (row.original.aqShortlistings?.length > 0) {
                  agents.push(...row.original.aqShortlistings.map(s => 
                    `${s.user.firstName} ${s.user.lastName}(Shortlister)`
                  ));
                }
              } else if (row.original.user) {
                agents.push(`${row.original.user.firstName} ${row.original.user.lastName}(Uploader)`);
              }
              // Add processor
              if (row.original.socialDomain?.socialWorks?.[0]?.user) {
                const processor = row.original.socialDomain.socialWorks[0].user;
                agents.push(`${processor.firstName} ${processor.lastName}(Processor)`);
              }
              // Add recommender
              if (row.original.recoBy) {
                const name = typeof row.original.recoBy === 'string'
                  ? row.original.recoBy
                  : `${row.original.recoBy.firstName} ${row.original.recoBy.lastName}`;
                agents.push(`${name}(Recommender)`);
              }
              // Add approver
              if (row.original.aprUnaprBy) {
                const name = typeof row.original.aprUnaprBy === 'string'
                  ? row.original.aprUnaprBy
                  : `${row.original.aprUnaprBy.firstName} ${row.original.aprUnaprBy.lastName}`;
                agents.push(`${name}(Approver)`);
              }
              value = agents.join('; ') || '-';
              break;

            default:
              value = row.original[col];
          }

          // Handle null/undefined values
          if (value === null || value === undefined) return '-';
          
          // Handle values containing commas
          if (typeof value === 'string' && (value.includes(',') || value.includes(';'))) {
            return `"${value}"`;
          }
          
          return value;
        }).join(',');
      }).join('\n');

      // Combine header and content
      const csv = header + csvContent;

      // Create and trigger download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `archive_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setSnackbar({
        open: true,
        message: 'Data exported successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export data',
        severity: 'error'
      });
    }
  }, [mode]); // Added mode to dependencies

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableSorting: true,
    enableMultiSort: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalElements,
    onPaginationChange: setPagination,
    state: {
      pagination,
      isLoading: loading,
    },
    initialState: {
      density: 'compact',
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
      showFirstButton: true,
      showLastButton: true,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const buttonSx = {
        textTransform: 'none',
        backgroundColor: '#8797A8',
        color: '#FFFFFF !important',
        padding: '4px 8px',
        minWidth: 'auto',
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
        '&:hover': {
          backgroundColor: '#8797A8',
          opacity: 0.9,
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        },
        '&.Mui-disabled': {
          backgroundColor: '#8797A8',
          opacity: 0.7,
        }
      };

      return (
        <Stack direction="row" spacing={1} width="100%" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={0.5}>
            <Button
              size="small"
              startIcon={<ContentCopy />}
              onClick={() => handleCopyDomains(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Copy
            </Button>
            <Button
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => handleExportData(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Export
            </Button>
          </Stack>
        </Stack>
      );
    },


    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#e6e6fa',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    fetchArchiveData(mode, newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Archive
          </Typography>
          <Box flexGrow={1}></Box>
          {(isAdminUser || (isEDManagerUser && isESManagerUser)) && (
            <Button
              variant="outlined"
              onClick={() => {
                const newMode = mode === 'ed' ? 'es' : 'ed';
                setMode(newMode);
                fetchArchiveData(newMode, tabValue);
              }}
              sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
            >
              Switch To {mode === 'ed' ? 'ES' : 'ED'}
            </Button>
          )}
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {processingStates.map((state) => (
                <Tab
                  key={state}
                  label={state.charAt(0).toUpperCase() + state.slice(1)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {processingStates.map((state) => (
            <TabPanel key={state} value={state} sx={{ padding: 1 }}>
              <Box>
                <MaterialReactTable table={table} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}
