import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Paper, Select, Slider, Snackbar, Stack, Tab, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useContext, useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    Drawer,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    IconButton,
    
  } from '@mui/material';
import { Add, CalendarMonth, CalendarMonthOutlined, Close, ExpandMore } from "@mui/icons-material";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useUser } from "./UserProvider";
import { getChildren, getDayWorkId, getProcessors, getTeamMISReportsPending, getTeamMISReportsSubmitted, getTeamQAReports, submitMIS, updateMIS } from "./api";
import { UserContext1 } from "..";
import { getUserName, isAdmin, isQA } from "./msalService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
const getDate1Day=()=>{
    let d=new Date(); d.setDate(d.getDate()-1); return dayjs(d);
    }
    
export default function MISReports() 
{
    const [date, setDate] = useState(getDate1Day());
    const [date1, setDate1] = useState(dayjs(new Date()));
    const [dates, setDates] = useState(getDate1Day());
    const [date1s, setDate1s] = useState(dayjs(new Date()));
    let [id,setId]=useState(0);
    let [index,setIndex]=useState(0);

    const [tvalue, setTvalue] = useState('Pending');

    const [openp, setOpenp] = React.useState(false);
    const [opens, setOpens] = React.useState(false);

    const handleCloseP = () => {
      setOpenp(false);
    };

    const handleCloseS = () => {setOpens(false);};
    let [psize,setPsize]= useState(50);
    let [ol,setOl]= useState(false);
    const [c, setC] = useState(false);

    const [rows, setRows] = useState([]);
    const [data, setData] = useState(null);
    const[upsuc, setUpsuc]=useState(false);
    const[endAnswer, setEndAnswer]=useState(0);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const [tor, setTor] = useState('');
    const [pf, setPf] = useState('');
    const [remark, setRemark] = useState('');
    const [updated, setUpdated] = useState(false);
    const ratingToText = (value) => {
      switch (value) {
        case -1:
          return 'Average';
        case 0:
          return 'Good';
        case 1:
          return 'Great';
        case -10:
          return 'Not Answered';
        default:
          return '';
      }
    };

    const ratingToTextEndQ = (value) => {
      switch (value) {
        case -1:
          return 'Somewhat';
        case 0:
          return 'Enough';
        case 1:
          return 'Highly';
        case -10:
          return 'Not Answered';
        default:
          return '';
      }
    };

    
    useEffect(()=>{console.log(updated)},[updated])
  
    useEffect(()=>{
      if(data)
      {setTor(data.tor)
    setPf(data.pf)
  setRemark(data.remark)}
  //setUpdated(false)
  },[data])
    const [rows1, setRows1] = useState([]);
    const [rowss, setRowss] = useState([]);
    const [rows1s, setRows1s] = useState([]);

    const [processor, setProcessor] = useState(getUserName());
    const [processor1, setProcessor1] = useState(getUserName());

    const [processors, setProcessors] = useState([]);
    const { user, updateUser,olevel } = useUser();
    useEffect(()=>{
        getTeamMISReportsPending(date.format('YYYY-MM-DD'),date1.format('YYYY-MM-DD')).then((res)=>{setRows(res.data); console.log(res.data)}).catch((err)=>{console.log(err)})},[date,date1,c])
    useEffect(()=>{
        if(!ol)
        {if(processor=='All')
        setRows1(rows);
    else
        setRows1(rows.filter((row)=>{if(row.user.email===processor) return row;}))}
    else
    {
        setRows1(rows);
    }
},[rows,processor])

useEffect(()=>{
    getTeamMISReportsSubmitted(dates.format('YYYY-MM-DD'),date1s.format('YYYY-MM-DD')).then((res)=>{setRowss(res.data); console.log(res.data)}).catch((err)=>{console.log(err)})},[dates,date1s,c])
useEffect(()=>{
    if(!ol)
    {if(processor1=='All')
    setRows1s(rowss);
else
    setRows1s(rowss.filter((row)=>{if(row.user.email===processor1) return row;}))}
else setRows1s(rowss);
},[rowss,processor1])


useEffect(()=>{if(isQA()||isAdmin()) {getProcessors().then((res)=>{setProcessors(res.data)}).catch((err)=>{console.log(err)})} else {getChildren().then((res)=>{setProcessors(res.data)}).catch((err)=>{console.log(err)})}},[])

useEffect(()=>{if(user.users.length>0)
setOl(false);
else setOl(true);
},[user])

    const columns = [
      {
        field: 'dateOfWork',
        headerName: 'Work Date',
        type: 'text',
        width: 150,valueGetter: (params) => {return new Date(params.row.dateOfWork).toISOString().substring(0,10)}
    },
    {
      field: 'submittedDate',
      headerName: 'Submission Date',
      type: 'text',
      width: 150,valueGetter: (params) => {return new Date(params.row.submittedDate).toISOString().substring(0,10)}
  },
  { field: 'pqo', headerName: 'PQ-O',type:'number', width: 90},
  { field: 'pqltd', headerName: 'PQ-Ltd',type:'number', width: 90},
  { field: 'primaryleads', headerName: 'PL',type:'number', width: 90},
  { field: 'totalLeads', headerName: 'Leads',type:'number', width: 90},
  { field: 'tor', headerName: 'TOR',type:'number', width: 90},
  { field: 'pf', headerName: 'PF',type:'number', width: 90},

        { field: 'user', headerName: 'Processor', width: 170,valueGetter: (params) => {return (params.row.user.firstName+" "+params.row.user.lastName)} 
    },
    
        { field: 'remark', headerName: 'Remark', width: 150, valueGetter: (params) => {return (params.row.remark)}
    }
    ]
//-----------------------------------------------------------------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------------------------------------------

function CustomToolbar(props) {
    const ids=props.ids
    return (
      <GridToolbarContainer sx={{ display:'flex',flexDirection:'row', color:'black'}} >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Box flexGrow={1}/>
       
{/*isAdmin()&& <>
<Button
variant="text"
//color="primary"
startIcon={<DeleteOutline />}
onClick={()=>{}}
sx={{
  textTransform:'none'
}}
>
Delete
</Button>
<Button
variant="text"
//color="primary"
startIcon={<ShortcutOutlined />}
onClick={()=>{}}
sx={{
  textTransform:'none'
}}
>
Move
</Button></>*/}
      </GridToolbarContainer>
    );
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------





    return (<ThemeProvider theme={theme}>
        <TabContext value={tvalue} >
        <Stack direction='column'  spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      MIS Reports
    </Typography>
    <Box sx={{flexGrow:1}}/>
    {(tvalue=='Pending')&&<Stack direction='row' alignItems='center' spacing={2}><LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{}} components={['DatePicker','DatePicker']}>
      <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work From"
          value={date}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate(newValue);}}
        />
        <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work To"
          value={date1}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate1(newValue);}}
        />
         </DemoContainer>
    </LocalizationProvider>
   {(!ol)&& <Box paddingTop={1}>
        <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
      <InputLabel id="demo-select-small-label">Processor</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={processor}
        label="Processor"
        onChange={(e) => setProcessor(e.target.value)}
        //sx={{flexDirection:'column',justifyContent:'end',alignItems:'end'}}
        > <MenuItem value='All'>{"All"}</MenuItem>
        <MenuItem value={getUserName()}>{"Me"}</MenuItem>
       {processors&&processors.map((child)=>{ 
              if(child.email!==getUserName())return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
      </Select>
    </FormControl>
    </Box>}
    </Stack>}
    {(tvalue=='Submitted')&&<Stack direction='row' alignItems='center' spacing={2}><LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{}} components={['DatePicker','DatePicker']}>
      <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work From"
          value={dates}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDates(newValue);}}
        />
        <DesktopDatePicker 
      format='YYYY-MM-DD'
        slotProps={{ textField: { size: 'small' } }}
        sx={{
        color:'text.primary',fontWeight:'600',  padding: "0px 0px 0px 0px !important",m:0,width:200}}
          label="Work To"
          value={date1s}
          onChange={(newValue) => {
            //const d=new Date()
            //newValue=newValue.set('D',d.getDate());
            setDate1s(newValue);}}
        />
         </DemoContainer>
    </LocalizationProvider>
   {(!ol)&& <Box paddingTop={1}>
        <FormControl  sx={{  minWidth: 200,justifyContent:'start' }} size="small">
      <InputLabel id="demo-select-small-label">Processor</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={processor1}
        label="Processor"
        onChange={(e) => setProcessor1(e.target.value)}
        //sx={{flexDirection:'column',justifyContent:'end',alignItems:'end'}}
        > <MenuItem value='All'>{"All"}</MenuItem>
        <MenuItem value={getUserName()}>{"Me"}</MenuItem>
       {processors&&processors.map((child)=>{ 
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
      </Select>
    </FormControl>
    </Box>}
    </Stack>}
    <TabList
      //orientation="vertical"
         onChange={(event, newValue) => {
            setTvalue(newValue);
          }}
         sx={{
          //width: '540px',
          height: '40px',
             backgroundColor: '#ffffff',
             borderRadius: '0',
             '& .MuiTab-root': {
               '&.Mui-selected': {
                 backgroundColor: '#fef7ff',
               },
               '&:hover': {
                 backgroundColor: '#fef7ff',
               },
               '&.Mui-selected:hover': {
                 backgroundColor: '#fef7ff',
               },
             },
             
           }}
      >
        <Tab sx={{width:'130px'}} value="Pending" label={<Stack direction='row' alignItems='center' spacing={1.25}><Typography sx={{fontSize: '14px',fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>Pending</Typography></Stack>} />
        <Tab sx={{width:'130px'}} value="Submitted" label={<Stack direction='row' alignItems='center' spacing={1.25}><Typography sx={{fontSize: '14px',
                fontWeight: '800',
                color: '#49454f',
                lineHeight: '1.25',}}>Submitted</Typography></Stack>} />
          
              
                
      </TabList>
            </Stack>
            <TabPanel value="Pending" sx={{padding:0}}>
            <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
          <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          MIS Updated!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2500} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          MIS Not Updated!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          MIS Submitted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2500} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          MIS Not Submitted!
        </Alert>
      </Snackbar>
      <Box>
      {data&&<Dialog maxWidth='lg' open={openp} onClose={handleCloseP} >
        <DialogTitle><Stack direction='row'><Typography>Work Date </Typography><Typography fontWeight='bold'>{":    "+data.dateOfWork}</Typography><Box flexGrow={1} /> <Typography fontWeight='bold'>{data.user.firstName+" "+data.user.lastName}</Typography> </Stack></DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={4} paddingTop={3}>
            <Stack direction='row' spacing={4}>
              <Typography fontWeight='bold'>Auto-Generated Details</Typography>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>PQ-O</Typography><Typography fontWeight='bold'>{data.pqo}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>PQ-L</Typography><Typography fontWeight='bold'>{data.pqltd}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>Primary Leads</Typography><Typography fontWeight='bold'>{data.primaryleads}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>Total Leads</Typography><Typography fontWeight='bold'>{data.totalLeads}</Typography></Stack>
</Stack>
            <Stack direction='row' width='100%' justifyContent='space-between' spacing={3}>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}} >
                <Typography fontSize={14} fontWeight='bold' >List OB</Typography>
                <List disablePadding >
                  {data.obList.map((row)=>{return <ListItem disableGutters ><ListItemText  primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              </Stack>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}} >
                <Typography fontSize={14} fontWeight='bold' >List OG</Typography>
                <List >
                  {data.ogList.map((row)=>{return <ListItem disableGutters><ListItemText primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              
              </Stack>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}}>
                <Typography fontSize={14} fontWeight='bold'>List LTD</Typography>
                <List>
                  {data.ltdList.map((row)=>{return <ListItem><ListItemText primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              </Stack>
            </Stack>
            </Stack>
            <Stack direction='column' spacing={3} paddingTop={3.5}>
              <Typography fontWeight='bold'>Enter Manually</Typography>
              <Stack direction='row' spacing={3}>
                <Stack direction='column' spacing={1.5}>
                  <Typography fontWeight='bold'>Totally Opening Rate</Typography>
                  <TextField
              margin="normal"
              id="TOR"
              placeholder="TOR"
              type='number'
              size="small"
              fullWidth
              value={tor}
              onChange={(e)=>{setTor(e.target.value)}}
            />
                  </Stack>
                  <Stack direction='column' spacing={1.5}>
                  <Typography fontWeight='bold'>Primary Follow Ups</Typography>
                  <TextField
              margin="normal"
              id="PF"
              placeholder="PF"
              type="number"
              fullWidth
              size="small"
              value={pf}
              onChange={(e)=>{setPf(e.target.value)}}
            />
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1.5}>
                  <Typography fontWeight='bold'>Remark</Typography>
                  <TextField
              margin="normal"
              id="remark"
              placeholder="Remark"
              type="text"
              fullWidth
              size="small"
              value={remark}
              onChange={(e)=>{setRemark(e.target.value)}}
            />
            {data&&data.startQuestion&&data.endQuestion&&<Stack direction='row' width='100%' justifyContent='space-between' spacing={1.5}>
            <Stack direction='column' alignItems='start' spacing={1.5}>
            <Typography fontWeight='bold'>{data?data.startQuestion.question:"Start Question"}</Typography>
            <Typography >{data?ratingToText(data.startAnswer):"Start Answer"}</Typography>
            </Stack>
            <Stack direction='column'  alignItems='end'  spacing={1.5}>
            <Typography fontWeight='bold'>{data?data.endQuestion.question:"End Question"}</Typography>
            <Box sx={{ width: 200, pr:2}}>
              <Slider
                aria-label="End Answer"
                defaultValue={0}
                step={1}
                // marks={[
                //   { value: -1, label: 'Somewhat' },
                //   { value: 0, label: 'Enough' },
                //   { value: 1, label: 'Highly' }
                // ]}
                min={-1}
                max={1}
                value={endAnswer}
                onChange={(e, newValue) => setEndAnswer(newValue)}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => ['Somewhat', 'Enough', 'Highly'][value + 1]}
                
              />
            </Box>
            </Stack>
            </Stack>}
            <Typography fontWeight='bold'>Remark</Typography>
                  <TextField
              margin="normal"
              id="remark"
              placeholder="Remark"
              type="text"
              fullWidth
              size="small"
              value={remark}
              onChange={(e)=>{setRemark(e.target.value)}}
            />
                  </Stack>
              </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{updateMIS(data.id).then((res)=>{setData(res.data);
          setRows((prev)=>{let temp=[...prev]; temp[index]=res.data; return temp;});setUpdated(true);setUpsuc(true)}).catch((err)=>{console.log(err); setUpfail(true)});}} >
            Auto Fetch
          </Button>
         { (user.email==data.user.email)&&<Button disabled={(!updated)} 
          onClick={()=>{submitMIS(data.id,tor,pf,remark,endAnswer).then((res)=>{setOpenp(false);setC(!c);setUpsuc1(true);setUpdated(false)}).catch((err)=>{console.log(err);setUpfail1(true)})}}
          >
            Submit MIS
          </Button>}
        </DialogActions>
      </Dialog>}
    </Box>
            <List >
              <Stack direction='column' spacing={1}>
                {rows1.map((row,index)=>{return <><ListItem sx={{backgroundColor:'white'}} button onClick={()=>{setData(row);setIndex(index); setOpenp(true); setUpdated(false)}}>
                    <ListItemIcon><CalendarMonthOutlined/></ListItemIcon>
                    <ListItemText  primary={row.dateOfWork}/>
                    <Box sx={{flexGrow:1}}/>
                    <Typography fontWeight='bold'>{row.user.firstName+" "+row.user.lastName}</Typography>
                </ListItem>
              </>})}
              </Stack>
            </List>
     </Box>
    </TabPanel>
    <TabPanel value="Submitted" sx={{padding:0}}>
    <Box fontFamily='Inter' sx={{
         width:'100%'
         }} >
          {data&&<Dialog maxWidth='lg' open={opens} onClose={handleCloseS} >
        <DialogTitle><Stack direction='row'><Typography>Work Date </Typography><Typography fontWeight='bold'>{":    "+data.dateOfWork}</Typography><Box flexGrow={1} /> <Typography fontWeight='bold'>{data.user.firstName+" "+data.user.lastName}</Typography> </Stack></DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={5} paddingTop={3}>
            <Stack direction='row' spacing={5}>
            <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>TOR</Typography><Typography fontWeight='bold'>{data.tor}</Typography></Stack>
            <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>PF</Typography><Typography fontWeight='bold'>{data.pf}</Typography></Stack>
            <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>PQ-O</Typography><Typography fontWeight='bold'>{data.pqo}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>PQ-L</Typography><Typography fontWeight='bold'>{data.pqltd}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>Primary Leads</Typography><Typography fontWeight='bold'>{data.primaryleads}</Typography></Stack>
              <Stack sx={{backgroundColor:'#fff2fb', px:1,borderRadius:2,}} direction='row' spacing={1}><Typography>Total Leads</Typography><Typography fontWeight='bold'>{data.totalLeads}</Typography></Stack>
</Stack>
            <Stack direction='row' width='100%' justifyContent='space-between' spacing={4}>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}} >
                <Typography fontSize={14} fontWeight='bold' >List OB</Typography>
                <List disablePadding >
                  {data.obList.map((row)=>{return <ListItem disableGutters ><ListItemText  primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              </Stack>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}} >
                <Typography fontSize={14} fontWeight='bold' >List OG</Typography>
                <List >
                  {data.ogList.map((row)=>{return <ListItem disableGutters><ListItemText primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              
              </Stack>
              <Stack direction='column' sx={{backgroundColor:'#f2fffe', minWidth:200,borderRadius:2,px:1,paddingTop:1}}>
                <Typography fontSize={14} fontWeight='bold'>List LTD</Typography>
                <List>
                  {data.ltdList.map((row)=>{return <ListItem><ListItemText primary={<Typography fontSize={14}  >{row.domain}</Typography>}/></ListItem>})}
                </List>
              </Stack>
            </Stack>
            </Stack>

            {data&&data.startQuestion&&data.endQuestion&&<Stack direction='row' spacing={3} mt={3}>
            <Stack direction='row' spacing={1.5}>
              <Typography fontWeight='bold'>{data.startQuestion.question}</Typography>
              <Typography >{ratingToText(data.startAnswer)}</Typography>
            </Stack>
            <Stack direction='row' spacing={1.5}>
              <Typography fontWeight='bold'>{data.endQuestion.question}</Typography>
              <Typography >{ratingToTextEndQ(data.endAnswer)}</Typography>
            </Stack>
            </Stack>}
            <Stack direction='column' paddingTop={3.5} spacing={1.5}>
                                <Typography fontWeight='bold'>Remark</Typography>
                 <Typography>{data.remark}</Typography>
              </Stack>
        </DialogContent>
       
      </Dialog>}
      <DataGrid
        autoHeight 
        rows={rows1s}
        columns={columns}
        pageSize={psize}
        onRowClick={(params)=>{setData(params.row); setOpens(true)}}
        onPageSizeChange={(newPageSize) => setPsize(newPageSize)}
        rowsPerPageOptions={[10,25,50,100,500]}
        disableRowSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        
        //onRowClick={(params)=>{console.log('row clicked');setId(params.row.id); setOpen(true)}}
      />

      </Box>
    </TabPanel>
            
            </Stack>
            </TabContext>
            </ThemeProvider>
    )

}

//---------------------------------------------------------------------------------------------------------------------------

