import { Alert, Backdrop, Box, Button, Checkbox, Chip, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDayWork, getDayWorkId, getMissedLeads, getPresentAllocs, getPresentAllocsWI, getTodayWork, getUsersQA, restartAnalysis, saveLeadQA, saveMissedLeads, startAnalysis, stopAnalysis, workingpa, workstatuspa } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Add, Cancel, CheckCircle, Close, Create, Done, DoneOutline, Remove, Save, Stop } from "@mui/icons-material";
import { useUser } from "./UserProvider";
import { getUserName } from "./msalService";
import { useParams } from "react-router";
import dayjs from "dayjs";

export default function WorkInputCopy() 
{

    const { id } = useParams();

    const [presentAllocs, setPresentAllocs] = useState([]);
    const ref=useRef(null)
    const handleFocus = (id) => {
        ref.current = id; // Update the ref with the currently focused TextField
        console.log(ref)
      };
     // useEffect(()=>{console.log(ref)},[presentAllo])
    const [canreview,setCanreview]=useState(true);
    const CheckB=(props)=>{
  
        const [checked,setChecked]=useState(props.working);
        const [status,setStatus]=useState(props.status);
      
        const handleChange = (event) => {
          const check=event.target.checked;console.log(check)
          workingpa(props.id,check).then(()=>{
            setChecked(check);
            const arr=presentAllocs;
            for(let i=0;i<arr.length;i++)
            {
              if(arr[i].id==props.id)
              arr[i].working=check;
            }
            setPresentAllocs(arr);
          }).catch((err)=>{console.log(err)})
          
        };
      
        const Icon=(props)=>{
          
          if(props.stat==0)
        return <Tooltip title="Not Started Processing"><Stop sx={{padding:0}}/></Tooltip>
        else if(props.stat==1)
        return <Tooltip title="Processing"><Create  sx={{padding:0}} color="primary"/></Tooltip>
        else if(props.stat==2)
        return <Tooltip title="Processed"><DoneOutline sx={{color:'green',padding:0}}/></Tooltip>
        }

        return(<Box>
            <IconButton sx={{padding:0}} size="small" onClick={()=>{
              let s=status;
              if(status==0) s=1;
            else if(status==1)s=2;
            if(status==2) s=0;
            workstatuspa(props.id,s).then((res)=>{setStatus(s);
              const arr=presentAllocs;
              for(let i=0;i<arr.length;i++)
              {
                if(arr[i].id==props.id)
                arr[i].status=s;
              }
              setPresentAllocs(arr);
            }).catch((err)=>{console.log(err)})
            }}><Icon stat={status} /></IconButton>
           {/* <Checkbox  icon={<CreateOutlined />}
          checkedIcon={<Create />} checked={checked} onChange={handleChange} />*/}</Box>);
        }
      


    const columns = React.useMemo(
        () => [
          {
            id: 'domain',
            header: 'Domain',
            accessorFn: (row) => {return row.domain.domain;},

          },
          {
            id: 'working',
            accessorFn: (row) => {return row.status;},
            Cell: ({renderedCellValue,row})=>{return(<CheckB id={row.original.id} working={row.original.working} status={row.original.status}/>) },
            header: 'Status',
          },
          {
            id: 'Type',
            accessorFn: (row) => { return row.domain.ltd;},
            Cell: ({renderedCellValue,row})=>{ return(<Box><Chip label={renderedCellValue?"Owned":"Ltd"} size="small" sx={{backgroundColor:renderedCellValue?'#C6CFFF':'#FFC682',height:17,fontSize:10}} /> </Box>);},
            header: 'Type',
          },
          {
            id: 'EST',
            accessorFn: (row) => {
                return row.domain.EST;  
            },
            header: 'EST',
          },
          {
            id: 'PP',
            accessorFn: (row) => { 
                return row.domain.PP;
        },
            header: 'PP',
          },
          
        ],
        []
      );

      const getLeadsData = React.useCallback((domainWork) => {
        return domainWork.leads;
      }, []);

      const RenderDetailPanel = 
        memo(({ row }) => {
          const leadsData =useMemo(()=>{return getLeadsData(row.original.todayDW)},[row.original.todayDW]);
          const [r1,setR1]=useState(row);
          const [xrow,setXrow]=useState(true);
         // useEffect(()=>{console.log("r1")},[])
          
          const Cf=(props)=>{
            const [checked,setChecked]=useState(props.checked);
            return (<FormControlLabel sx={{padding:0}} control={<Checkbox sx={{paddingY:0}} onChange={ (event) => {
                const c=event.target.checked
                setChecked(c); setPresentAllocs((prevAllocs) => {
                    const updatedAllocs = [...prevAllocs];
                    updatedAllocs[row.index].todayDW.leads[props.in].contactForm = c; // Update the site value at the specified index
                    return updatedAllocs;
                  });              }
            } checked={checked} size='small' />} label={ <Typography padding={0} fontSize={14} color='text.primary' align='left'>Contact Form</Typography> }  />)}


          const Imp=(props)=>{
            const [checked,setChecked]=useState(props.checked);
            return (<FormControlLabel sx={{padding:0}} control={<Checkbox sx={{paddingY:0}} onChange={ (event) => {
                const c=event.target.checked
                setChecked(c); setPresentAllocs((prevAllocs) => {
                    const updatedAllocs = [...prevAllocs];
                    updatedAllocs[row.index].todayDW.leads[props.in].imp = c; // Update the site value at the specified index
                    return updatedAllocs;
                  });
              }
            } checked={checked} size='small' />} label={ <Typography padding={0} fontSize={14} color='text.primary' align='left'>Imp Classify</Typography> }  />)}

            const Leadtb=memo((props)=>{
                const [inputValue, setInputValue] = useState(props.value);
               // const inputRef = useRef(null);
               //useEffect(()=>{console.log("r2")},[])
                const handleInputChange = (event) => {
                    const val=event.target.value
                    setInputValue(val);
                    //inputRef.current.focus();
                    };

                    const handleInputKeyPress = (event) => {
                        if (event.key === 'Enter') {
                            setPresentAllocs((prevAllocs) => {
                                const updatedAllocs = [...prevAllocs];
                                updatedAllocs[row.index].todayDW.leads[props.in].site = inputValue; // Update the site value at the specified index
                                return updatedAllocs;
                              });
                        }
                      };
                return(<TextField
                //label="Leads"
                placeholder="lead.com"
                value={inputValue}
                size='small'
                inputRef={ref.current === row.index ? ref : null}
                onClick={() => handleFocus(row.index)}
                //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
                onChange={handleInputChange}
                onBlurCapture={()=>{setPresentAllocs((prevAllocs) => {
                    const updatedAllocs = [...prevAllocs];
                    updatedAllocs[row.index].todayDW.leads[props.in].site = inputValue; // Update the site value at the specified index
                    return updatedAllocs;
                  });}}
                onKeyPress={handleInputKeyPress}
                variant='outlined'
                margin="normal"
                autoCorrect={false}
                InputProps={{sx:{fontSize:14,height:30}}}
                sx={{'& legend': { display: 'none' },
                '& fieldset': { top: 0 },padding:0,margin:0}}
              />)})

          const leadsColumns =  useMemo(()=>[
              {
                id: 'site',
                accessorKey: 'site',
                header: 'Leads',
                Cell: ({renderedCellValue,row})=>{return(<Leadtb value={row.original.site} in={row.index} />) },
                size:220,maxSize:220
                //enableEditing:false
              },
              {
                accessorKey: 'imp',
                header: 'Imp',
                id: 'imp',
                size:150,maxSize:150,
                //accessorFn: (row) => {return  row.imp},
                Cell: ({renderedCellValue,row})=>{return(<Imp checked={row.original.imp} in={row.index}/>) },
               // Edit: ({ cell, renderedCellValue }) => {return <>{renderedCellValue}</>},
              }, {
                //accessorKey: 'contactForm',
                header: 'C Form',
                id: 'cform',
                Cell: ({renderedCellValue,row})=>{return(<Cf checked={row.original.contactForm} in={row.index}/>) },
                size:150,maxSize:150,

                //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
              }
              , {
                //accessorKey: 'contactForm',
                header: 'Contacts',
                id: 'contact',
                Cell: ({renderedCellValue,row})=>{return(<RenderDetailPanelLeads row={row} r1={r1}/>) },
                size:1000

                //Edit: ({ cell, renderedCellValue }) => <>{renderedCellValue}</>,
              }

            ],[])
            const [inputValue, setInputValue] = useState('');
            const [pl, setPl] = useState('');

            const [bg, setBg] = useState();
            const[upfail, setUpfail]=useState(false);

            const [textInputs, setTextInputs] = useState(new Set());
            const handleInputChange = (event) => {
                setInputValue(event.target.value);
              };
            
              const handleInputKeyPress = (event) => {
                if (event.key === 'Enter' && inputValue.trim() !== '') {
                  setTextInputs((prevInputs) => new Set([...prevInputs, inputValue.trim().toLowerCase()]));
                  setInputValue('');
                }
              };
            
              const handleRemoveInput = (input) => {
                const updatedInputs = new Set(textInputs);
                updatedInputs.delete(input);
                setTextInputs(updatedInputs);
              };
             // useEffect(()=>{getMissedLeads(row.original.id).then((res)=>{setTextInputs(res.data); setSaveml(false)}).catch((err)=>{console.log(err)})},[])
            const leadTable=  useMaterialReactTable({
                columns:leadsColumns,
                data:leadsData,
                //renderDetailPanel:({ row }) => (<RenderDetailPanelLeads row={row} /> ),
                 // muiDetailPanelProps: ({ row }) => ({ sx: {paddingY:0},}),
                enableExpandAll:true,
                enableBottomToolbar:false,
                enableTopToolbar:false,
                enableTableHead:false,
                layoutMode:'grid-no-grow',
                //memoMode:'cells',
               // enableEditing:true,
                //editDisplayMode: 'cell',
               // muiTableBodyCellProps: ({ cell,row, column, table }) => ({onClick:()=>{table.setEditingCell(cell);}}),
              // muiEditTextFieldProps: ({ cell,row, column, table }) => ({sx:{padding:0, height:15,}, InputProps:{ sx: {fontSize:14 } }}),
                initialState:{//expanded:true,
                    density:'compact'},
                    //muiTableBodyRowProps:{ sx:{flex:true,flexDirection:'row', alignItems:'end',justifyContent:'s'}},
                    muiTableBodyProps:{sx:{backgroundColor:'#fcfcfa'}},muiTableBodyRowProps:{sx:{backgroundColor:'#fcfcfa'}},
                    muiTablePaperProps:{elevation:0},
                    muiDetailPanelProps:{sx:{ paddingY:0,backgroundColor:'white',paddingLeft:8}},
                onEditingRowSave: ({ values, table,row })=>{
                    saveLeadQA(row.original.id,values.impMiss=='Yes',values.irrelevant=='Yes',values.primaryEmailMissing?values.primaryEmailMissing:"").then((res)=>{table.setEditingRow(null);console.log(res.data)})
                    .catch((err)=>{console.log(err); setUpfail(true)})
                    },
                    
              });
          return <Stack direction='column' spacing={1}> 
                    <Stack paddingLeft={2} direction='row' width='100%' justifyContent='start' spacing={3} alignItems='center' >
                    <FormControl size='small'>
      <RadioGroup 
      row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={bg}
        onChange={ (event) => {
            setBg(event.target.value);
          }}
      >
        <FormControlLabel  value="Brandable" control={<Radio size='small' />} label={ <Typography fontSize={13} color='text.primary' align='left'>
      Generic 
    </Typography>      } />
        <FormControlLabel value="Generic" control={<Radio size="small"/>} label={ <Typography fontSize={13} color='text.primary' align='left'>
      Brandable 
    </Typography>      } />
        <FormControlLabel value="Brandable Generic" control={<Radio size="small" />} label={ <Typography fontSize={13} color='text.primary' align='left'>
      Brandable Generic
    </Typography>      } />

      </RadioGroup>
    </FormControl>
          <Stack direction='row' sx={{paddingRight:5}} spacing={3} alignItems='center' >
         {/* <Typography fontSize={13} fontWeight='bold' color='text.primary' align='left'>
      Tags: 
        </Typography> */ }    
    <Stack direction='row' spacing={6} alignItems='center'>
    <TextField
      //label="Leads"
      placeholder="Tags"
      value={inputValue}
      size='small'
      //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
      onChange={handleInputChange}
      onKeyPress={handleInputKeyPress}
      variant='standard'
      margin="normal"
      InputProps={{sx:{fontSize:13,height:15}}}
      sx={{'& legend': { display: 'none' },
      '& fieldset': { top: 0 },
    width:75,padding:0}}
    />
    <Stack direction='row' sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px',alignItems:'center',justifyContent:'center' }}>
      {[...textInputs].map((input) => (
        <Chip
          key={input}
          label={input}
          onDelete={() => handleRemoveInput(input)}
          deleteIcon={<Close  sx={{height:13,width:13,padding:0}} />}
          size='small'
          color="primary"
          sx={{height:20,fontSize:13,'& .MuiChip-label': { 
            padding: '2px 5px' 
          } }}
        />
      ))}
    </Stack>

   
    </Stack>
    <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
         Not Uploaded!
        </Alert>
      </Snackbar></Stack></Stack><MaterialReactTable  table={leadTable}/>
      <Stack direction='row' paddingLeft={1}><TextField
                //label="Leads"
                placeholder="Paste or Enter Leads"
                value={pl}
                size='small'
                multiline
                //inputRef={inputRef}
                //disabled={(row.original.presentAllocation)&&row.original.presentAllocation.status!=2}
               onChange={(event) => {
                setPl(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault(); // Prevent default behavior of adding a new line
                  if (pl.trim() !== '') {
                    const arr=pl.split('\n')
                    const l=arr.map((ar)=>{ return {
                        site:ar.trim().toLowerCase(),
                        contactForm:false,
                        imp:false,
                        contacts:[{name:"",primaryEmail:"",secondaryEmail:"",designation:""}]
                    }})
                    setPresentAllocs((prevAllocs) => {
                        const updatedAllocs = [...prevAllocs];
                        updatedAllocs[row.index].todayDW.leads=updatedAllocs[row.index].todayDW.leads.concat(l); // Update the site value at the specified index
                        return updatedAllocs;
                      });
                    setPl(''); // Reset input value
                  }
                }
              }}
               /* onBlur={()=>{setPresentAllocs((prevAllocs) => {
                    const updatedAllocs = [...prevAllocs];
                    updatedAllocs[row.index].todayDW.leads[props.in].site = inputValue; // Update the site value at the specified index
                    return updatedAllocs;
                  });}}
                onKeyPress={handleInputKeyPress}*/
                variant='outlined'
                margin="normal"
                autoCorrect={false}
                InputProps={{sx:{fontSize:14}}}
                sx={{'& legend': { display: 'none' },
                '& fieldset': { top: 0 },padding:0,margin:0,width:190}}
              /></Stack></Stack>;
        })

      const getContactsData = React.useCallback((lead) => {
        //lead.contacts.push(createContactRow())
        // Assuming domainWork.leads contains the array of leads
        return lead.contacts;
      }, []);

      const RenderDetailPanelLeads = 
        ({ row,r1 }) => {
            const [r2,setR2]=useState(row);
          const contactsData = getContactsData(row.original);
          const Emailtb=(props)=>{
            const [inputValue, setInputValue] = useState(props.value);
            const [pe, setPe] = useState(props.pe);

            const handleInputChange = (event) => {
                setInputValue(event.target.value);
              };
            return(<TextField
            placeholder="Email"
            value={inputValue}
            size='small'
            onChange={handleInputChange}
            variant='outlined'
            margin="normal"
            autoCorrect={false}
            InputProps={{sx:{fontSize:14,height:25}}}
            sx={{'& legend': { display: 'none' },
            '& fieldset': { top: 0 },padding:0,margin:0}}
          />)}

          const Nametb=(props)=>{
            const [inputValue, setInputValue] = useState(props.value);

            const handleInputChange = (event) => {
                setInputValue(event.target.value);
              };
            return(<TextField
            placeholder="Name"
            value={inputValue}
            size='small'
            onChange={handleInputChange}
            variant='outlined'
            margin="normal"
            autoCorrect={false}
            InputProps={{sx:{fontSize:14,height:25}}}
            sx={{'& legend': { display: 'none' },
            '& fieldset': { top: 0 },padding:0,margin:0}}
          />)}

          const Desigtb=(props)=>{
            const [inputValue, setInputValue] = useState(props.value);

            const handleInputChange = (event) => {
                setInputValue(event.target.value);
              };
            return(<TextField
            placeholder="Designation"
            value={inputValue}
            size='small'
            onChange={handleInputChange}
            variant='outlined'
            margin="normal"
            autoCorrect={false}
            InputProps={{sx:{fontSize:14,height:25}}}
            sx={{'& legend': { display: 'none' },
            '& fieldset': { top: 0 },padding:0,margin:0}}
          />)}
          const contactsColumns =useMemo( ()=> [
            {
                id:'name',
                accessorKey: 'name',
                muiEditTextFieldProps: ({ cell, row }) => ({
                    placeholder:'Name',
                    fullWidth:true
                }),
                size:220,
                //Cell: ({renderedCellValue,row})=>{return(<Nametb value={row.original.name}/>) },
                header: 'Name',
              },
            {
                id:'pemail',
                header: 'P Email',muiEditTextFieldProps: ({ cell, row }) => ({
                    placeholder:'Primary Email'
                }),
                size:220,

                accessorKey:'primaryEmail'
                //Cell: ({renderedCellValue,row})=>{return(<Emailtb p={row.original.primaryEmail} s={row.original.secondaryEmail}  />) },

              },
              {
                id:'semail',
                header: 'S Email',muiEditTextFieldProps: ({ cell, row }) => ({
                    placeholder:'Secondary Email'
                }),
                size:220,
                accessorKey:'secondaryEmail'
                //Cell: ({renderedCellValue,row})=>{return(<Emailtb p={row.original.primaryEmail} s={row.original.secondaryEmail}  />) },

              },
            
             {
                accessorKey: 'designation',
                muiEditTextFieldProps: ({ cell, row }) => ({
                    placeholder:'Designation'
                }),
                size:220,
                header: 'Designation',
              },

            ],[])
            const contactTable=  useMaterialReactTable({
                columns:contactsColumns,
                data:contactsData,
                enableExpandAll:true,
                enableRowActions:true,
               // memoMode:'cell',
                positionActionsColumn: 'last',
                renderRowActions: ({ row }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                      <Tooltip  title="Add">
                        <IconButton size="small" sx={{padding:0}} color="primary" onClick={() => { setPresentAllocs((prevAllocs) => {
                        const updatedAllocs = [...prevAllocs];
                        updatedAllocs[r1.index].todayDW.leads[r2.index].contacts.splice(row.index+1,0,createContactRow()); // Update the site value at the specified index
                        return updatedAllocs;
                      });}}>
                          <Add fontSize="small" sx={{padding:0}} />
                        </IconButton>
                      </Tooltip>
                      {(!(row.index==0&&contactsData.length==1))&&<Tooltip title="Remove">
                        <IconButton size="small" sx={{padding:0}} color="primary" onClick={() => {setPresentAllocs((prevAllocs) => {
                        const updatedAllocs = [...prevAllocs];
                        updatedAllocs[r1.index].todayDW.leads[r2.index].contacts.splice(row.index,1); // Update the site value at the specified index
                        return updatedAllocs;
                      });}}>
                          <Remove fontSize="small" sx={{padding:0}} />
                        </IconButton>
                      </Tooltip>}
                    </Box>
                  ),
                layoutMode:'grid-no-grow',
                enableBottomToolbar:false,
                initialState:{density:'compact'},
                enableEditing:true,
                editDisplayMode:'table',
                muiTablePaperProps:{elevation:0},
                enableTopToolbar:false,enableTableHead:false,
                muiEditTextFieldProps: ({ cell,row, column, table }) => ({
                   //onChange:(event)=>{console.log(cell)},
                    onBlur:(event)=>{const val=event.currentTarget.value;
                        setPresentAllocs((prevAllocs) => {
                            const updatedAllocs = [...prevAllocs];
                            updatedAllocs[r1.index].todayDW.leads[r2.index].contacts[row.index][column.columnDef.accessorKey]=val; // Update the site value at the specified index
                            return updatedAllocs;
                          });
                    },
                    sx:{padding:0, height:15,}, InputProps:{ sx: {fontSize:13 } }}),

              });
          return (<Box flexGrow={1} width='100%' sx={{flexGrow:1}} ><MaterialReactTable table={contactTable}
          /></Box>)
        }
        const { user1, updateUser1 } = useUser();
        const [disablerb, setDisablerb ] = useState(false);
        const [openbd, setOpenbd ] = useState(false);

        const [rbtitle,setRbtitle] = useState("");

let createLeadRow=()=>{
    return {
        site:"",
        contactForm:false,
        imp:false,
        contacts:[{name:"",primaryEmail:"",secondaryEmail:"",designation:""}]
    }
}

let createContactRow=()=>{
    return {name:"",primaryEmail:"",secondaryEmail:"",designation:""}
}

    useEffect(()=>{
        if(id==0)
        getPresentAllocsWI().then((res)=>{
            if(res.data)
   {let d=res.data;
    d=d.map((a1)=>{
        let a=a1;
        let leads=a.todayDW.leads;
        leads=leads.map((lead)=>{lead.contacts.push(createContactRow())})
        leads.push(createLeadRow()) 
        a.todayDW.leads=leads
        return a;  
    })
    setPresentAllocs(d);
    console.log(d);
}}).catch((err)=>{console.log(err)})
},[])

/*useEffect(()=>{
    if(id!=0)
    getDayWorkId(id).then((res)=>{
        if(res.data)
{setDayWork(res.data);console.log(res.data); setDomainWorks(res.data.domainWorks)
if(res.data.analyst==null||res.data.analyst.email==
    getUserName())
setCanreview(true); else setCanreview(false);
setDate(dayjs(new Date(res.data.dateOfUpload)));
}
else setDomainWorks([])
    }).catch((err)=>{console.log(err)})
},[])

const reviewbutton=()=>{
    if(dayWork.revStatus==0) setRbtitle('Start Review');
    else if(dayWork.revStatus==1||dayWork.revStatus==-1)
    {
        if(canreview)
        setRbtitle('Finish Review');
    else{setDisablerb(true) ;setRbtitle('Under Review'); }
    }
    else if(dayWork.revStatus==2)
    {
        if(canreview)
        setRbtitle('Re-Review'); 
    else{setDisablerb(true) ;setRbtitle('Reviewed')}
    }

}*/

const domainTable=  useMaterialReactTable({
    columns:columns,
    data:presentAllocs,initialState: { density: 'compact' },
    //memoMode:'cells',
    renderDetailPanel: ({ row }) => (
        <RenderDetailPanel row={row} />
      ),
    enableExpandAll:true,
    muiTablePaperProps: ({ table }) => ({
        //not sx
        style: {
          zIndex: table.getState().isFullScreen ? 1250 : undefined,
        },
      }),
      muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke'}},
      muiDetailPanelProps: ({ row }) => ({
        sx: {
          backgroundColor:'#fcfcfa',paddingX:0
        },
      }),
      

  });
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2} alignItems='center'>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Work Station
    </Typography>
    <Box sx={{flexGrow:1}}/>
    {/*dayWork&&<Button
      variant="contained"
      color="primary"
      disabled={disablerb}
      //startIcon={<Uploa />}
      onClick={()=>{
        if(dayWork.revStatus==0)
        {
            startAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:1}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==1||dayWork.revStatus==-1)
        {
            stopAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:2}))}).catch((err)=>{console.log(err)});
        }
        else if(dayWork.revStatus==2)
        {
            restartAnalysis(dayWork.id).then((res)=>{setDayWork(dayWork=>({...dayWork,revStatus:-1}))}).catch((err)=>{console.log(err)});
        }
      }}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none',
      }}
    >
      { rbtitle}
    </Button>*/}
   
 
            </Stack>
            <Box //sx={{ opacity: openbd ? 0.5 : 1,pointerEvents:openbd ? "none" : "auto"}}
            >
      <MaterialReactTable
     table={domainTable}
    />
    </Box>
            </Stack>
            </ThemeProvider>
    )
 }