import Dexie from "dexie";

const db = new Dexie('WorkInputDB');

// Define a schema for the database
db.version(2).stores({
    presentAllocs: 'id, dateOfAlloc, domain, working, status, todayDW',
    data:'id, date, changes, ld, lastWorkSaved'
});

export default db;